import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  /**
   * Initialize mixpanel.
   *
   * @memberof MixpanelService
   */
  init(): void {
    // mixpanel.init('5c3d71d8969d07eade7a841605f12ebb',{debug:!environment.production});
  }

  /**
   * Push new action to mixpanel.
   *
   */
  track(id: string, action: any = {}): void {
    // mixpanel.track(id, action);
  }
}