import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelSearchByRetailer } from './model';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { AdminService } from 'src/app/core/services/api/admin.service';
import { SupplyService } from 'src/app/core/services/api/supply.service';

@Component({
  selector: 'app-search-by-retailer',
  templateUrl: './search-by-retailer.component.html',
  styleUrls: ['./search-by-retailer.component.scss']
})
export class SearchByRetailerComponent implements OnInit {
  retailerData = {
    pharmacyName: "",
    mobileNo: "",
    address: "",
    retailerOrderTrackResponses: [
      {
        orderIdList: [
        ],
        orderRetrieveId: "",
        orderTimeEpoch: 0,
        invoiceNumber: "",
        invoiceTimeEpoch: 0,
        slotName: ""
      }
    ]
  }
  page = 0;

  constructor(
    public dialogRef: MatDialogRef<SearchByRetailerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelSearchByRetailer,
    private adminService: AdminService,
    private popupService: PopupService,
    private supplyService: SupplyService) { 

  }

  ngOnInit(): void {
    this.getData();
  }

  copyRet(code){
    navigator.clipboard.writeText(code)
  }
  getData() {
    this.popupService.openProgress();
    const params = {
      retailerCode: this.data.code,
      page: this.page
    };
    this.adminService.globalSearchByRetailerCode(params).subscribe(
      response => {
        this.retailerData = response.data;
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  getPreviousPageData() {
    this.page = this.page - 1;
    this.getData();
  }

  getNextPageData() {
    this.page = this.page + 1;
    this.getData();
  }

  searchByInvoice(invoiceNumber) {
    this.popupService.openSearchByInvoicePopup(invoiceNumber).afterClosed().subscribe(
      response => {
        if (response) {
          this.dialogRef.close(response);
        }
      }
    );
  }

  searchByOrderId(orderId) {
    this.popupService.openSearchByOrderPopup(orderId).afterClosed().subscribe(
      response => {
        if (response) {
          this.dialogRef.close(response);
        }
      }
    );
  }

  cancel() {
    this.dialogRef.close(false);
  }

  generateIRN(orderId) {
    this.popupService.openProgress();
    const params = {
      lstOrderRetrieveIds: [orderId]
    };
    this.supplyService.generateIRN(params).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess("Success");
        this.downloadCSV(response.data);
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  // Download Generated IRN
  downloadCSV = (generatedIRN) => {
    const fields = [
      "orderRetrieveId",
      "invoiceNumber",
      "status",
      "irnNumber",
      "errorMessage",
    ];
    const header = [
      "orderRetrieveId",
      "invoiceNumber",
      "status",
      "irnNumber",
      "errorMessage",
    ];
    const replacer = (key, value) => (value === null ? null : value);

    const csv = generatedIRN.map((row) =>
      fields.map((fieldName) => row[fieldName], replacer).join(",")
    );

    // add headers
    csv.unshift(header.join(","));
    const csvArray = csv.join("\n");

    // generate file
    const a = document.createElement("a");
    const blob = new Blob([csvArray], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    // download file
    a.href = url;
    a.download = "GENERATED_IRN_" + new Date().toLocaleDateString("en-GB");
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };
}
