import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { AuthService } from '../services/api/api-auth.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService {
  constructor(private router: Router, private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let url = request.url;
   
    return next.handle(request).pipe(
      catchError((err, caught) => {
        if (!url.includes("refreshToken") && err.status == 401) {

          return this.authService.getNewAccessToken().pipe(
            mergeMap((token: String, http) => {
              if (token) {
                // clone and modify the request
                request = request.clone({
                  setHeaders: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': undefined
                  }
                });
              }

              return next.handle(request);
            })
          )
        } else if (url.includes("refreshToken")) {
          localStorage.clear();
          this.router.navigateByUrl("/authenticate");
        } else {
          if (err.status) {
            return throwError(err);
          } else {
            return next.handle(request)
          }
        }
      })
    )

    // if (
    //   url.includes("assets/") ||
    //   url.includes("authenticate") ||
    //   url.includes("firebasestorage.googleapis.com")
    // ) {
    //   return next.handle(request);
    // } else if (url.includes("/checkAuth")) {

      
          
      
    // }
    //  else {
    //   return this.authService.getAccessToken().pipe(
    //     mergeMap((token: String, htt) => {
    //       if (token) {
    //         // clone and modify the request
    //         request = request.clone({
    //           setHeaders: {
    //             Authorization: `Bearer ${token}`
    //           }
    //         });
    //       }

    //       return next.handle(request);
    //     })
    //   );
    // }
  }
}
