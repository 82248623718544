import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { ApiService } from "../../../common/api.service";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class WarehouseService {
  API_URL: string = environment.adminUrl + "/admin";
  API_URL_V2: string = environment.adminUrl + "/v2/admin";
  preprod_url = "https://preprod.saveo.in/admin";

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute
  ) {}

  getPickData(): Observable<any> {
    const urlPath = this.API_URL + "/saveoinventory/pick";
    return this.apiService.doGetObservable(urlPath);
  }

  pickDone(params, body): Observable<any> {
    const urlPath = this.API_URL + "/saveoinventory/pick/done";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updatePickFoundQuantity(params, body): Observable<any> {
    const urlPath = this.API_URL + "/saveoinventory/pick/foundQuantity";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updatePickRemarks(params, body): Observable<any> {
    const urlPath = this.API_URL + "/saveoinventory/pick/remarks";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getGrnData(): Observable<any> {
    const urlPath = this.API_URL + "/saveoinventory/grn";
    return this.apiService.doGetObservable(urlPath);
  }

  uploadGrn(params, body): Observable<any> {
    const urlPath = this.API_URL + "/saveoinventory/grn/upload";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  pickAgain(params, body): Observable<any> {
    const urlPath = this.API_URL + "/saveoinventory/pick/again";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getDecisionRoomFilters(params): Observable<any> {
    const urlPath = this.API_URL + "/decisionRoom/billingslot/past";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getDecisionRoomItems(params): Observable<any> {
    const urlPath = this.API_URL + "/decisionRoom/billingslot/item/all";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateDecisionRoomBatch(params, body): Observable<any> {
    const urlPath = this.API_URL + "/decisionRoom/medicine/new/update/batch";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateDecisionRoomDistributor(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/decisionRoom/medicine/new/update/distributor";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateDecisionRoomInvoiceNumber(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/decisionRoom/medicine/new/update/invoiceNumber";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateDecisionRoomQuantity(params, body): Observable<any> {
    const urlPath = this.API_URL + "/decisionRoom/medicine/new/update/quantity";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateDecisionRoomFoundQuantity(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/decisionRoom/medicine/update/foundQuantity";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateDecisionRoomReason(params, body): Observable<any> {
    const urlPath = this.API_URL + "/decisionRoom/medicine/new/update/reason";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  searchItem(params): Observable<any> {
    const urlPath = this.API_URL + "/decisionRoom/master/medicine/search/name";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  decisionRoomAddItem(params, body): Observable<any> {
    const urlPath = this.API_URL + "/decisionRoom/add/new/medicine/open/items";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  addOpenItemsToInventory(params, body): Observable<any> {
    const urlPath = this.API_URL + "/decisionRoom/send/saveoInventory";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getDecisionRoomItemsToBeAdded(params): Observable<any> {
    const urlPath =
      this.API_URL + "/saveoinventory/itemsToBeAdded/items/decisionRoom/all";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  addItemsToInventory(params, body): Observable<any> {
    const urlPath = this.API_URL + "/saveoinventory/itemsToBeAdded/item/add/v2";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  addManualPOItemsToInventory(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/saveoinventory/itemsToBeAdded/ManualPO/add";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateSaveoInventoryItemsBatch(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/saveoinventory/itemsToBeAdded/item/update/batch/v3";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateSaveoInventoryItemsBatchManualPO(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/saveoinventory/itemsToBeAdded/item/update/batch/v2";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateSaveoInventoryItemsPackaging(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/saveoinventory/itemsToBeAdded/item/update/packaging/v3";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateSaveoInventoryItemsPackagingManualPO(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/saveoinventory/itemsToBeAdded/item/update/packaging/v2";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateSaveoInventoryItemsFoundQuantity(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/saveoinventory/itemsToBeAdded/item/update/foundQuantity";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateSaveoInventoryItemsFoundQuantityManualPO(
    params,
    body
  ): Observable<any> {
    const urlPath =
      this.API_URL +
      "/saveoinventory/itemsToBeAdded/item/update/foundQuantity/v2";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateSaveoInventoryItemsMrp(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/saveoinventory/itemsToBeAdded/item/update/mrp/v3";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }
  updateSaveoInventoryItemsMrpManualPO(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/saveoinventory/itemsToBeAdded/item/update/mrp/v2";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  sendOpenItemsToVendor(params, body): Observable<any> {
    const urlPath = this.API_URL + "/decisionRoom/send/vendor";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getCancelledByCustomerItemsToBeAdded(params): Observable<any> {
    const urlPath =
      this.API_URL +
      "/saveoinventory/itemsToBeAdded/items/CancelledByCustomer/all";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getDistributorReturnItemsToBeAdded(params): Observable<any> {
    const urlPath =
      this.API_URL +
      "/saveoinventory/itemsToBeAdded/items/returnFromDistributor/all";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getManualPOItemsToBeAdded(): Observable<any> {
    const urlPath =
      this.API_URL + "/saveoinventory/itemsToBeAdded/items/ManualPO/all";
    return this.apiService.doGetObservable(urlPath);
  }

  getManualPOItems(params): Observable<any> {
    const urlPath =
      this.API_URL +
      "/saveoinventory/itemsToBeAdded/items/ManualPO/items/all/v2";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getManualPOItemsToBeAddedOrderDetails(params): Observable<any> {
    const urlPath =
      this.API_URL + "/saveoinventory/itemsToBeAdded/items/ManualPO/detail";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  searchPickItem(params): Observable<any> {
    const urlPath = this.API_URL + "/saveoinventory/pick/search/keyword";
    return this.apiService.doPostObservableForQuery(urlPath, {}, params);
  }

  addPickItem(params, body): Observable<any> {
    const urlPath = this.API_URL + "/saveoinventory/pick/item/add";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateManualPoExpiry(params, body): Observable<any> {
    const urlPath =
      this.API_URL +
      "/saveoinventory/itemsToBeAdded/item/manualPO/update/expiryDate";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateExpiry(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/saveoinventory/itemsToBeAdded/item/update/expiryDate/v3";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  getAddItemsFilters(url): Observable<any> {
    const urlPath = this.API_URL + url;
    return this.apiService.doGetObservable(urlPath);
  }

  updateMrpItemToBeAdded(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/saveoinventory/itemsToBeAdded/item/update/mrp/v3";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  
  getReasonsForOpenItems(url): Observable<any> {
    const urlPath = this.API_URL + '/decisionRoom/reasons/open/items';
    return this.apiService.doGetObservable(urlPath);
  }

  updateNewItemsQty(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/decisionRoom/medicine/new/update/quantity/missingQty/damagedQty";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }

  updateOldItemsQty(params, body): Observable<any> {
    const urlPath =
      this.API_URL + "/decisionRoom/medicine/update/foundQuantity/missingQty/damagedQty";
    return this.apiService.doPostObservableForQuery(urlPath, body, params);
  }
  getSampleAssignBinCSV(): Observable<any> {
    const urlPath =
      environment.adminUrl +
      "/admin/saveoinventory/audit/bins/assign/sampleCSV";
    return this.apiService.doGetObservable(urlPath);
  }

  uploadAssignBinCSV(file: any): Observable<any> {
    const urlPath =
      environment.adminUrl + "/admin/saveoinventory/audit/bins/assign";
    return this.apiService.doPostObservableForCSV(urlPath, file, {});
  }

  getSampleAuditRequiredCSV(): Observable<any> {
    const urlPath =
      environment.adminUrl +
      "/admin/saveoinventory/audit/auditRequired/sampleCSV";
    return this.apiService.doGetObservable(urlPath);
  }

  uploadAuditRequiredCSV(file: any): Observable<any> {
    const urlPath =
      environment.adminUrl + "/admin/saveoinventory/audit/auditRequired";
    return this.apiService.doPostObservableForCSV(urlPath, file, {});
  }

  getSampleMedicineTagCSV(): Observable<any> {
    const urlPath =
      environment.adminUrl +
      "/admin/saveoinventory/audit/medicine/updateListingTag/sampleCSV";
    return this.apiService.doGetObservable(urlPath);
  }

  uploadMedicineTagCSV(file: any): Observable<any> {
    const urlPath =
      environment.adminUrl +
      "/admin/saveoinventory/audit/medicine/updateListingTag";
    return this.apiService.doPostObservableForCSV(urlPath, file, {});
  }
}
