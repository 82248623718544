import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-logistics-revamp",
  templateUrl: "./logistics-revamp.component.html",
  styleUrls: ["./logistics-revamp.component.scss"],
})
export class LogisticsRevampComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    localStorage.removeItem("logisticsRevampInvoiceGlobalFilters");
    localStorage.removeItem("invoicePageLevelFilters");
  }
}
