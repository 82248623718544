import * as React from 'react';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import CCHeader from './CCHeader/CCHeader';
import CreditBillsTable from './CreditBills/CreditBillsTable';

import './CreditReactComponent.scss';
import LeftSideBar from './LeftCommonComponent/LeftSideBar';
import { Input,Icon } from 'semantic-ui-react'
import CreditUserDetails from './CreditUserDetails/creditUserDetails';
export interface IMyComponentProps {
  token:String;
}

export const CreditReactComponent: FunctionComponent<IMyComponentProps> = (props: IMyComponentProps) => {
  const [submenuSelected,setSubmenuSelected] = useState("all");
  const [leftbar,setLeftbar] = useState(false);
  const [searchQuery,setSearchQuery] = useState(undefined);
  
  const getdataUsingSearchQuery=()=>{
      let query =  searchQuery.trim();
      if(query.includes("R_") || query.includes("RET")){
        console.log("Retailer");
      }
      else if(query.includes("SBIL")){
        console.log("Bill");
      }
      else{
        console.log("cheque");
      }
  } 

  return (
    <>
    <div style={{marginBottom:"20px"}}>
    <CCHeader/>
    </div>
    <div className="row">
      <div className="col-2">
          <LeftSideBar token={props.token} setLeftbar={setLeftbar} setSubmenuSelected={setSubmenuSelected}/>
        </div>
        <div className="col-10">
        <div style={{marginBottom:"10px"}}>
          
          {/* <Input style={{width:"35%"}}
            icon={<Icon name='search' style={{color:"#4b4495"}} circular link onClick={()=>getdataUsingSearchQuery()} />}
            placeholder='Search Retailer Code, Bill Number or Cheque Number'
            onChange={(e)=>setSearchQuery(e.target.value)}
          /> */}
        </div>
        {submenuSelected!='livelimits' && <CreditBillsTable token={props.token} submenuSelected={submenuSelected} name="Pankaj"/>}
        {submenuSelected=='livelimits' && <CreditUserDetails token={props.token}/>}
      </div>
   
    {/* {leftbar && 
        <div className="col-1">
          <LeftSideBar setLeftbar={setLeftbar} setSubmenuSelected={setSubmenuSelected}/>
        </div>
 
    }
    {leftbar && 
      <div className="col-11">
        <CreditBillsTable submenuSelected={submenuSelected} name="Pankaj"/>
      </div>
    } */}
    {/* {!leftbar && 
        <div className="col-2">
          <LeftSideBar setLeftbar={setLeftbar} setSubmenuSelected={setSubmenuSelected}/>
        </div>
 
    }
    {!leftbar && 
      <div className="col-10">
        <CreditBillsTable submenuSelected={submenuSelected} name="Pankaj"/>
      </div>
    } */}
    </div>
    </>
  )
};
