import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonFiltersComponent } from '../common-filters/common-filters.component';

@Component({
  selector: 'app-common-table-header',
  templateUrl: './common-table-header.component.html',
  styleUrls: ['./common-table-header.component.scss']
})
export class CommonTableHeaderComponent implements OnInit {
  data = [];
  header = {value: "", filter: false, key: "", width: "", sorting:{active:false,standAlone:false}};
  filteredHeader = [];
  filters=null;
  @ViewChild(CommonFiltersComponent) filtersComponent: CommonFiltersComponent;

  constructor() { }
  @Input("filters") set val(value: any) {
    if (value) {
      this.filters = value;
    }
  }
  @Input("data") set val1(value: any) {
    if (value) {
      this.data = value;
    }
  }

  @Input("header") set val2(value: any) {
    if (value) {
      this.header = value;
    }
  }

  @Input("filteredHeader") set val3(value: any) {
    if (value) {
      this.filteredHeader = value;
    }
  }

  @Output() filteredData: EventEmitter<any> = new EventEmitter<any>();
  @Output() sortFilter: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
  }

  // Accessing the toggleFilter function from the child component which was indirectly accessed by another child component
  selectToggle(event){
    this.filtersComponent.toggleFilter(event.key, event.filter);
  }

  // Sending the data back to Parent component and calling the setFilteredData indirectly
   setParentData(event) {
    this.filteredData.emit({ 
      filteredData: event.filteredData,
      filters: event.filters,
      headerKey: event.key
    });
  }

  setSortFilter(event) {
    this.filtersComponent.applySort(event.key);
  }

  removeFilterHeader(event) {
    this.filtersComponent.removeFilter(event);
  }
}
