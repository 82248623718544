import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelViewBill } from './model';

@Component({
  selector: 'app-view-bill',
  templateUrl: './view-bill.component.html',
  styleUrls: ['./view-bill.component.scss']
})
export class ViewBillComponent implements OnInit {

  printStatus={
    id:'',
    status:true,
    restrictPrint:true
  }
  constructor(public dialogRef: MatDialogRef<ViewBillComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelViewBill,) { }

  ngOnInit(): void {
      this.printStatus.id=this.data.data.id
  }


  setStatusNew(event){
      this.printStatus={
         id:'',
         status:true,
         restrictPrint:true
      }
  }

  close() {
    this.dialogRef.close(false);
  }
}
