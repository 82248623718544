import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError} from '@angular/material/dialog';
import { DialogModelGeneratePO } from './model';
import { SupplyService } from "src/app/core/services/api/supply.service";
import { PopupService } from "src/app/core/services/popup/popup.service";
import { FormControl, FormBuilder, FormGroup, Validators, FormGroupDirective } from "@angular/forms";
import {Observable} from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FileUploadService } from 'src/app/core/services/api/file-upload.service';
import { HubService } from 'src/app/core/services/api/hub.service';

@Component({
  selector: 'app-generate-po',
  templateUrl: './generate-po.component.html',
  styleUrls: ['./generate-po.component.scss']
})
export class GeneratePoComponent implements OnInit {
  _file: File;
	_mFormGroup: FormGroup;

  newMedQuantity = 1;
  newMedBlockedForRetail=0;
  newMedBlockedForWholesale=0;
  newMedForListing=0;
  newMedForGifting=0;

  distributors = [];
  filteredDistributors=[];
  currDistributorName = '';
  currDistributorNameCopy="";
  currDistributorId = '';
  medicines = [];
  emptyMed = {
    itemName: "",
    quantity: 0
  };
 splitFields=null;

  currSearchMed = new FormControl();
  searchSuggestions: Observable<string[]>;
  searchResult = [];
  currMedToAdd;
  bulkBilling=false
  types = [
    {
      typeName: 'Holding',
      displayName: 'List from Saveo holding distributor',
      show: false
    },
    // {
    //   typeName: 'GIT',
    //   displayName: 'List from same distributor',
    //   show: false
    // }
  ];
  currType = "Holding";
  showNewMedicineForm = false;
  formNewMedicine: FormGroup;
  showCSV = true;
  hubs = [];
  currHubId;
  filteredHubs = [];
  currHub = "";
  globalHub=null;
  constructor(private supplyService: SupplyService,
    private popupService: PopupService,
    public dialogRef: MatDialogRef<GeneratePoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelGeneratePO,
    private formBuilder: FormBuilder,
    private fileUploadService: FileUploadService,
    private hubService: HubService
    ) {
      this.formNewMedicine = this.formBuilder.group({
        medicineName: ["", [Validators.required]],
        quantity: ["", [Validators.required]],
        packaging: ["", [Validators.required]],
        manufacturer: ["", [Validators.required]]
      });

		this._mFormGroup = this.formBuilder.group({
			file: ["", [Validators.required]]
		});
    }

  ngOnInit(): void {
    this.popupService.openProgress();
    this.getHubs(true);
    this.getFields()
  }


  getFields(){
    this.popupService.openProgress();
    this.globalHub=localStorage.getItem('hubId')
    if(this.globalHub==4){
      this.newMedBlockedForWholesale=1
    }
    this.supplyService.getManualPoSplitFields({hubId:this.globalHub}).subscribe((res)=>{
      this.popupService.closeProgress()
     this.splitFields=res.data
    },
    error => {
    this.popupService.closeProgress();
    this.popupService.openError(error.error.error.error);
   })
  }

  enterQtyChange(){
    if(this.globalHub==4){
        this.newMedBlockedForWholesale=this.newMedQuantity
    }
  }
  
  // get hubs
	getHubs(closeProgress) {
	  this.hubService.getHubs().subscribe(
		response => {
		  this.hubs = response.body.data;
		  // this.currHubId = JSON.parse(response.headers.get('hubId'));
      this.currHubId=this.hubs[0].id
		  this.currHub = this.hubs[0].hubCode;
		  this.getDistributors(closeProgress);
		},
		error => {
		  this.popupService.closeProgress();
		  this.popupService.openError(error.error.error.error);
		}
	  )
	}
  
	// change hub
	changeHub(id) {
	  this.popupService.openProgress();
    this.currHubId = id;
	  this.getDistributors(true);
   
	}

  getDistributors(closeProgress) {
	  const params = {
		  servicingHubId: this.currHubId
	  };
	  this.hubService.getDistributorsByServicingHub(params).subscribe(
      response => {
        this.distributors = response.data;
        this.filteredDistributors=[...response.data]
        if (this.distributors.length > 0) {
          this.currDistributorName = this.distributors[0].distributorName;
          this.currDistributorNameCopy=this.distributors[0].distributorName;
          this.currDistributorId = this.distributors[0]._id;
          this.types.map(type => {
            type.show = true;
          })
        }
        this.checkSelectionType();
        if (this.currType == 'Holding') {
          this.getHoldingSearchSuggestions();
        } else {
          this.getGitSearchSuggestions();
        }
        if (closeProgress) {
          this.popupService.closeProgress();
        }
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  searchDistributor(event){
		let data=this.distributors.filter((item)=>item.distributorName.toLowerCase().includes(event.target.value.toLowerCase()))
		this.filteredDistributors=[...data]
	}
  
  searchHub(event){
		let data = this.hubs.filter((item)=>item.hubCode.toLowerCase().includes(event.target.value.toLowerCase()))
		this.filteredHubs = [...data]
	}

  checkSelectionType() {
    if (this.currDistributorId == '10004') {
      this.types[0].show = false;
      // this.currType = this.types[1].typeName;
    } else {
      this.types[0].show = true;
    }
  }

  changeShowCSV() {
    if (this.showCSV) {
      this.types[0].show = true;
    } else {
      this.types[0].show = false;
      // this.currType = this.types[1].typeName;
    }
    
  }

  changeDistributor(e) {
  
    this.currDistributorId=e._id
    this.currDistributorName=e.distributorName
    this.currDistributorNameCopy=e.distributorName;
    this.checkSelectionType();
    this.medicines = [];
    this.searchResult = [];
    this.currSearchMed.setValue("")
    if (this.currType == 'Holding') {
      this.getHoldingSearchSuggestions();
    } else {
      this.getGitSearchSuggestions();
    }
    console.log( this.currDistributorNameCopy);
    
  }

  changeType() {
    this.currMedToAdd = {};
    this.medicines = [];
    if (this.currType == 'Holding') {
      this.getHoldingSearchSuggestions();
    } else {
      this.getGitSearchSuggestions();
    }
  }
  
  getGitSearchSuggestions() {
    this.supplyService.searchMedicineForPO({searchString: "", distributorId: this.currDistributorId}).subscribe(
      response => {
        let sample = [];
        response.data.map(med => {
          sample.push(med)
        })
        this.searchResult = sample;
        this.searchSuggestions = this.currSearchMed.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterGitSuggestions(value))
          );
      },
      error=> {
        let sample = [];

        this.searchResult = sample;
    
        this.searchSuggestions = this.currSearchMed.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterGitSuggestions(value))
          );
        this.popupService.closeProgress();
        // this.popupService.openError(error.error.error.error);
      }
    )
  }
  
  getHoldingSearchSuggestions() {
    this.supplyService.searchMedicineForPO({searchString: "",distributorId: this.currDistributorId}).subscribe(
      response => {
        let sample = [];
        response.data.map(med => {
          sample.push(med)
        })
        this.searchResult = sample;
        this.searchSuggestions = this.currSearchMed.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterHoldingSuggestions(value))
          );
      },
      error=> {
        let sample = [];

        this.searchResult = sample;
    
        this.searchSuggestions = this.currSearchMed.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterHoldingSuggestions(value))
          );
        this.popupService.closeProgress();
        // this.popupService.openError(error.error.error.error);
      }
    )
  }

  private filterGitSuggestions(value: string): string[] {
    let sample = [];
      this.supplyService.searchMedicineForPO({searchString: value, distributorId: this.currDistributorId}).subscribe(
        response => {
          response.data.map(med => {
            sample.push(med)
          })
        }
      );
    
    return sample;
  }

  private filterHoldingSuggestions(value: string): string[] {
    let sample = [];
      this.supplyService.searchMedicineForPO({searchString: value,distributorId: this.currDistributorId}).subscribe(
        response => {
          response.data.map(med => {
            sample.push(med)
          })
        }
      );
    
    return sample;
  }

  selectNewMed(e, opt) {
    // console.log(e, opt)
    this.currMedToAdd = opt;
    this.currSearchMed.setValue(opt.medicineName)
    console.log(this.currMedToAdd)
  }

  addMedicine() {
    this.popupService.openProgress();
    if (this.newMedQuantity < 1) {
      this.popupService.closeProgress();
      this.popupService.openError("Invalid Quantity");
    }else if(this.newMedQuantity !== this.newMedBlockedForRetail+this.newMedBlockedForWholesale+this.newMedForListing+ this.newMedForGifting){
      this.popupService.closeProgress();
      this.popupService.openError("Please ensure total quantity should be equal to sum of blocked quantities ");
    } else {
      const params={
        quantity: this.newMedQuantity,
        quantityBlockedForWholesale:this.newMedBlockedForWholesale,
        quantityBlockedForRetail:this.newMedBlockedForRetail,
        quantityBlockedForListing	:this.newMedForListing,
        quantityBlockedForGifting:this.newMedForGifting
      }

      this.supplyService.calculatNetValueForPO(params, this.currMedToAdd).subscribe(
        response => {
          console.log(response.data);
          response.data.distributorId = this.currDistributorId;
          this.medicines.push(response.data);
          this.newMedQuantity = 1;
          
          this.popupService.closeProgress();
        },
        error => {
          this.popupService.closeProgress();
          this.newMedQuantity = 1;
          this.popupService.openError(error.error.error.error);
        }
      )
    }
  }

  showNewMedicine() {
    this.showNewMedicineForm = true;
  }

  discardNewMedicine() {
    this.showNewMedicineForm = false;
    this.formNewMedicine.reset();
  }

  addHoldingMedicine() {
    this.popupService.openProgress();
    const params = {
      quantity: this.newMedQuantity
    };
    this.supplyService.addNewGeneratePoMedicine(params, this.currMedToAdd).subscribe(
      response => {
        this.medicines.push(response.data);
        this.showNewMedicineForm = false;
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  addNewMedicine() {
    this.popupService.openProgress();
    const body = {
      medicineName: this.formNewMedicine.value.medicineName,
      packaging: this.formNewMedicine.value.packaging,
      manufacturer: this.formNewMedicine.value.manufacturer
    }
    const params = {
      quantity: this.formNewMedicine.value.quantity
    };
    this.supplyService.addNewGeneratePoMedicine(params, body).subscribe(
      response => {
        this.medicines.push(response.data);
        this.showNewMedicineForm = false;
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  removeItem(index) {
    this.medicines.splice(index, 1);
  }

  

  calculateNetvalue(index) {
    this.popupService.openProgress();
    let body = this.medicines[index];
    let params={
      quantity: this.medicines[index].quantity,
        quantityBlockedForWholesale:this.medicines[index].quantityBlockedForWholesale,
        quantityBlockedForRetail:this.medicines[index].quantityBlockedForRetail,
        quantityBlockedForListing	:this.medicines[index].quantityBlockedForListing,
        quantityBlockedForGifting	:this.medicines[index].quantityBlockedForGifting
    }
    this.supplyService.calculatNetValueForPO(params, this.medicines[index]).subscribe(
      response => {
        this.medicines[index] = response.data;
        this.medicines[index].distributorId = body.distributorId;
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  calculateHoldingNetvalue(index) {
    this.popupService.openProgress();
    let body = this.medicines[index];
    this.supplyService.addNewGeneratePoMedicine({quantity: this.medicines[index].quantity}, this.medicines[index]).subscribe(
      response => {
        this.medicines[index] = response.data;
        this.medicines[index].distributorId = body.distributorId;
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  generatePO() {
    this.popupService.openProgress();
    const params = {};
    const body = {
      clubMedicineList: this.medicines,
      distributorId: this.currDistributorId,
      selectionType: this.currType,
      bulkBilling:this.bulkBilling
    }
    this.supplyService.generateAndDownloadPO(params, body).subscribe(
      response => {        
        this.popupService.closeProgress();
        var reader = new FileReader();

        reader.readAsText(response.body);
        reader.onload = () => {
          const result = String(reader.result)
          const obj = JSON.parse(result)
          console.log(obj);
        this.downloadFile(obj.data.items,obj.data.fileName);

        }
        // this.downloadButtonPush(response.body, response.headers.get("content-disposition").split('=')[1]);
        
        this.popupService.openSuccess("Download Successful");
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }
 

  downloadFile(data, filename) {
    let arrHeader = Object.keys(data[0]);
    let csvData = this.ConvertToCSV(data, arrHeader);
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `${filename}.csv`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    console.log(objArray);
    console.log(headerList);
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    let newHeaders = headerList;

    for (let index in newHeaders) {
      row += newHeaders[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      // let line = (i + 1) + '';
      let line =  ''
      for (let index=0;index<headerList.length; index++) {
        let head = headerList[index];
        if(index < headerList.length-1){
          line +=this.strRep(array[i][head])+',' ;
        }else{
          line +=this.strRep(array[i][head]);

        }
      }
      str += line + '\r\n';
    }
    return str;
  }
  strRep(data) {
    if(typeof data == "string") {
      let newData = data.replace(/,/g, " ");
       return newData;
    }
    else if(typeof data == "undefined") {
      return "-";
    }
    else if(typeof data == "number") {
      return  data.toString();
    }
    else {
      return data;
    }
  }
  // download button auto push
  downloadButtonPush(resp, filename) {
    const a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(resp);
    a.href = url;
    a.download = filename;
    a.click();
    this.closePopup();
  }

  onFileSelect(event) {
		if (event.target.files.length > 0) {
			this._file = event.target.files[0];
			this._mFormGroup.get("file").setValue(this._file);
			console.log(this._file, event.target.files[0]);
		}
	}
  deleteAllMeds(){
    this.medicines=[];
  }

  uploadManualPO() {
    this.popupService.openProgress();
		const formData = new FormData();
		formData.append("manualPOFile", this._file, this._file.name);
		const params = {
			distributorId: this.currDistributorId,
      selectionType: this.currType
		}
    this.fileUploadService.uploadManualPO(formData, params).subscribe(
      response => {
        this.popupService.closeProgress();
        var reader = new FileReader();

        reader.readAsText(response.body);
        reader.onload = () => {
          const result = String(reader.result)
          const obj = JSON.parse(result)
         if(obj.data.statusCode=='200'){
          this.downloadFile(obj.data.manualPOResource.items,obj.data.manualPOResource.fileName);
          this.popupService.openSuccess("Success");

         }else if(obj.data.statusCode=='400'){
          this.downloadFile(obj.data.manualPOCsvItems.csvItems,obj.data.manualPOCsvItems.filename);
          this.popupService.openError(obj.data.message);
         }
       
        }
        
        this.popupService.closeProgress();
      },
      error => {
        let errorObj;
        var reader = new FileReader();
        reader.onloadend = (e) => {
          errorObj = JSON.parse((<any>e.target).result);
          this.showError(errorObj);
        }
        reader.readAsText(error.error);
      }
    )
  }

  showError(errorObj) {
    this.popupService.closeProgress();
    this.popupService.openError(errorObj.error.error)
  }

  closePopup() {
    this.dialogRef.close();
  }

}
