import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Router } from "@angular/router";
import { LogisticsService } from "src/app/core/services/api/logistics.service";
import { PopupService } from "src/app/core/services/popup/popup.service";
import { DialogMapAdjustment } from "./model";

@Component({
  selector: "app-map-adjustment",
  templateUrl: "./map-adjustment.component.html",
  styleUrls: ["./map-adjustment.component.scss"],
})
export class MapAdjustmentComponent implements OnInit {
  InvoiceHeaders = [
    { value: "INV No.", filter: false, key: "invoiceNumber" },
    { value: "RET ID", filter: false, key: "retailerCode" },
    { value: "INV AMT", filter: false, key: "netTotal" },
    {
      value: "AMT Adjusted",
      filter: false,
      key: "amtAdjusted",
    },
  ];
  filteredInvoiceHeader = [];
  filteredInvoiceData = [];
  InvoiceData = [];

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<MapAdjustmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogMapAdjustment,
    public popupService: PopupService,
    private logisticsService: LogisticsService
  ) {}

  ngOnInit(): void {
    this.InvoiceData = [...this.data.data.invoiceMap];
  }

  close(select) {
    this.dialogRef.close(select);
  }

  submitMapping(action) {
    this.popupService.openProgress();

    let bodyData = [];

    for (let i = 0; i < this.InvoiceData.length; i++) {
      let temp = {
        adjustmentAmount: this.InvoiceData[i].adjustmentAmount,
        invoiceNumber: this.InvoiceData[i].invoiceNumber,
      };

      if (this.data.data.sectionSelected === "CN") {
        temp["cnNumber"] = this.data.data.parentData.cnNumber;
        temp["cnType"] = this.data.data.cnType;
      } else if (this.data.data.sectionSelected === "SA") {
        temp["id"] = this.data.data.parentData.id;
      }

      bodyData.push(temp);
    }

    const body = {
      normalMapping: true,
    };

    if (
      this.data.data.sectionSelected === "CN" &&
      this.data.data.invoiceType !== "CREDIT"
    ) {
      body["cnAdjustmentRequestList"] = bodyData;
      body["source"] = "Logistics_CN_Map";

      this.logisticsService.postCnMapping(body).subscribe(
        (resp) => {
          this.popupService.closeProgress();

          if (resp.failureRowCount === 0) {
            this.popupService.openSuccess("Success");

            setTimeout(() => {
              if (action === "stay") {
                this.close(true);
              } else {
                this.close(false);
                this.router.navigate(["/logistics"]);
              }
            }, 2000);
          } else if (resp.failureRowCount > 0 && resp.successRowCount > 0) {
            this.popupService.openError("Some of the entries were not mapped");

            setTimeout(() => {
              if (action === "stay") {
                this.close(true);
              }
            }, 2000);
          } else {
            this.popupService.openError("Failed");

            setTimeout(() => {
              if (action === "stay") {
                this.close(true);
              }
            }, 2000);
          }
        },
        (error) => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      );
    } else if (
      this.data.data.sectionSelected === "SA" &&
      this.data.data.invoiceType !== "CREDIT"
    ) {
      body["salaryAdjustmentRequestList"] = bodyData;
      body["source"] = "Logistics_SA_Map";

      this.logisticsService.postSalaryAdjustmentMapping(body).subscribe(
        (resp) => {
          this.popupService.closeProgress();
          if (resp.failureRowCount === 0) {
            this.popupService.openSuccess("Success");

            setTimeout(() => {
              if (action === "stay") {
                this.close(true);
              } else {
                this.close(false);
                this.router.navigate(["/logistics"]);
              }
            }, 2000);
          } else if (resp.failureRowCount > 0 && resp.successRowCount > 0) {
            this.popupService.openError("Some of the entries were not mapped");

            setTimeout(() => {
              if (action === "stay") {
                this.close(true);
              }
            }, 2000);
          } else {
            this.popupService.openError("Failed");

            setTimeout(() => {
              if (action === "stay") {
                this.close(true);
              }
            }, 2000);
          }
        },
        (error) => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      );
    } else if (
      this.data.data.sectionSelected === "BS" ||
      this.data.data.invoiceType === "CREDIT"
    ) {
      let creditPaymentRequestDTO = {
        amountPaid: 0,
        paymentMode:
          this.data.data.sectionSelected === "BS"
            ? "BANK-STATEMENT"
            : this.data.data.sectionSelected === "SA"
            ? "SALARY-ADJUSTMENT"
            : "CN-ADJUSTMENT",
        source:
          this.data.data.sectionSelected === "BS"
            ? "Logistics_BS_Map"
            : this.data.data.sectionSelected === "SA"
            ? "Logistics_SA_Map"
            : "Logistics_CN_Map",
        creditBillPaymentRequest: [],
        uniqueId:
          this.data.data.sectionSelected === "BS"
            ? this.data.data.parentData.utrNoId
            : this.data.data.sectionSelected === "SA"
            ? this.data.data.parentData.id
            : this.data.data.parentData.cnNumber,
      };

      this.InvoiceData.forEach((inv) => {
        creditPaymentRequestDTO.amountPaid += inv.adjustmentAmount;
        let tempObj = {
          amountPaid: inv.adjustmentAmount,
          creditInvoice: this.data.data.invoiceType === "CREDIT" ? true : false,
          invoiceNumber: inv.invoiceNumber,
          retailerCode: inv.retailerCode,
        };

        creditPaymentRequestDTO.creditBillPaymentRequest.push(tempObj);
      });

      this.logisticsService
        .uploadCreditBulkPayment(creditPaymentRequestDTO)
        .subscribe(
          (resp) => {
            this.popupService.closeProgress();

            if (resp.data.status.toLowerCase() === "success") {
              this.popupService.openSuccess("Success");

              setTimeout(() => {
                if (action === "stay") {
                  this.close(true);
                } else {
                  this.close(false);
                  this.router.navigate(["/logistics"]);
                }
              }, 2000);
            } else {
              this.popupService.openError("Failed");

              setTimeout(() => {
                if (action === "stay") {
                  this.close(true);
                }
              }, 2000);
            }
          },
          (error) => {
            this.popupService.closeProgress();
            this.popupService.openError(error.error.error.error.message);
          }
        );
    }
  }
}
