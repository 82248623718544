import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FinanceCnGeneratedDialogModel } from './model';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { FinanceService } from 'src/app/core/services/api/finance.service';

@Component({
  selector: 'app-finance-cn-generated',
  templateUrl: './finance-cn-generated.component.html',
  styleUrls: ['./finance-cn-generated.component.scss']
})
export class FinanceCnGeneratedComponent implements OnInit {
  creditNotes = [];

  constructor(
    public dialogRef: MatDialogRef<FinanceCnGeneratedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FinanceCnGeneratedDialogModel,
    private financeService: FinanceService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    this.popupService.openProgress();
    this.getCreditNotes();
  }

  getCreditNotes() {
    const params = {
      cnNumbers: this.data.invoices
    };
    this.financeService.getCreditNotes(params).subscribe(
      response => {
        this.creditNotes = response.data;
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  openAdjustedInvoicePopup(creditNote) {
    this.popupService.openFinanceInvoiceAdjust(creditNote.adjustedPIIds)
  }

  close() {
    this.dialogRef.close();
  }
}
