import { CommonModule } from "@angular/common";
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule, MatRippleModule, MatSidenavModule, MatToolbarModule } from "@angular/material";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "../../material.module";
import { TbWaterfallModule } from "../../tb/tb-waterfall/tb-waterfall.module";
import { AdminComponent } from "./admin.component";
import {MedicineMappingMenuModule} from 'src/app/shared/medicine-mapping-menu/medicine-mapping-menu.module';


@NgModule({
  declarations: [AdminComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    MatRippleModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    TbWaterfallModule,
    RouterModule,
    MedicineMappingMenuModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  exports: [AdminComponent],
})
export class AdminModule {}
