import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelConfirm } from '../dialog-confirm/model';
import { DialogModelComment } from './model';
import { AdminService } from "src/app/core/services/api/admin.service";
import { PopupService } from "src/app/core/services/popup/popup.service";

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
  comment = "";
  empCode=null;
  roles={
    data:[],
    show:false,
    selectedRole:[]
  }
  internalUser=false;
  constructor(
    public dialogRef: MatDialogRef<CommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelComment,
    private adminService: AdminService,
    private popupService: PopupService) { }

  ngOnInit(): void {
    if(this.data.text.string==="Add Role"){
      this.getRoles()
    } 
    this.roles.selectedRole=[]
  }

  submit() {
    // console.log(this.roles.selectedRole.includes("SALES_EXECUTIVE"))
    // if(this.roles.selectedRole.includes("SALES_EXECUTIVE")){
        // if(this.empCode && this.empCode.length){
           this.dialogRef.close({empCode:this.empCode,comment: this.comment,roles:this.roles.selectedRole,internalUser:this.internalUser});

        // }else{
        //   this.popupService.openError("Employee Code is Mandatory for Sales Executive")
        // }
    // }else{
    //   this.dialogRef.close({empCode:this.empCode,comment: this.comment,roles:this.roles.selectedRole,internalUser:this.internalUser});

    // }
  }
  
  getRoles(){
    this.popupService.openProgress()
    this.adminService.getAllRoles().subscribe((response)=>
      {

      this.roles.data=response.data
      if(this.data.text.string==="Add Role"){
         this.roles.show=true
      }
      this.popupService.closeProgress()
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    })
  }

  internalToggle(event){
    this.internalUser=event.checked;
    if(event.checked){
      this.roles.show=true;
    }else{
      this.roles.show=false;
    }
   
    this.getRoles();
  }
  cancel() {
    this.dialogRef.close(false);
  }

}
