import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "src/app/common/api.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BinService {
  API_URL = environment.hubUrl + "/admin/saveoinventory/audit/bin";
  API_URL2 =
    environment.hubUrl +
    "/admin/saveoinventory/itemsToBeAdded/item/update/binAndFoundQuantity";
  API_URL3 =
    environment.hubUrl +
    "/admin/saveoinventory/itemsToBeAdded/item/update/binAndFoundQuantity/v2";

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getSampleCSV(): Observable<any> {
    const urlPath = this.API_URL + "/sampleCSV";
    return this.http.get<any>(urlPath, { observe: "response" });
  }

  uploadCsv(file: any): Observable<any> {
    const urlPath = this.API_URL + "/uploadCSV";
    return this.http.post<any>(urlPath, file);
  }

  adnSearchBin(params): Observable<any> {
    const urlPath = this.API_URL + "/search/adnId";
    return this.http.get<any>(urlPath, { observe: "response", params });
  }

  parentSearchBin(params): Observable<any> {
    const urlPath = this.API_URL + "/search/parentId";
    return this.http.get<any>(urlPath, { observe: "response", params });
  }

  submitBin(params, body): Observable<any> {
    return this.http.post<any>(this.API_URL2, body, {
      observe: "response",
      params,
    });
  }

  submitManualPOBin(params, body): Observable<any> {
    return this.http.post<any>(this.API_URL3, body, {
      observe: "response",
      params,
    });
  }


  getRepackingRacks(): Observable<any> {
    const urlPath = environment.BASE_URL + "/v2/admin/getRackNumber";
    return this.http.get<any>(urlPath, { observe: "response" });
  }

  updateRacks(params, body): Observable<any> {
    let urlPath=environment.BASE_URL +"/v2/admin/uploadEmptyBinsOfRacks"
    return this.http.post<any>(urlPath, body, {
      observe: "response",
      params,
    });
  }


}
