import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogModelCheckManualBill } from './model';
import { LogisticsService } from "src/app/core/services/api/logistics.service";
import { PopupService } from "src/app/core/services/popup/popup.service";

@Component({
  selector: 'app-check-manual-bill',
  templateUrl: './check-manual-bill.component.html',
  styleUrls: ['./check-manual-bill.component.scss']
})
export class CheckManualBillComponent implements OnInit {
  bills = [];
  currBill = "";

  constructor(
    private logisticsService: LogisticsService,
    private popupService: PopupService,
    public dialogRef: MatDialogRef<CheckManualBillComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelCheckManualBill) { }

  ngOnInit(): void {
    console.log(this.data)
    this.popupService.openProgress();
    this.getManualBills();
  }

  getManualBills() {
    this.logisticsService.getManualBills({invoiceNumber: this.data.invoiceNumber}).subscribe(
      response => {
        this.bills = response.data;
        // if (this.bills.length > 0) {
        //   this.currBill = this.bills[0];
        // }
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  adjustInCurrentBill() {
    let params: any;
    if (this.currBill.length > 0) {
      params = {
        adjustInCurrentBill: true,
        adjustInInvoiceNumber: this.currBill
      }
    } else {
      params = {
        adjustInCurrentBill: true
      }
    }
    this.logisticsService.placeSalesReturn(params, this.data.body).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
        this.dialogRef.close();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
        this.dialogRef.close();
      }
    )
  }

  close() {
    this.dialogRef.close();
  }

}
