import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../../../common/api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductMasterService {
  API_URL: string = environment.adminUrl;
  preprod_url = "https://preprod.saveo.in/admin";

  constructor(private http: HttpClient, private apiService: ApiService) { }

  // get all to be mapped medicines data 
  getToBeMappedData(params): Observable<any> {
    const urlPath = this.API_URL + "/productMaster/MedicineToBeMapped";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  // get all compositions for medicine mapping 
  getAllCompositions(params): Observable<any> {
    const urlPath = this.API_URL + "/productMaster/Composition";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  // get all manufacturers for medicine mapping 

  getAllManufacturers(params): Observable<any> {
    const urlPath = this.API_URL + "/productMaster/Manufacturer";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

   // get all groups for medicine mapping 

   getAllGroups(params): Observable<any> {
    const urlPath = this.API_URL + "/productMaster/getAllGroup";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  // get all super groups for medicine mapping 

  getAllSuperGroups(params): Observable<any> {
    const urlPath = this.API_URL + "/productMaster/getAllSuperGroup";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  // get all cautions for medicine mapping 

  getAllCautions(params): Observable<any> {
    const urlPath = this.API_URL + "/productMaster/getCautionByName";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  
 // get all diseases for medicine mapping 

 getAllDiseases(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/getDiseaseByName";
  return this.apiService.doGetObservableForQuery(urlPath, params);
 }

 // get all groups by supergroup id for medicine mapping 

 getAllGroupsBySuperGroup(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/getGroupMappingBySuperGroup";
  return this.apiService.doGetObservableForQuery(urlPath, params);
 }

 // get all salts for medicine mapping 

 getAllSalts(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/getSaltByName";
  return this.apiService.doGetObservableForQuery(urlPath, params);
 }

   // get all side effects for medicine mapping 

 getAllSideEffects(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/getSideEffectByName";
  return this.apiService.doGetObservableForQuery(urlPath, params);
 }


//  get all marketings 

getAllMarketings(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/getMarketingBy";
  return this.apiService.doGetObservableForQuery(urlPath, params);
 }


//  get all catewgories 

getAllCategories(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/getCategory";
  return this.apiService.doGetObservableForQuery(urlPath, params);
 }

 //  get all schedule drugs 

getAllScheduleDrugs(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/getScheduleDrug";
  return this.apiService.doGetObservableForQuery(urlPath, params);
 }

 //  get all product forms 

getAllProductForms(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/getProductForm";
  return this.apiService.doGetObservableForQuery(urlPath, params);
 }

 //  get all product types 

getAllProductType(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/getProductType";
  return this.apiService.doGetObservableForQuery(urlPath, params);
 }

 //  get all product units 

getAllProductUnits(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/getProductUnit";
  return this.apiService.doGetObservableForQuery(urlPath, params);
 }

 getParentsBySearch(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/search/getParentMedicine";
  return this.apiService.doGetObservableForQuery(urlPath, params);
 }

 getParentsById(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/getParentMedicineById";
  return this.apiService.doGetObservableForQuery(urlPath, params);
 }

 getChildByParentId(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/getChildByParentId";
  return this.apiService.doGetObservableForQuery(urlPath, params);
 }
 getChildById(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/getChildMedicineById";
  return this.apiService.doGetObservableForQuery(urlPath, params);
 }
  
 mapMedicineRequest(params, body): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/mappingRequest";
  return this.apiService.doPostObservableForQuery(urlPath, body, params);
}
createMedicineRequest(params, body): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/createNewMedicine";
  return this.apiService.doPostObservableForQuery(urlPath, body, params);
}
updateMedicineRequest(params, body): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/updateRequest";
  return this.apiService.doPostObservableForQuery(urlPath, body, params);
}

// get all to be created medicines data 
getToBeCreatedData(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/MedicineToBeCreated";
  return this.apiService.doGetObservableForQuery(urlPath, params);
}

// get be created medicine data 
getToBeCreatedMedData(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/getParentCreation";
  return this.apiService.doGetObservableForQuery(urlPath, params);
}

// get be created medicine data 
getToBeApprovedMedData(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/MedicineToBeApproved";
  return this.apiService.doGetObservableForQuery(urlPath, params);
}
// get be created medicine data 
getToBeApprovedCreatedMedData(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/MedicineToBeApprovedCreation";
  return this.apiService.doGetObservableForQuery(urlPath, params);
}

// get be created medicine data 
getFullParentDataById(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/getFullParentMedicineById";
  return this.apiService.doGetObservableForQuery(urlPath, params);
}

// get be created medicine data 
updateVerifiedMeds(params,body): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/updateVerifiedMedicine";
  return this.apiService.doPostObservableForQuery(urlPath,body, params);
}

// get be salts
getSalts(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/getSaltByName";
  return this.apiService.doGetObservableForQuery(urlPath, params);
}

// create composition 
createComposition(params,body): Observable<any> {
  const urlPath = this.API_URL + "/productMasterEntity/compositionEntityCreate";
  return this.apiService.doPostObservableForQuery(urlPath,body, params);
}
//  entities common create api 
createEntity(params,body): Observable<any> {
  const urlPath = this.API_URL + "/productMasterEntity/genericEntityCreate";
  return this.apiService.doPostObservableForQuery(urlPath,body, params);
}

// get to be aproved entities data
getToBeApprovedEntities(params): Observable<any> {
  const urlPath = this.API_URL + "/productMasterEntity/EntityToBeApproved";
  return this.apiService.doGetObservableForQuery(urlPath, params);
}

//  entities common approve api 
approveEntity(params,body): Observable<any> {
  const urlPath = this.API_URL + "/productMasterEntity/genericEntityApprove";
  return this.apiService.doPostObservableForQuery(urlPath,body, params);
}

// get to be verifiied entities data
getVerifiedEntities(params): Observable<any> {
  const urlPath = this.API_URL + "/productMasterEntity/getVerifiedEntity";
  return this.apiService.doGetObservableForQuery(urlPath, params);
}

// update verified entities common  api 
updateEntity(params,body): Observable<any> {
  const urlPath = this.API_URL + "/productMasterEntity/updateVerifiedEntity";
  return this.apiService.doPostObservableForQuery(urlPath,body, params);
}
// get to be verifiied entities data
getMasterMedicineById(params): Observable<any> {
  const urlPath = this.API_URL + "/productMaster/getMasterMedicineById";
  return this.apiService.doGetObservableForQuery(urlPath, params);
}

// get to be entities creation data
getEntityCreation(params): Observable<any> {
  const urlPath = this.API_URL + "/productMasterEntity/getEntityCreation";
  return this.apiService.doGetObservableForQuery(urlPath, params);
}
}
