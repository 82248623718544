import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from '../../../common/api.service';

@Injectable({
  providedIn: 'root'
})
export class HubService {

  API_URL = environment.hubUrl + "/hub";

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getHubs(): Observable<any> {
    const urlPath = this.API_URL + "/fetchAllHub";
    return this.http.get<any>(urlPath, {observe: 'response'});
  }

  updateHub(params, body): Observable<any> {
    const urlPath = this.API_URL + "/updateHubForUser";
    return this.apiService.doUpdateWithBody(urlPath, body, params);
  }

  getServicingHubs(params): Observable<any> {
    const urlPath = this.API_URL + "/fetchServicingHubs";
    return this.http.get<any>(urlPath, {observe: 'response', params: params});
  }

  getDistributorsByServicingHub(params): Observable<any> {
    const urlPath = environment.hubUrl + "/distributor/home/findByServicingHubId";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getRetrievalCodes(params): Observable<any> {
    const urlPath = environment.hubUrl + "/admin/banner/getAllRetrievalCodeByHub";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

}
