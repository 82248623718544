import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from "src/app/material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatIconModule, MatRippleModule } from "@angular/material";
import { CommonFilterIconsComponent } from './common-filter-icons.component';

@NgModule({
  declarations: [CommonFilterIconsComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    MatIconModule,
    MatRippleModule,
  ],
  exports: [CommonFilterIconsComponent]
})
export class CommonFilterIconsModule { }
