import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FinanceInvoiceAdjustDialogModel } from './model';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { FinanceService } from 'src/app/core/services/api/finance.service';

@Component({
  selector: 'app-finance-invoice-adjust',
  templateUrl: './finance-invoice-adjust.component.html',
  styleUrls: ['./finance-invoice-adjust.component.scss']
})
export class FinanceInvoiceAdjustComponent implements OnInit {
  invoices = [];

  constructor(
    public dialogRef: MatDialogRef<FinanceInvoiceAdjustComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FinanceInvoiceAdjustDialogModel,
    private financeService: FinanceService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    this.popupService.openProgress();
    this.getInvoices();
  }

  getInvoices() {
    const params = {
      piIds: this.data.piIds
    };
    this.financeService.getInvoices(params).subscribe(
      response => {
        this.invoices = response.data;
        console.log(this.invoices)
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  close() {
    this.dialogRef.close();
  }

}
