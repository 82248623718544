import * as React from 'react';
// import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import './CreditBillsTable.scss';
import axios from 'axios';
import { message, Modal, Popover, Table, Button } from 'antd';
import { Steps } from 'antd';
import { Tag, Divider } from 'antd';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Select, Radio } from 'antd';

import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import { environment } from 'src/environments/environment';
import { split } from '@angular-devkit/core';
import { E } from '@angular/cdk/keycodes';
import { Icon, Input } from 'semantic-ui-react';
import { AuthComponent } from '../../../../../registration/components/auth/auth.component';
const { Option } = Select;

const { Step } = Steps;
const options = [
  { key: 1, text: 'None', value: 1 },
  { key: 2, text: 'Cheque Cancelled', value: 2 },
  { key: 3, text: 'Cheque Bounced', value: 3 },
  { key: 4, text: 'Cheque Success', value: 4 }
]

const children = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i} value={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

export interface IAppProps {
  name:String,
  submenuSelected:String,
  token:String
}

export interface MainState {
  page:number,
  invoices:any[],
  data:any[],
  pageSize:number,
  API_URL:string,
  totalPages:number,
  fetchedPages:any[],
  visible:boolean,
  oldStatus:string,
  newStatus:string,
  invoiceNumber:string,
  invoiceStatuses:[],
  tableDataLoading:boolean,
  submenuSelectedFromProps:String,
  searchQuery:String,
  chequeNumber:String,
  selectedChequeNumber:String,
  selectedFilter:String,

}

export default class CreditBillsTable extends React.Component<IAppProps,MainState> {
  constructor(props: IAppProps | Readonly<IAppProps>) {
    super(props);
    this.state = { 
      page:1,
      invoices:[],
      data : [],
      pageSize:20,
      API_URL:undefined,
      totalPages:40,
      fetchedPages:[],
      visible:false,
      oldStatus:'',
      newStatus:'',
      chequeNumber:'',
      invoiceNumber:'',
      invoiceStatuses:[],
      tableDataLoading:false,
      submenuSelectedFromProps:"all",
      searchQuery:"",
      selectedChequeNumber:'',
      selectedFilter:'',
     };
}

static getDerivedStateFromProps(nextProps, prevState) {
  // console.log(prevState.submenuSelected,nextProps.submenuSelected)
    if (prevState.submenuSelected !== nextProps.submenuSelected) {
    return {
      submenuSelectedFromProps: nextProps.submenuSelected
    };
  }

  

  // Return null to indicate no change to state.
  return null;
}

componentDidUpdate(prevProps, prevState) {
  console.log(prevState.submenuSelectedFromProps,this.state.submenuSelectedFromProps)
  if (prevState.submenuSelectedFromProps !== this.state.submenuSelectedFromProps){
     
      if(prevState.submenuSelectedFromProps!=this.state.submenuSelectedFromProps){
        console.log("update using menu")
        let inputSearch:any = document.getElementById('searchInputAboveTable');
        let inputSearch1:any = document.getElementById('searchInputAboveTable1');
        let inputSearch2:any = document.getElementById('searchInputAboveTable2');
        let inputSearch3:any = document.getElementById('searchInputAboveTable3');
        let inputSearch4:any = document.getElementById('searchInputAboveTable4');

        inputSearch.value = '';
        inputSearch1.value='';
        inputSearch2.value='';
        inputSearch3.value='';
        inputSearch4.value='';
        this.getNewTableDataforSelectedMenu();
      }
      // this.getNewTableDataforSelectedMenu();
  }


}
async getAccessToken(){
  
}
  async getNewTableDataforSelectedMenu() {
    this.setState({
      tableDataLoading:true,
      page:1
    },async()=>{
      let invoices
      if(this.state.submenuSelectedFromProps==='livelimits'){
         invoices = await axios.get(this.state.API_URL+"user/getInvoicesByPage/"+(this.state.page-1)+"/"+this.state.submenuSelectedFromProps,{
        headers: {
          'Authorization': this.props.token
        }
      });
      }else{
        invoices = await axios.get(this.state.API_URL+"user/getChequeInvoices",{
          params:{
            pageNumber:this.state.page-1,
          },
          headers: {
            'Authorization': this.props.token
          }
        });
      }
      console.log(invoices)
      if(invoices.data.data){
        let invoicesforState = invoices.data.data;
        let stateInvoices = this.state.invoices;
        stateInvoices = [...stateInvoices,...invoicesforState];
        let fetchedPages = this.state.fetchedPages;
        fetchedPages = [...fetchedPages,this.state.page];
        this.setState({
          data:stateInvoices,
          fetchedPages,
          tableDataLoading:false
        })
      }
    })
    
  }



 handleChange=(value)=> {
  console.log(`Selected: ${value}`);
}


retailerCodeFilter=()=>{
  console.log("dxfghj");
  return(
    <>
    <Select
        mode="tags"
        placeholder="Please select"
        defaultValue={['a10', 'c12']}
        // onChange={(e)=>this.handleChangeForRetailerCode(e)}
        style={{ width: '100%' }}
      >
        {children}
      </Select>
    </>
  )
}

informTask = () => {
  message
    .loading('Action in progress..', 1.5)
    .then(() => {
      axios.put(this.state.API_URL+"admin/updateBiStatusForCreditUser/"+this.state.invoiceNumber+"/"+this.state.oldStatus+"/"+this.state.newStatus+"/"+this.state.selectedChequeNumber,{},{
        headers: {
          'Authorization': this.props.token
        }
      }).then(data=>{
        console.log(data.data);
        if(data.data.error){
          message.error(data.data.error.message, 2.5)
        }
        else{
          let stateInvoices = [...this.state.data];
          console.log("si",stateInvoices);
          for(let i=0;i<stateInvoices.length;i++){
            if(stateInvoices[i].invoiceNumber==this.state.invoiceNumber && stateInvoices[i].chequeNumber==this.state.chequeNumber){
              let invoice = stateInvoices[i];
              invoice.invoiceStatus = this.state.newStatus;
              invoice.chequeStatus = this.state.newStatus;
              stateInvoices[i] = invoice;
            }
            else if(stateInvoices[i].invoiceNumber==this.state.invoiceNumber){
              let invoice = stateInvoices[i];
              invoice.invoiceStatus = this.state.newStatus;
              stateInvoices[i] = invoice;
            }
          }
          this.setState({
            data:stateInvoices
          })
          message.success(data.data.data.message, 2.5)
        }
       
      }).catch((err)=>{
        console.log(err.response.data);
        message.error(err.response.data.error.error.message, 2.5)
      })
      
    })
    // .then(() => message.info('Loading finished is finished', 2.5));
};

  columns:any = [
  {
    title: 'Invoice Date',
    width: 100,
    dataIndex: 'invoiceDate',
    key: 'invoiceDate',
    fixed: 'left',
    render:(text)=>{
      const content = text.split("T")[0].split("-")[2]+"-"+text.split("T")[0].split("-")[1]+"-"+text.split("T")[0].split("-")[0]+" "+text.split("T")[1].split(":")[0]+":"+text.split("T")[1].split(":")[1] +" "+"(24 hour format)";
      return(
        <>
          <Popover content={content} title="Timestamp">
            {text.split("T")[0].split("-")[2]+"-"+text.split("T")[0].split("-")[1]+"-"+text.split("T")[0].split("-")[0]}
        </Popover>
        </>
      )
    }
  },
  {
    title: 'Invoice Number',
    width: 100,
    dataIndex: 'invoiceNumber',
    key: 'invoiceNumber',
    fixed: 'left',
  },
  { title: 'Loan Identifier', dataIndex: 'loanIdentifier', key: 'loanIdentifier' },
  { title: 'Retailer Code', dataIndex: 'retailerCode', key: 'retailerCode' },
  { title: 'Transaction Id', dataIndex: 'chequeNumber', key: 'chequeNumber' },
  { title: 'Amount', dataIndex: 'actionAmount', key: 'actionAmount' },
  { title: 'Bill Number', dataIndex: 'billNumber', key: 'billNumber' },
  { title: 'Cheque Number', dataIndex: 'chequeNumber', key: 'chequeNumber' },
  { title: 'Amount Paid', dataIndex: 'actionAmount', key: 'actionAmount' },
  { title: 'Action Date', dataIndex: 'actionDate', key: 'actionDate', width:100 },
  // { title: 'Nbfc Id', dataIndex: 'nbfcId', key: 'nbfcId' },
  { title: 'Nbfc Partner Name', dataIndex: 'nbfcPartnerName', key: 'nbfcPartnerName' },
  { title: 'Bill Status', dataIndex: 'billStatus', key: 'billStatus' ,width:100,render:(text)=>{
    return(
    <>
    {(text=="PAID") && <Tag style={{fontSize:"8px"}} color="success">{text}</Tag>}
    {text=="PARTIALLY_PAID" && <Tag style={{fontSize:"8px"}} color="processing">{text}</Tag>}
    {(text!="PARTIALLY_PAID" && text!="PAID") && <Tag style={{fontSize:"8px"}} color="warning">{text}</Tag>}
    </>
    );
  }},
  { title: 'Cheque Status', dataIndex: 'chequeStatus',width:120, key: 'chequeStatus' ,render:(text)=>{
    return(
    <>
    {(text=="CHEQUE_CANCELLED") && <Tag style={{fontSize:"8px"}} color="error">{text}</Tag>}
    {(text=="CHEQUE_BOUNCED") && <Tag style={{fontSize:"8px"}} color="error">{text}</Tag>}
    {text=="PAYMENT_INITIATED" && <Tag style={{fontSize:"8px"}} color="processing">{text}</Tag>}
    {(text=="PAYMENT_RECEIVED" || text=="PARTIAL_PAYMENT_RECEIVED") && <Tag style={{fontSize:"8px"}} color="success">{text}</Tag>}
    {text!="CHEQUE_CANCELLED" && text!="CHEQUE_BOUNCED" && text!="PAYMENT_INITIATED" && text!="PAYMENT_RECEIVED" && text!="PARTIAL_PAYMENT_RECEIVED" && <Tag style={{fontSize:"8px"}} color="processing">{text}</Tag>}
    </>
    );
  }},
  { title: 'Invoice Status', dataIndex: 'invoiceStatus', key: 'invoiceStatus',width:150,
  filters: [
    { text: 'PAYMENT_RECEIVED', value: 'PAYMENT_RECEIVED' },
    { text: 'PARTIAL_PAYMENT_RECEIVED', value: 'PARTIAL_PAYMENT_RECEIVED' },
    { text: 'DELIVERED', value: 'DELIVERED' },
    { text: 'OUT_FOR_DELIVERY', value: 'OUT_FOR_DELIVERY' },
    { text: 'CHEQUE_BOUNCED', value: 'CHEQUE_BOUNCED' },
    { text: 'READY_TO_DISPATCH', value: 'READY_TO_DISPATCH' },

  ],
  onFilter: (value, record) => record.invoiceStatus.indexOf(value) === 0,
  render:(text)=>{
    return(
    <>
    {(text=="PAYMENT_RECEIVED" || text=="PARTIAL_PAYMENT_RECEIVED") && <Tag color="success">{text}</Tag>}
    {text=="DELIVERED" && <Tag style={{fontSize:"8px"}} color="processing">{text}</Tag>}
    {text=="CHEQUE_BOUNCED" && <Tag style={{fontSize:"8px"}} color="error">{text}</Tag>}
    {(text!="PAYMENT_RECEIVED" && text!="CHEQUE_BOUNCED" && text!="PARTIAL_PAYMENT_RECEIVED" && text!="DELIVERED") && <Tag style={{fontSize:"8px"}} color="warning">{text}</Tag>}
    </>
    );
  }
 },
  {
    title: 'Action',
    key: 'operation',
    fixed: 'right',
    width: 100,
    render: (text) => {
      const menu = (
        <Menu>
          <Menu.Item disabled={(text.chequePaymentInvoice)?false:true} key={1}>
            <a target="_blank" rel="noopener noreferrer" onClick={()=>this.showModal(text,"cancelled")}>
              Cheque Cancelled
            </a>
          </Menu.Item>
          <Menu.Item disabled={(text.chequePaymentInvoice && text.chequeStatus==='PAYMENT_NOT_INITIATED' && this.state.submenuSelectedFromProps!='postDatedCheque')?false:true} key={1}>
            <a target="_blank" rel="noopener noreferrer" onClick={()=>this.showModal(text,"bounced")}>
              Cheque Bounced
            </a>
          </Menu.Item>
          <Menu.Item disabled={(text.chequePaymentInvoice && text.chequeStatus==='PAYMENT_NOT_INITIATED' && this.state.submenuSelectedFromProps!='postDatedCheque')?false:true} key={2}>
            <a target="_blank" rel="noopener noreferrer" onClick={()=>this.showModal(text,"cleared")}>
              Cheque Cleared
            </a>
          </Menu.Item>
          <Menu.Item disabled key={3}>
            <a target="_blank" rel="noopener noreferrer" onClick={()=>this.showModal(text,"received")}>
              Payment Received
            </a>
          </Menu.Item>
          {/* <Menu.Item danger>a danger item</Menu.Item> */}
        </Menu>
      );
      return(
        <Dropdown overlay={menu}>
          <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            Select <DownOutlined />
          </a>
        </Dropdown>
      )
    },
  },
];
  componentDidMount(){
    let API_URL: string = environment.adminUrl + '/credit/';
    //let API_URL: string = "http://localhost:5000" + '/credit/';
    console.log(API_URL);
    this.setState({
      API_URL,
      tableDataLoading:true
    },()=>{
      this.getAllCreditBills();
    })
    
  }
  
   handleChangeForSearchQueryfilter=async(type='',value) =>{
    
    this.setState({
      tableDataLoading:true,
      page:1,
      fetchedPages:[]
    })
    console.log(this.state.API_URL)
    let invoices = await axios.get(this.state.API_URL+"user/getChequeInvoices",{
      params:{
        pageNumber:0,
        searchFilter:type,
        value:value?value:this.state.searchQuery
      },
      headers: {
        'Authorization': this.props.token
      }
    });
    console.log(invoices)
    if(invoices.data.data){
      let invoicesforState = invoices.data.data;
      let stateInvoices = this.state.invoices;
      stateInvoices = [...stateInvoices,...invoicesforState];
      let fetchedPages = this.state.fetchedPages;
      fetchedPages = [...fetchedPages,this.state.page];
      this.setState({
        data:stateInvoices,
        fetchedPages,
        tableDataLoading:false
      })
    }
  }

  onPageChange=()=>{
    
  }

  getAllCreditBills=async()=>{
    let invoices = await axios.get(this.state.API_URL+"user/getChequeInvoices",{
      params:{
        pageNumber:this.state.page-1,
      },
      headers: {
        'Authorization': this.props.token
      }
    });
    console.log(invoices)
    if(invoices.data.data){
      let invoicesforState = invoices.data.data;
      let stateInvoices = this.state.invoices;
      stateInvoices = [...stateInvoices,...invoicesforState];
      let fetchedPages = this.state.fetchedPages;
      fetchedPages = [...fetchedPages,this.state.page];
      this.setState({
        data:stateInvoices,
        fetchedPages,
        tableDataLoading:false
      })
    }
  }

   getdataUsingSearchQuery=(type,value=null)=>{
    if(type==="ACTIONDATE"){
      this.handleChangeForSearchQueryfilter(type,value);
    }else{
      let inputSearch:any = document.getElementById('searchInputAboveTable');
      let inputSearch1:any = document.getElementById('searchInputAboveTable1');
      let inputSearch2:any = document.getElementById('searchInputAboveTable2');
      let inputSearch3:any = document.getElementById('searchInputAboveTable3');
     
      let query = type==="RETAILERCODE"?inputSearch.value.trim(): type==="INVOICENUMBER"?inputSearch1.value.trim(): type==="CHEQUENUMBER"?inputSearch2.value.trim() :type==="BILLNUMBER"?inputSearch3.value.trim() :this.state.searchQuery.trim();
      if(query.length<3){
        message.error('Please enter valid search filter.');
        return;
      }
  
      this.setState({
        selectedFilter:type
      })
      this.handleChangeForSearchQueryfilter(type,value=query);
    }
    
} 

  changeCreditBillsInvoicePageNumber=async(pageNumber,pageSize)=>{
    this.setState({
      page:pageNumber
    })
    let statuses = this.state.invoiceStatuses;
    console.log(statuses.toString());
    // let invoices = await axios.get(this.state.API_URL+"user/getInvoicesByPage/"+((pageNumber-1)+statuses.length>0?"?invoiceStatuses="+statuses.toString():""));
    let invoices = await axios.get(this.state.API_URL+"user/getChequeInvoices",{
      params:{
        pageNumber:pageNumber-1,
        searchFilter:this.state.selectedFilter,
        value:this.state.searchQuery
      },
      headers: {
        'Authorization': this.props.token
      }
    });
    console.log(invoices)
    if(invoices.data.data){
      let invoicesforState = invoices.data.data;
      let stateInvoices = this.state.invoices;
      stateInvoices = [...stateInvoices,...invoicesforState];
      let totalPages = this.state.totalPages;
      if(stateInvoices.length%this.state.pageSize==0 && invoicesforState.length!=0 && !this.state.fetchedPages.includes(pageNumber)){
        totalPages +=20; 
      }
      let fetchedPages = this.state.fetchedPages;
      fetchedPages = [...fetchedPages,pageNumber];
      this.setState({
        data:stateInvoices,
        totalPages,
        fetchedPages,
        tableDataLoading:false
      })
    }
  }

  showModal = (event,status) => {
    let map = new Map<any,any>();
    map.set("bounced","CHEQUE_BOUNCED");
    map.set("cleared","PAYMENT_RECEIVED");
    map.set("cancelled","CHEQUE_CANCELLED");
    console.log(event,status);
    this.setState({
      visible: true,
      oldStatus: event.invoiceStatus,
      newStatus: map.get(status),
      invoiceNumber: event.invoiceNumber,
      chequeNumber: event.chequeNumber,
      selectedChequeNumber: event.chequeNumber
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };
  hideConfirmModal = () => {
    this.setState({
      visible: false,
    },()=>{
      this.informTask();
    });
  };

  
  setSearchQuery=(value,type='')=>{

   
   if(type==="ACTIONDATE") {
    this.setState({
      searchQuery:(new Date(value).getTime()).toString(),
      selectedFilter:type
    })
    this.getdataUsingSearchQuery(type,(new Date(value).getTime()).toString())
   }else{
     if(value?.length){
      this.setState({
        searchQuery:value,
        selectedFilter:type
      })
     }
   
   }
  }
  
  clearAllFilters=()=>{
    let inputSearch:any = document.getElementById('searchInputAboveTable');
    let inputSearch1:any = document.getElementById('searchInputAboveTable1');
    let inputSearch2:any = document.getElementById('searchInputAboveTable2');
    let inputSearch3:any = document.getElementById('searchInputAboveTable3');
    let inputSearch4:any = document.getElementById('searchInputAboveTable4');

    inputSearch.value = '';
    inputSearch1.value='';
    inputSearch2.value='';
    inputSearch3.value='';
    inputSearch4.value='';
    this.setState({
      page:1,
      invoices:[],
      data : [],
      pageSize:20,
      totalPages:40,
      fetchedPages:[],
      visible:false,
      oldStatus:'',
      newStatus:'',
      invoiceNumber:'',
      invoiceStatuses:[],
      tableDataLoading:false,
      submenuSelectedFromProps:"all",
      searchQuery:"",
      selectedFilter:""
    },()=>{
      this.setState({
        tableDataLoading:true
      })
      this.getAllCreditBills();
    })
  }

   epochToJsDate=(ts)=>{
    // ts = epoch timestamp
    // returns date obj
    
    let date = new Date(ts*1000);
    console.log(date)
    return (<span>{date}</span>);
}

  public render() {
    // const invoiceList = this.state.invoices.map((invoice,key)=>{
    //   return(
    //     <>
    //         <Tr className="rowItemBillInvoiceMapping">
    //           <Td>{invoice.invoiceDate}</Td>
    //           <Td>{invoice.invoiceNumber}</Td>
    //           <Td>{invoice.loanIdentifier}</Td>
    //           <Td>{invoice.nbfcPartnerName}</Td>
    //           <Td>{invoice.billNumber}</Td>
    //           <Td>{invoice.retailerCode}</Td>
    //           <Td>{invoice.retailerName}</Td>
    //           <Td>{invoice.billStatus}</Td>
    //           <Td>{invoice.invoiceStatus}</Td>
    //           <Dropdown clearable options={options} selection />

    
    //         </Tr>
    //     </>
    //   );
    // })
    return (
      <div>

       
        {/* <Steps current={1}>
          <Step title="Finished" description="This is a description." />
          <Step title="In Progress" subTitle="Left 00:00:08" description="This is a description." />
          <Step title="Waiting" description="This is a description." />
        </Steps> */}
        <div style={styles.customTable1}>
            <Modal
              title="Confirm"
              visible={this.state.visible}
              onOk={this.hideConfirmModal}
              onCancel={this.hideModal}
              okText="Confirm"
              cancelText="Cancel"
            >
              <div style={{marginBottom:"20px"}}>
                <span >Are you sure you want to change buyer invoice status?</span>
              </div>
              <div style={{display:"inline-flex"}}>
              <div style={{marginLeft:"10px"}}>
                {(this.state.oldStatus=="PAYMENT_RECEIVED" || this.state.oldStatus=="PARTIAL_PAYMENT_RECEIVED") && <Tag color="success">{this.state.oldStatus}</Tag>}
                {this.state.oldStatus=="DELIVERED" && <Tag color="processing">{this.state.oldStatus}</Tag>}
                {(this.state.oldStatus!="PAYMENT_RECEIVED" && this.state.oldStatus!="PARTIAL_PAYMENT_RECEIVED" && this.state.oldStatus!="DELIVERED") && <Tag color="warning">{this.state.oldStatus}</Tag>}
                </div>
              
                <p>-{'>'}</p>
                <div style={{marginLeft:"10px"}}>
                  {(this.state.newStatus=="PAYMENT_RECEIVED" || this.state.newStatus=="PARTIAL_PAYMENT_RECEIVED") && <Tag color="success">{this.state.newStatus}</Tag>}
                  {this.state.newStatus=="DELIVERED" && <Tag color="processing">{this.state.newStatus}</Tag>}
                  {this.state.newStatus=="CHEQUE_BOUNCED" && <Tag color="error">{this.state.newStatus}</Tag>}
                  {(this.state.newStatus!="PAYMENT_RECEIVED" && this.state.newStatus!="CHEQUE_BOUNCED" && this.state.newStatus!="PARTIAL_PAYMENT_RECEIVED" && this.state.newStatus!="DELIVERED") && <Tag color="warning">{this.state.newStatus}</Tag>}
                  </div>
                
               
              </div>
            </Modal>
            <div style={{display:"inline-flex", width:"100%"}}>
            <Input style={{width:"20%"}}
            id="searchInputAboveTable"
            icon={<Icon name='search' style={{color:"#4b4495"}} circular link onClick={()=>this.getdataUsingSearchQuery('RETAILERCODE')} />}
            placeholder='Search By Retailer Code'
            onChange={(e)=>this.setSearchQuery(e.target.value,'RETAILERCODE')}
          />
          &nbsp; &nbsp;
            <Input style={{width:"20%"}}
            id="searchInputAboveTable1"
            icon={<Icon name='search' style={{color:"#4b4495"}} circular link onClick={()=>this.getdataUsingSearchQuery('INVOICENUMBER')} />}
            placeholder='Search By Inv. Number'
            onChange={(e)=>this.setSearchQuery(e.target.value,'INVOICENUMBER')}
          />
           &nbsp; &nbsp;
            <Input style={{width:"20%"}}
            id="searchInputAboveTable2"
            icon={<Icon name='search' style={{color:"#4b4495"}} circular link onClick={()=>this.getdataUsingSearchQuery('CHEQUENUMBER')} />}
            placeholder='Search By Cheque Number'
            onChange={(e)=>this.setSearchQuery(e.target.value,'CHEQUENUMBER')}
          />
           &nbsp; &nbsp;
            <Input style={{width:"20%"}}
            id="searchInputAboveTable3"
            icon={<Icon name='search' style={{color:"#4b4495"}} circular link onClick={()=>this.getdataUsingSearchQuery('BILLNUMBER')} />}
            placeholder='Search By Bill Number'
            onChange={(e)=>this.setSearchQuery(e.target.value,'BILLNUMBER')}
          />

&nbsp; &nbsp;
            <Input style={{width:"20%"}}
            type="date"
            id="searchInputAboveTable4"
            placeholder='Search By Action Date'
            onChange={(e)=>this.setSearchQuery(e.target.value,'ACTIONDATE')}
          />
            <Button onClick={()=>this.clearAllFilters()} style={{marginLeft:"15px",color:"#4b4495", fontWeight:"normal"}} danger type="text">
              Clear filters
            </Button>
            </div>
            
            <Table
              columns={this.columns}
              dataSource={this.state.data}
              size="small"
              loading={this.state.tableDataLoading}
              scroll={{ x: 'calc(700px + 50%)', y: 440 }}
              pagination={{ onChange:(pageNumber,pageSize)=>{
                console.log(pageNumber,pageSize);
                this.setState({
                  tableDataLoading:true
                },()=>this.changeCreditBillsInvoicePageNumber(pageNumber,this.state.pageSize))
                
              },pageSize:this.state.pageSize,total:this.state.totalPages,showSizeChanger:false,current:this.state.page}}
            />
          </div>
      </div>
    );
  }
}
const styles = {
  customTable1: {
    marginRight: '2%'
  },
}


