import { Component, OnInit, Inject } from "@angular/core";
import { DialogModelLogisticsCoinsEdit } from "./model";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PopupService } from "../../../core/services/popup/popup.service";
import { LogisticsService } from "src/app/core/services/api/logistics.service";

@Component({
  selector: "app-logistics-coins-edit",
  templateUrl: "./logistics-coins-edit.component.html",
  styleUrls: ["./logistics-coins-edit.component.scss"],
})
export class LogisticsCoinsEditComponent implements OnInit {
  bundleDetails = {
    totalCurrentCummulativeAmount: 0,
    totalCummulativeAmount: 0,
    currentTotalNotes: 0,
    currentCashTotalAmount: 0,
    currentTotalCoins: 0,
    currentCoinsTotalAmount: 0,
    coinBundleAdded: false,
    coinBundle: {
      totalCoin10: 0,
      totalCoin5: 0,
      totalCoin2: 0,
      totalCoin1: 0,
      totalCoins: 0,
      totalCoinAmount: 0,
      bundleUUID: "",
      bundleName: "",
      date: "",
      time: "",
    },
    cashBundle: {
      bundleUUID: "",
      bundleName: "",
      date: "",
      time: "",
      totalCash2000: 0,
      totalCash500: 0,
      totalCash200: 0,
      totalCash100: 0,
      totalCash50: 0,
      totalCash20: 0,
      totalCash10: 0,
      totalCash5: 0,
      totalCashNotes: 0,
      totalCashAmount: 0,
    },
  };
  sessionDetails = { data: null, totalAmount: 0 };
  constructor(
    public dialogRef: MatDialogRef<LogisticsCoinsEditComponent>,
    private popupservice: PopupService,
    private logisticService: LogisticsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelLogisticsCoinsEdit
  ) {}

  ngOnInit(): void {
    this.data.data.cashBundleDetails &&
      (this.bundleDetails.cashBundle = JSON.parse(
        JSON.stringify(this.data.data.cashBundleDetails)
      ));
    this.data.data.isCoinBundlePresent &&
      (this.bundleDetails.coinBundle = JSON.parse(
        JSON.stringify(this.data.data.coinBundleDetails)
      ));
    this.data.data.isCoinBundlePresent &&
      (this.bundleDetails.coinBundleAdded = true);
    this.bundleDetails.totalCurrentCummulativeAmount =
      this.bundleDetails.cashBundle.totalCashAmount +
      this.bundleDetails.coinBundle.totalCoinAmount;
    this.bundleDetails.totalCummulativeAmount =
      this.bundleDetails.cashBundle.totalCashAmount +
      this.bundleDetails.coinBundle.totalCoinAmount;
    this.bundleDetails.currentTotalNotes =
      this.bundleDetails.cashBundle.totalCashNotes;
    this.bundleDetails.currentCashTotalAmount =
      this.bundleDetails.cashBundle.totalCashAmount;
    this.bundleDetails.currentTotalCoins =
      this.bundleDetails.coinBundle.totalCoins;
    this.bundleDetails.currentCoinsTotalAmount =
      this.bundleDetails.coinBundle.totalCoinAmount;

    this.sessionDetails.data = this.data.data?.sessionDenominationDetailsList;
    this.sessionDetails.data.forEach((data) => {
      this.sessionDetails.totalAmount += data.executiveCollectedAmount;
    });
  }

  editValue(type) {
    if (type === "cash") {
      let totalCashAmount = 0;
      let totalNotes = 0;
      Object.keys(this.bundleDetails.cashBundle).forEach((key) => {
        if (
          key.includes("totalCash") &&
          key !== "totalCashNotes" &&
          key !== "totalCashAmount"
        ) {
          totalCashAmount =
            totalCashAmount +
            +key.split("totalCash")[1] * +this.bundleDetails.cashBundle[key];
          totalNotes = totalNotes + +this.bundleDetails.cashBundle[key];
        }
      });
      this.bundleDetails.currentTotalNotes = totalNotes;
      this.bundleDetails.currentCashTotalAmount = totalCashAmount;
    } else if (type === "coin") {
      let totalCoinsAmount = 0;
      let totalCoins = 0;
      Object.keys(this.bundleDetails.coinBundle).forEach((key) => {
        if (
          key.includes("totalCoin") &&
          key !== "totalCoins" &&
          key !== "totalCoinAmount"
        ) {
          totalCoinsAmount =
            totalCoinsAmount +
            +key.split("totalCoin")[1] * +this.bundleDetails.coinBundle[key];
          totalCoins = totalCoins + +this.bundleDetails.coinBundle[key];
        }
      });
      this.bundleDetails.currentTotalCoins = totalCoins;
      this.bundleDetails.currentCoinsTotalAmount = totalCoinsAmount;
    }

    this.bundleDetails.totalCurrentCummulativeAmount =
      this.bundleDetails.currentCashTotalAmount +
      this.bundleDetails.currentCoinsTotalAmount;
  }

  editConfirm() {
    this.popupservice.openProgress()
    this.denominationsCheck()
      .then((res) => {
        if (res["error"]) {
          this.popupservice.closeProgress();
          this.popupservice.openError(res["message"]);
        } else {
          if (
            this.bundleDetails.totalCummulativeAmount !==
            this.bundleDetails.totalCurrentCummulativeAmount
          ) {
            this.popupservice.closeProgress();
            this.popupservice.openError(
              "Edited total bundle cummulative amount should be equal to total submitted amount"
            );
          } else if (
            this.sessionDetails.totalAmount !==
            this.bundleDetails.totalCummulativeAmount
          ) {
            this.popupservice.closeProgress();
            this.popupservice.openError(
              "Edited total sessions cummulative amount should be equal to total submitted amount"
            );
          } else if (this.bundleDetails.currentCashTotalAmount <= 0) {
            this.popupservice.closeProgress();
            this.popupservice.openError(
              "Total Cash amount should be greater than zero"
            );
          } else {
            let params = {
              bundleUUID: this.bundleDetails.cashBundle.bundleUUID,
              bundleType: "CashBundleEdit",
            };
            let body = {
              addToCashBundle: false,
              addToCoinBundle:
                this.bundleDetails.coinBundleAdded &&
                !this.data.data.isCoinBundlePresent
                  ? true
                  : false,
              cashBundleEditRequest: {
                totalCash10: this.bundleDetails.cashBundle.totalCash10,
                totalCash100: this.bundleDetails.cashBundle.totalCash100,
                totalCash20: this.bundleDetails.cashBundle.totalCash20,
                totalCash200: this.bundleDetails.cashBundle.totalCash200,
                totalCash2000: this.bundleDetails.cashBundle.totalCash2000,
                totalCash5: this.bundleDetails.cashBundle.totalCash5,
                totalCash50: this.bundleDetails.cashBundle.totalCash50,
                totalCash500: this.bundleDetails.cashBundle.totalCash500,
              },
              coinBundleEditRequest: this.bundleDetails.coinBundleAdded
                ? {
                    totalCoin1: this.bundleDetails.coinBundle.totalCoin1,
                    totalCoin10: this.bundleDetails.coinBundle.totalCoin10,
                    totalCoin2: this.bundleDetails.coinBundle.totalCoin2,
                    totalCoin5: this.bundleDetails.coinBundle.totalCoin5,
                    totalCoins: this.bundleDetails.currentTotalCoins,
                  }
                : null,
              sessionDenominationDetailsList: [],
            };
            this.sessionDetails.data.forEach((data) => {
              if (data?.edited) {
                delete data["edited"];
                body.sessionDenominationDetailsList.push(data);
              }
            });

            this.logisticService.editBundle(body, params).subscribe(
              (res) => {
                this.popupservice.closeProgress();
                this.popupservice.openSuccess(res.data);
                setTimeout(() => {
                  this.dialogRef.close(true);
                }, 300);
              },
              (error) => {
                this.popupservice.closeProgress();
                this.popupservice.openError(
                  error?.error?.error?.error || "Something went wrong"
                );
              }
            );
          }
        }
      })
      .catch((err) => {
        this.popupservice.closeProgress();
        this.popupservice.openError("Something went wrong");
      });
  }

  close() {
    this.dialogRef.close(false);
  }

  editSession(index) {
    let totalAmount = 0;
    this.sessionDetails.data[index].edited = true;
    Object.keys(this.sessionDetails.data[index]).forEach((key) => {
      if (key.includes("cash") || key.includes("coin")) {
        let multiple;
        if (key.includes("cash")) {
          multiple = key.split("cash")[1];
        } else {
          multiple = key.split("coin")[1];
        }
        totalAmount =
          totalAmount + this.sessionDetails.data[index][key] * multiple;
      }
    });
    this.sessionDetails.data[index].executiveCollectedAmount = totalAmount;
    this.sessionDetails.data[index].rtoCashMappingAmount = totalAmount;

    this.sessionDetails.totalAmount = 0;
    this.sessionDetails.data.forEach((data) => {
      this.sessionDetails.totalAmount += data.executiveCollectedAmount;
    });
  }

  denominationsCheck() {
    return new Promise((resolve, reject) => {
      try {
        let denominations = {
          cash: {
            "2000": 0,
            "500": 0,
            "200": 0,
            "100": 0,
            "50": 0,
            "20": 0,
            "10": 0,
            "5": 0,
          },
          coins: {
            "10": 0,
            "5": 0,
            "2": 0,
            "1": 0,
          },
        };
        this.sessionDetails.data.forEach((data) => {
          Object.keys(data).forEach((key) => {
            if (key.includes("cash") || key.includes("coin")) {
              let amount;
              if (key.includes("cash")) {
                amount = key.split("cash")[1];
                denominations.cash[amount] += data[key];
              } else {
                amount = key.split("coin")[1];
                denominations.coins[amount] += data[key];
              }
            }
          });
        });

        Object.keys(denominations.cash).forEach((sessionKey) => {
          if (
            this.bundleDetails.cashBundle["totalCash" + sessionKey] !==
            denominations.cash[sessionKey]
          ) {
            resolve({
              error: true,
              message: `Mismatch in count of ${sessionKey} Cash denominations between bundle and sessions`,
            });
          }
        });
        Object.keys(denominations.coins).forEach((sessionKey) => {
          if (
            this.bundleDetails.coinBundle["totalCoin" + sessionKey] !==
            denominations.coins[sessionKey]
          ) {
            resolve({
              error: true,
              message: `Mismatch in count of ${sessionKey} Coin denominations between bundle and sessions`,
            });
          }
        });

        resolve({
          error: false,
          message: "",
        });
      } catch (err) {
        reject({
          error: true,
          message: "Something went wrong",
        });
      }
    });
  }
}
