import { AuthGuardService } from "src/app/core/auth/auth.guard";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { DashboardContainerComponent } from "./container/dashboard-container/dashboard-container.component";
import { RegistrationComponent } from "./components/registration/registration.component";
import { AdminComponent } from "./components/admin/admin.component";
import { DistributorComponent } from "./components/distributor/distributor.component";
import { RetailerContainerComponent } from "./container/retailer-container/retailer-container.component";
import { DispatchManagerComponent } from "./components/dispatch-manager/dispatch-manager.component";
import { DeliveryExecutiveComponent } from "./components/delivery-executive/delivery-executive.component";
import { AnalyticsComponent } from "./components/analytics/analytics.component";
// import { LogisticsComponent } from "./components/logistics/logistics.component";
import { SupplyComponent } from "./components/supply/supply.component";
import { OutstandingComponent } from "./components/outstanding/outstanding.component";
import { HelpDeskComponent } from "./components/help-desk/help-desk.component";
import { CreditDashboardComponent } from "./components/credit/credit-dashboard/credit-dashboard.component";
import { WarehouseComponent } from "src/app/components/warehouse/warehouse.component";
import { FinanceComponent } from "./components/finance/finance.component";
import { DemandComponent } from "./components/demand/demand.component";
import { LogisticsRevampComponent } from "./components/logistics-revamp/logistics-revamp.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "check",
    pathMatch: "full",
  },
  {
    path: "retailer",
    component: RetailerContainerComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./components/retailer/retailer.module").then(
            (m) => m.RetailerModule
          ),
      },
    ],
  },
  {
    path: "admin",
    component: AdminComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/admin/admin-container/admin-container.module"
          ).then((m) => m.AdminContainerModule),
      },
    ],
  },
  {
    path: "finance",
    component: FinanceComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/finance/finance-container/finance-container.module"
          ).then((m) => m.FinanceContainerModule),
      },
    ],
  },
  {
    path: "help-desk",
    component: HelpDeskComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/help-desk/help-desk-container/help-desk-container.module"
          ).then((m) => m.HelpDeskContainerModule),
      },
    ],
  },
  {
    path: "outstanding",
    component: OutstandingComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/outstanding/outstanding-container/outstanding-container.module"
          ).then((m) => m.OutstandingContainerModule),
      },
    ],
  },
  {
    path: "warehouse",
    component: WarehouseComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/warehouse/warehouse-container/warehouse-container.module"
          ).then((m) => m.WarehouseContainerModule),
      },
    ],
  },
  {
    path: "delivery-exec",
    component: DeliveryExecutiveComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/delivery-executive/delivery-executive-container/delivery-executive-container.module"
          ).then((m) => m.DeliveryExecutiveContainerModule),
      },
    ],
  },
  {
    path: "dispatch-manager",
    component: DispatchManagerComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/dispatch-manager/dispatch-manager-container/dispatch-manager-container.module"
          ).then((m) => m.DispatchManagerContainerModule),
      },
    ],
  },
  {
    path: "dist",
    component: DistributorComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/distributor/distributor-container/distributor-container.module"
          ).then((m) => m.DistributorContainerModule),
      },
    ],
  },
  {
    path: "analysis",
    component: AnalyticsComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/analytics/analytics-container/analytics-container.module"
          ).then((m) => m.AnalyticsContainerModule),
      },
    ],
  },
  // {
  //   path: "logistics",
  //   component: LogisticsComponent,
  //   canActivate: [AuthGuardService],
  //   children: [
  //     {
  //       path: "",
  //       loadChildren: () =>
  //         import(
  //           "./components/logistics/logistics-container/logistics-container.module"
  //         ).then((m) => m.LogisticsContainerModule),
  //     },
  //   ],
  // },

  {
    path: "logistics",
    component: LogisticsRevampComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/logistics-revamp/logistics-revamp-container/logistics-revamp-container.module"
          ).then((m) => m.LogisticsRevampContainerModule),
      },
    ],
  },
  {
    path: "supply",
    component: SupplyComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/supply/supply-container/supply-container.module"
          ).then((m) => m.SupplyContainerModule),
      },
    ],
  },
  {
    path: "demand",
    component: DemandComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./components/demand/demand-container/demand-container.module"
          ).then((m) => m.DemandContainerModule),
      },
    ],
  },
  // {
  //   path: "credit",
  //   component: CreditDashboardComponent,
  //   canActivate: [AuthGuardService],
  //   // children: [
  //   //   {
  //   //     path: "",
  //   //     loadChildren: () =>
  //   //       import(
  //   //         "./components/supply/supply-container/supply-container.module"
  //   //       ).then(m => m.SupplyContainerModule)
  //   //   }
  //   // ]
  // },
  {
    path: "check",
    canActivate: [AuthGuardService],
    children: [],
  },
  {
    path: "authenticate",
    component: RegistrationComponent,

    children: [
      {
        path: "",
        loadChildren: () =>
          import("./components/registration/components/auth/auth.module").then(
            (m) => m.AuthModule
          ),
      },
      {
        path: "profile-setup",
        loadChildren: () =>
          import(
            "./components/registration/components/profile-setup/profile-setup.module"
          ).then((m) => m.ProfileSetupModule),
      },
      {
        path: "complete",
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import(
            "./components/registration/components/registration-complete/registration-complete.module"
          ).then((m) => m.RegistrationCompleteModule),
      },
      {
        path: "block",
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import(
            "./components/registration/components/block/block.module"
          ).then((m) => m.BlockModule),
      },
    ],
  },
  {
    path: "shop",
    component: DashboardContainerComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./components/customer/home/home.module").then(
            (m) => m.HomeModule
          ),
      },
      
    ],
  },
  {
    path: "**",
    redirectTo: "admin",
  },

];

const rootRouting: ModuleWithProviders = RouterModule.forRoot(
  routes,

  {
    preloadingStrategy: PreloadAllModules,
    useHash: false,
    enableTracing: false,
    scrollPositionRestoration: "top",
    onSameUrlNavigation: "reload",
  }
);

@NgModule({
  imports: [rootRouting],
  exports: [RouterModule],
})
export class AppRoutingModule {}
