import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModelConfirm } from './model';
import { LogisticsService } from 'src/app/core/services/api/logistics.service';
import { PopupService } from 'src/app/core/services/popup/popup.service';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {
  adjustInvNoList=null
  adjustInvNoDefaultList=null
  adjustInvNo=null;
  objectKeys=Object.keys
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
    private logisticsService:LogisticsService,
    private popupService:PopupService,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelConfirm
  ) {}

  ngOnInit(): void {
    if(this.data.invoiceNumber['checkBalanceAmount']===false && !this.data.invoiceNumber['credit']){
        this.getAdjustInvNumbers()
    }
  }

  adjustInCurrentBill() {
    this.dialogRef.close({currentBill:true});
  }

  adjustInNextBill() {
    this.dialogRef.close({nextBill:true,adjustInvNo:this.adjustInvNo});
  }

  close() {
    this.dialogRef.close(null);
  }

  getAdjustInvNumbers(){
     const params = {
      invoiceNumber: this.data.invoiceNumber['invoiceNumber'],
      deMobileNumber: ""
    };
    this.logisticsService.getAdjustInvoiceNumbers(params).subscribe(
      response => {
        this.adjustInvNoList=null;
        this.adjustInvNoDefaultList=null;
        this.adjustInvNoList = response.data;
        this.adjustInvNoDefaultList=JSON.parse(JSON.stringify(response.data))
        if (this.adjustInvNoList) {
          this.adjustInvNo = Object.keys(this.adjustInvNoList)[0]
        } else {
          this.adjustInvNo = null;
        }
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error?.error?.error?.error || 'Something went wrong');
      }
    )
  }


  searchInv(event){
        let val=event.target.value
        let obj={}
        val.length && Object.keys(this.adjustInvNoDefaultList).forEach((key)=>{
              if(key.includes(val)){
                    obj[key]=this.adjustInvNoDefaultList[key]
              }
         })
         val.length ? this.adjustInvNoList=obj:this.adjustInvNoList=JSON.parse(JSON.stringify(this.adjustInvNoDefaultList))
  }

  optionSelected(event){
    this.adjustInvNo=event.option.value
  }
}
