import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from "src/app/material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatIconModule, MatRippleModule } from "@angular/material";
import { CommonTableHeaderComponent } from './common-table-header.component';
import { CommonFilterIconsModule } from '../common-filter-icons/common-filter-icons.module';
import { CommonFiltersModule } from '../common-filters/common-filters.module';

@NgModule({
  declarations: [CommonTableHeaderComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    MatIconModule,
    MatRippleModule,
    CommonFilterIconsModule,
    CommonFiltersModule
  ],
  exports: [CommonTableHeaderComponent]
})
export class CommonTableHeaderModule { }
