export const environment = {
  version: 7,
   production: true,
   helpDeskUrl: "https://gatewayprod.clinn.in/api",
   BASE_URL: "https://gatewayprod.clinn.in/api",
   adminUrl: "https://gatewayprod.clinn.in/api",
   adminUrl3:"https://gatewayprod.clinn.in/api",
   hubUrl: "https://gatewayprod.clinn.in/api",
   eagleUrl : 'https://eagle.clinn.in/api',
   billUrl:"https://bill.clinn.in/healthcheck",
   bannerUrl: "https://gatewayprod.clinn.in/api",
   docraptor:{key:"dc8n3N38toneWIGm0qsr",test:false},
   firebase: {
     apiKey: "AIzaSyD3rXgiBEoEMoD2RYitgyrqYCutw4-y97c",
     authDomain: "saveo-ordering-app.firebaseapp.com",
     databaseURL: "https://saveo-ordering-app.firebaseio.com",
     projectId: "saveo-ordering-app",
     storageBucket: "",
     messagingSenderId: "662126699710",
     appId: "1:662126699710:web:29d2e4642721098c"
   }
 };
