import { Injectable, Inject } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EventService } from '../event/event.service';

@Injectable({
    providedIn: 'root'
})
export class RoutingStateService {

    private history = [];

    constructor(
        private router: Router,
        private eventService:EventService
    ) {
        
    }

    public loadRouting(): void {
        this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(({urlAfterRedirects}: NavigationEnd) => {
            this.history = [...this.history, urlAfterRedirects];
            let historyData=[...this.history]
            let host=window.location.href.split('/')
                host.pop();

            let pageOpen=historyData.pop()
                pageOpen=pageOpen.split('/').pop()
            let pageClose=null;
           
             if(historyData.length>1){
                pageClose=this.history[this.history.length-2]
                pageClose=pageClose.split('/').pop()
             }

            // new route open event hit  
            this.eventService.trackEvent(`screen-open`,{url:host.join("/")+`/${pageOpen}`})
            if(pageClose){
            // previous route close event hit 
                this.eventService.trackEvent(`screen-close`,{url:host.join("/")+`/${pageClose}`})
            }

        });
    }

    public getHistory(): string[] {
        return this.history;
    }

    public getPreviousUrl(): string {
        return this.history[this.history.length - 2] || '';
    }

}
