import { ApiDataService } from "src/app/core/services/api/api.data.service";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { MedicinesResponsesList } from "src/app/shared/common-item-card/model";
import { shareReplay } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class SearchService {
  private loadMoreSubject = new BehaviorSubject<boolean>(false);
  loadMore$: Observable<any> = this.loadMoreSubject.asObservable();

  private productSearchSubject = new BehaviorSubject<any>([]);
  productSearch$: Observable<any> = this.productSearchSubject.asObservable();

  private companyNamesSubject = new BehaviorSubject<any>([]);
  companyName$: Observable<any> = this.companyNamesSubject.asObservable();

  private companySearchSubject = new BehaviorSubject<any>([]);
  companySearch$: Observable<any> = this.companySearchSubject.asObservable();

  private regularSchemeSearchSubject = new BehaviorSubject<any>([]);
  regularSchemeSearch$: Observable<
    any
  > = this.regularSchemeSearchSubject.asObservable();

  private specialSchemeSearchSubject = new BehaviorSubject<any>([]);
  specialSchemeSearch$: Observable<
    any
  > = this.specialSchemeSearchSubject.asObservable();

  constructor(private apiDataService: ApiDataService) {}

  // -------search by  product Name function

  initProductSearch(query, pageno) {
    this.apiDataService
      .getSearchByProduct(query, { page: pageno })
      .subscribe(data => {
        this.productSearchSubject.next(data.medicinesResponsesList);
        if (data.size === 0 || data.size < 20) {
          this.loadMoreSubject.next(false);
        } else {
          this.loadMoreSubject.next(true);
        }
      });
  }

  loadMoreProductSearch(query, pageno) {
    this.apiDataService
      .getSearchByProduct(query, { page: pageno })
      .subscribe(data => {
        this.productSearchSubject.next(
          this.productSearchSubject
            .getValue()
            .concat(data.medicinesResponsesList)
        );
        if (data.size === 0 || data.size < 20) {
          this.loadMoreSubject.next(false);
        } else {
          this.loadMoreSubject.next(true);
        }
      });
  }

  // -------search by charatcer for company Names

  initCompanyNames(query) {
    this.apiDataService
      .fetchCompanyNames({ manufacturerName: query })
      .subscribe(data => {
        this.companyNamesSubject.next(data);
      });
  }

  loadMoreCompanyNames(query) {
    this.apiDataService
      .fetchCompanyNames({ manufacturerName: query })
      .subscribe(data => {
        this.companyNamesSubject.next(
          this.companyNamesSubject.getValue().concat(data)
        );
      });
  }

  // -------search by company full Name

  initCompanySearch(query, pageno) {
    this.apiDataService
      .getSearchByCompany({ manufacturerName: query, page: pageno })
      .subscribe(data => {
        this.companySearchSubject.next(data.medicinesResponsesList);
        if (data.size === 0 || data.size < 20) {
          this.loadMoreSubject.next(false);
        } else {
          this.loadMoreSubject.next(true);
        }
      });
  }

  loadMoreCompanySearch(query, pageno) {
    this.apiDataService
      .getSearchByCompany({ manufacturerName: query, page: pageno })
      .subscribe(data => {
        this.companySearchSubject.next(
          this.companySearchSubject
            .getValue()
            .concat(data.medicinesResponsesList)
        );
        // console.log("size----------",data.size);
        if (data.size === 0 || data.size < 20) {
          // console.log("hide load button");
          this.loadMoreSubject.next(false);
        } else {
          this.loadMoreSubject.next(true);
        }
      });
  }

  // -------regular Scheme search function

  initRegularSchemeSearch(query, pageno) {
    this.apiDataService
      .SearchRegularScheme({ medicineName: query, page: pageno })
      .subscribe(data => {
        this.regularSchemeSearchSubject.next(data.medicinesResponsesList);
        if (data.size === 0 || data.size < 20) {
          //  console.log("hide load button");
          this.loadMoreSubject.next(false);
        } else {
          this.loadMoreSubject.next(true);
        }
      });
  }

  loadMoreRegularSchemeSearch(query, pageno) {
    this.apiDataService
      .SearchRegularScheme({ medicineName: query, page: pageno })
      .subscribe(data => {
        this.regularSchemeSearchSubject.next(
          this.regularSchemeSearchSubject
            .getValue()
            .concat(data.medicinesResponsesList)
        );
        if (data.size === 0 || data.size < 20) {
          // console.log("hide load button");
          this.loadMoreSubject.next(false);
        } else {
          this.loadMoreSubject.next(true);
        }
      });
  }

  // -------special Scheme search function

  initSpecialSchemeSearch(query, pageno) {
    this.apiDataService
      .SearchSpecialScheme({ medicineName: query, page: pageno })
      .subscribe(data => {
        this.specialSchemeSearchSubject.next(data.medicinesResponsesList);
        if (data.size === 0 || data.size < 20) {
          //  console.log("hide load button");
          this.loadMoreSubject.next(false);
        } else {
          this.loadMoreSubject.next(true);
        }
      });
  }

  loadMoreSpecialSchemeSearch(query, pageno) {
    this.apiDataService
      .SearchSpecialScheme({ medicineName: query, page: pageno })
      .subscribe(data => {
        this.specialSchemeSearchSubject.next(
          this.specialSchemeSearchSubject
            .getValue()
            .concat(data.medicinesResponsesList)
        );
        if (data.size === 0 || data.size < 20) {
          //  console.log("hide load button");
          this.loadMoreSubject.next(false);
        } else {
          this.loadMoreSubject.next(true);
        }
      });
  }
}
