import React, { useState } from "react";
import { Input, Icon, Button } from "semantic-ui-react";
import { Dimmer, Loader, Segment } from 'semantic-ui-react'
import axios from "axios";
import "../CreditReactComponent.scss";
import { message, Modal, Popover, Table } from 'antd';
export default function CreditUserDetails(props) {
  const [searchQuery, setSearchQuery] = useState("");
  const [errorData, setErrorData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [creditUserDetails, setCreditUserDetails] = useState(undefined);
  const getdataUsingSearchQuery = () => {
    setErrorData(undefined);
    setLoading(true);
    setCreditUserDetails(undefined);
    console.log("====================================");
    axios
      .get(
        "https://gatewayprod.saveo.in/api/credit/user/getCreditStatus?retailerId=" +
          searchQuery,
        {
          headers: {
            Authorization: props.token,
          },
        }
      )
      .then((d) => {
        setCreditUserDetails(d.data.data.creditUserDetailsModel);
        if(d.data.data.error.length > 0){
            console.log("Error",d.data.data.error[0]);
            setErrorData(d.data.data.error[0]);
            axios
            .get(
                "https://gatewayprod.saveo.in/api/credit/admin/getPlainCreditStatus?retailerId=" +
                searchQuery,
                {
                headers: {
                    Authorization: props.token,
                },
                }
            )
            .then((d) => {
                setCreditUserDetails(d.data.data.creditUserDetailsModel);
                setLoading(false);
            }).catch(e=>{
                setLoading(false);
            })
        }else{
            setLoading(false);
        }
      });
    console.log("====================================");
  };

  const epochToDate = (e) => {
    let d = new Date(e);
    return d
      .toString()
      .replace("(India Standard Time)", "")
      .replace(" GMT+0530", "");
  };

  const roundTo2Decimal = (e)=>{
      return Math.round((e + Number.EPSILON) * 100) / 100;
  }
  const triggerCreditUserDetailsView=()=>{
      axios.get("https://gatewayprod.saveo.in/api/credit/admin/triggerCreditUserdetailsCronManuallyV2",{
        headers: {
            Authorization: props.token,
        },
        }).then(d=>{
        message.success("Please check credit user details after sometime.", 2.5);
      })
      .catch((e)=>{
        message.error("Some error has occured.", 2.5);
      })
  }
  return (
    <div>
      <div style={{ display: "inline-flex", width: "100%" }}>
        <Input
          style={{ width: "35%" }}
          id="searchInputAboveTable"
          icon={
            <Icon
              name="search"
              style={{ color: "#4b4495" }}
              circular
              link
              onClick={() => getdataUsingSearchQuery()}
            />
          }
          placeholder="Search Retailer Code"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div style={{"marginLeft": "auto", 
"marginRight": "20px"}}>
            <Button positive onClick={()=>triggerCreditUserDetailsView()}>Trigger Credit User Details View</Button>
        </div>
      </div>
      <div>
        {creditUserDetails && (
          <div>
            <div className="creditUserDetailsContainer container">
              <div className="row">
                <div className="col-sm">
                  <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Name
                  </span>
                  <div>{creditUserDetails.retailerName}</div>
                </div>
                <div className="col-sm">
                  <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Retailer Code
                  </span>
                  <div>{creditUserDetails.retailerCode}</div>
                </div>
                <div className="col-sm">
                  <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Activated On
                  </span>
                  <div>{epochToDate(creditUserDetails.updatedTimestamp)}</div>
                </div>
              </div>
              <div style={{ marginTop: "5px" }} className="row">
                <div className="col-sm">
                  <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Sanctioned Limit
                  </span>
                  <div>{creditUserDetails.sanctionedLimit}</div>
                </div>
                <div className="col-sm">
                  <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Available Limit
                  </span>
                  {creditUserDetails.availableLimit <= 0 && (
                    <div>
                      <span style={{ color: "red",fontWeight:"bold" }}>
                        {roundTo2Decimal(creditUserDetails.availableLimit)}
                      </span>
                    </div>
                  )}
                  {creditUserDetails.availableLimit > 0 && (
                    <div>
                      <span style={{ color: "green", fontWeight:"bold" }}>
                        {roundTo2Decimal(creditUserDetails.availableLimit)}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-sm">
                  <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Used Limit
                  </span>
                  <div>
                    {creditUserDetails.usedLimit >=
                      creditUserDetails.sanctionedLimit && (
                      <span style={{ color: "red",fontWeight:"bold" }}>
                        {roundTo2Decimal(creditUserDetails.usedLimit)}
                      </span>
                    )}
                    {creditUserDetails.usedLimit <
                      creditUserDetails.sanctionedLimit && (
                      <span style={{ color: "green",fontWeight:"bold" }}>
                        {roundTo2Decimal(creditUserDetails.usedLimit)}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "5px" }} className="row">
                <div className="col-sm">
                  <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Status
                  </span>
                  <div>
                    {(errorData==undefined && creditUserDetails.isAvailable == "yes") ? (
                      <div>
                        <Icon
                          name="dot circle"
                          style={{
                            color: "green",
                            backgroundColor: "white",
                            fontSize: "1em",
                          }}
                        >
                          {" "}
                        </Icon>{" "}
                        Active
                      </div>
                    ) : (
                      <div>
                        <Icon
                          name="dot circle"
                          style={{
                            color: "red",
                            backgroundColor: "white",
                            fontSize: "1em",
                          }}
                        >
                          {" "}
                        </Icon>{" "}
                        Blocked
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="col-sm">
                  <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Available Limit
                  </span>
                  {creditUserDetails.availableLimit <= 0 && (
                    <div>
                      <span style={{ color: "red" }}>
                        {roundTo2Decimal(creditUserDetails.availableLimit)}
                      </span>
                    </div>
                  )}
                  {creditUserDetails.availableLimit > 0 && (
                    <div>
                      <span style={{ color: "green" }}>
                        {roundTo2Decimal(creditUserDetails.availableLimit)}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-sm">
                  <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                    Used Limit
                  </span>
                  <div>
                    {creditUserDetails.usedLimit >=
                      creditUserDetails.sanctionedLimit && (
                      <span style={{ color: "red" }}>
                        {roundTo2Decimal(creditUserDetails.usedLimit)}
                      </span>
                    )}
                    {creditUserDetails.usedLimit <
                      creditUserDetails.sanctionedLimit && (
                      <span style={{ color: "green" }}>
                        {roundTo2Decimal(creditUserDetails.usedLimit)}
                      </span>
                    )}
                  </div> */}
                {/* </div> */}
              </div>
              {errorData && <div className="creditUserDetailsContainer container">
                    <div className="row">
                        <div className="col-sm">
                            <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                                Message
                            </span>
                            <div>
                                <span>
                                    {errorData.message}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>}
                
            </div>
          </div>
        )}
        {loading && <Segment style={{marginTop:"20px",height:"40vh", width:"80vw"}}>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        </Segment>}
      </div>
    </div>
  );
}
