import { AuthService } from "src/app/core/services/api/api-auth.service";
import { CartStoreService } from "src/app/core/store/cart-store.service";
import { ApiDataService } from "./../../core/services/api/api.data.service";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy
} from "@angular/core";
import {
  MatSidenav,
  MatSidenavContainer,
  MatBottomSheet
} from "@angular/material";
import { Subscription, Subject } from "rxjs";
import { Location } from "@angular/common";
import { Router, NavigationEnd, RouterEvent } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { SideNavEventHandlerService } from "src/app/common/event/sidenav-event-handler.service";
import { ScrollEventService } from "src/app/common/event/scroll-event.service";
import { UserModel } from "src/app/core/services/params/user.model";
import { StoreService } from "src/app/core/store/data-store.service";
import { map, filter, pairwise, startWith, takeUntil } from "rxjs/operators";
import { SearchService } from "src/app/core/store/search-store.service";
import { CartItemResponseModel } from "src/app/core/services/params/cart.model";
import { AuthStoreService } from "src/app/core/store/auth-store.service";
import { AppDataService } from "src/app/app-data/app-data.service";
import { EventService } from "src/app/core/services/event/event.service";

@Component({
  selector: "app-dashboard-container",
  templateUrl: "./dashboard-container.component.html",
  styleUrls: ["./dashboard-container.component.scss"]
})
export class DashboardContainerComponent implements OnInit, OnDestroy {
  supportDetails = {
    email: "support@saveo.in",
    contactNumberInStr: "1800 889 1214",
    whatsAppNumber: "+91 84314 42624",
    contactNumber: 18008891214,
    mail: "mailto:support@saveo.in",
    call: "tel:18008891214"
  }
  _mNavMode = "side";
  _mNavOpen = false;
  _mShowMenu = false;
  isAuthenticated = false;
  panelOpenState = false;
  isExpanded = false;
  isCompany = false;
  _mShowCatalogHeader = false;

  subscriptions: Subscription[] = [];
  mCurrentHeading = 1;
  _loginStatus: UserModel;
  isAdmin = false;
  isDistributor = false;
  _mSearchStatus: string;

  // ----search variables
  _showCompanyNames = false;
  _mSearchType: string;
  _IsSearchActive = false;
  _mSearchString: string;
  searchResult$: any[];
  _mShowLoadButton: boolean;
  query;
  page = 0;
  companyNamesResult$: any[];

  _slideMode = "over";
  _drawerOpened = false;

  _CartItems$: CartItemResponseModel;
  public destroyed = new Subject<any>();

  @ViewChild("fixedContent", { static: true }) public fixedContent: ElementRef;

  @ViewChild("mainDrawer", { static: true }) sidenav: MatSidenav;

  @ViewChild("sideNavContainer", { static: true })
  myScrollContainer: MatSidenavContainer;

  @ViewChild("contentScroller", { static: true }) contentScroller: ElementRef;

  @Output() public sidenavToggle = new EventEmitter();
  @Output() sidenavClose = new EventEmitter();
  @Output() public searchActive: EventEmitter<boolean> = new EventEmitter();

  ngOnInit() {
    this.getSupportDetails();
    this.getActiveSections();
    this._mSearchType = "products";
    this.initateStoreSubjects();
    this.adminUserCheck();
    this.distributorUserCheck();
    this.onSameRouteNavigation();

    this.subscriptions[
      this.subscriptions.length
    ] = this.scrollEventService
      .getActionMainEmitter()
      .subscribe((heading: number) => {
        this.mCurrentHeading = heading as number;
        this.scrollToHeader(heading);
      });
  }
  constructor(
    private location: Location,
    private router: Router,
    private scrollEventService: ScrollEventService,
    private media: MediaObserver,
    private sideNavEventHandlerService: SideNavEventHandlerService,
    private authService: AuthService,
    private storeService: StoreService,
    private searchService: SearchService,
    private cartStoreService: CartStoreService,
    private appDataService: AppDataService,
    private apiDataService: ApiDataService,
    private eventService: EventService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.media.media$.subscribe((mediaChange: MediaChange) => {
      this._mNavMode = this.getMode(mediaChange);
      this.updateSlideMode(mediaChange);
      this.router.events.subscribe(val => {
        if (this.location.path() !== "") {
          const routePath = this.location.path();
          this.clearSearchValues();
          this._mSearchStatus = routePath;
          this.onChangeToCatalog(routePath);
          return;
        } else {
        }
      });
    });
  }

  getSupportDetails() {
    this.apiDataService.getSupportDetails().subscribe(
      response => {
        this.supportDetails = response;
        this.supportDetails.mail = "mailto:" + this.supportDetails.email;
        this.supportDetails.call = "tel:" + this.supportDetails.contactNumber;
      }
    )
  }

  /**
   * ------------------------ Nav bar Items   -------------------
   */

  // tslint:disable-next-line: member-ordering
  _mNavItems = [
    { title: "Home", url: "/shop", icon: "home" },
    // { title: "My Cart", url: "/shop/cart", icon: "cart" },
    { title: "Past Order", url: "/shop/past-order", icon: "past-order" },
    { title: "Regular Schemes", url: "/shop/regular", icon: "regular-Schemes" },
    { title: "Special Schemes", url: "/shop/special-schemes", icon: "special-Schemes"},
    { title: "Covid Essentials", url: "/shop/special", icon: "covid-essentials"},
    { title: "Company Section", url: "/shop/company-section", icon: "company-section"},
    { title: "Product Catalogue", url: "/shop/catalogue", icon: "special-Schemes"}
  ];

  resetSections() {
    this._mNavItems = [
      { title: "Home", url: "/shop", icon: "home" },
      // { title: "My Cart", url: "/shop/cart", icon: "cart" }
    ];
  }

  getActiveSections() {
    this.apiDataService.getActiveSections().subscribe(
      response => {
        this.resetSections();
        response.data.map(section => {
          console.log(section)
          if (section.active) {
            const obj = this.getSectionObject(section);
            if (obj.icon.length > 0) {
              this._mNavItems.push(obj);
            }
          }
        })
        console.log(this._mNavItems)
      }
    )
  }

  getSectionObject(sec) {
    console.log(sec.iconName)
    let sectionObj = { title: "", url: "", icon: ""};
    if (sec.iconName == "companySection") {
      sectionObj = { title: "500+ Companies", url: "/shop/company-section", icon: "company-section"}
    } else if (sec.iconName == "specialOffers") {
      sectionObj = { title: "Special Schemes", url: "/shop/special-schemes", icon: "special-Schemes"}
    } else if (sec.iconName == "regularSchemes") {
      sectionObj = { title: "Regular Schemes", url: "/shop/regular", icon: "regular-Schemes" }
    } else if (sec.iconName == "coronaEssentials") {
      sectionObj = { title: "Covid Essentials", url: "/shop/special", icon: "covid-essentials"}
    } else if (sec.iconName == "productCatalogue") {
      sectionObj = { title: "Product Catalogue", url: "/shop/catalogue", icon: "special-Schemes"}
    } else if (sec.iconName == "pastOrders") {
      sectionObj = { title: "Past Order", url: "/shop/past-order", icon: "past-order" }
    } else if (sec.iconName == "surgical") {
      sectionObj = { title: "Surgical", url: "/shop/surgical", icon: "surgicals"}
    } else if (sec.iconName == "generic") {
      sectionObj = { title: "Generic", url: "/shop/generic", icon: "generics"}
    } else if (sec.iconName == "ayurvedic") {
      sectionObj = { title: "Ayurvedic", url: "/shop/ayuervedic", icon: "ayurvedic"}
    } else if (sec.iconName == "allopathy") {
      sectionObj = { title: "Allopathy", url: "/shop/allopathy", icon: "allopathy"}
    } else if (sec.iconName == "exclusive") {
      sectionObj = { title: "Exclusive", url: "/shop/exclusive", icon: "exclusive"}
    }
    sectionObj.title = sec.section_name;
    return sectionObj;
  }

  /**
   * ------------------------ initate store Subjects  functions    -------------------
   */

  initateStoreSubjects() {
    this.cartStoreService.initCartItems();
    this.storeService.initPopularScheme();

    const data = this.cartStoreService.cartItems$;
    data.pipe(map(res => (this._CartItems$ = res))).subscribe();
  }

  adminUserCheck() {
    this.authService.getUserPhoneNumber().subscribe(numbers => {
      this.authService
        .subscribeUserData(numbers)
        .pipe(
          map(response => {
            const currentUser = response.data;
            if (currentUser.user.admin) {
              // console.log("Access granted your are admin user");
              this.isAdmin = true;
              this.isDistributor = true;
            }
          })
        )
        .subscribe();
    });
  }

  distributorUserCheck() {
    this.authService.getUserPhoneNumber().subscribe(numbers => {
      this.authService
        .subscribeUserData(numbers)
        .pipe(
          map(response => {
            const currentUser = response.data;
            if (currentUser.user.distributor) {
              // console.log("Access granted your are admin user");
              this.isDistributor = true;
            }
          })
        )
        .subscribe();
    });
  }

  logout() {
    this.authService.logout().subscribe(
      value => {
        // console.log("logout: ", value);
        this.router.navigate(["/authenticate"]);
        this.appDataService.setData("IsUserExist", "false", "local");
      },
      error => {
        console.log("error", error);
      }
    );
  }

  /**
   * ------------------------ SideNav and Scroll functions   -------------------
   */

  _toggleSideNav(sideNav) {
    this._drawerOpened = !this._drawerOpened;
  }

  _backdropToggle(type) {
    if (type === "backdrop") {
      this._drawerOpened = !this._drawerOpened;
    }
  }

  navigateTo(heading: number) {
    this.scrollEventService.emitHeadingEvent(heading);
  }
  scrollToHeader(heading: number) {
    const headerClass: string = this.getHeaderClass(heading);
  }
  getHeaderClass(heading: number): string {
    return "";
  }

  // ngOnDestroy() {
  //   this.subscriptions.forEach(s => s.unsubscribe());
  // }

  onActivate(e, outlet) {
    this.contentScroller.nativeElement.scrollTop = 0;
  }
  getMode(mediaChange: MediaChange): string {
    if (this.media.isActive("lt-md")) {
      return "";
    } else {
      return "side";
    }
  }

  private updateSlideMode(mediaChange: MediaChange) {
    if (this.media.isActive("lt-md")) {
      this._slideMode = "over";
      this._drawerOpened = false;
    } else {
      this._slideMode = "over";
      this._drawerOpened = false;
    }
  }
  onActionLaunch(event) {
    this._mNavOpen = true;
    const isOpened = this.sidenav.opened;
    this.sidenav.toggle();
    this.sideNavEventHandlerService.emitMainEvent(!isOpened);
  }

  /**
   * --------------------
   */

  onChangeToCatalog(path) {
    if ( path === "/shop/company-section") {
      this.isCompany = true;
    } else {
      this.isCompany = false;
    }
    if (path === "/shop/catalogue") {
      this._mShowCatalogHeader = true;
    } else {
      this._mShowCatalogHeader = false;
    }
  }

  /**
   * ------------------------- on same route navigation ------------------------
   */

  onSameRouteNavigation() {
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        pairwise(),
        filter((events: RouterEvent[]) => events[0].url === events[1].url),
        startWith("Initial call"),
        takeUntil(this.destroyed)
      )
      .subscribe(() => {
        this.clearSearchValues();
        this._mSearchStatus = "clearSearch";
        this.contentScroller.nativeElement.scrollTop = 0;
      });
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  /**
   * ------------------------ Search Service Functions   -------------------
   */

  search(e) {
    console.log(e)
    this._searchEvent(e);
   }

  _searchEvent($event) {
    this.query = $event;
    this.page = 0;
    if (this.query.length > 2) {
      this._IsSearchActive = true;
      if (this._mSearchType === "products") {
        // if search type is product
        this.searchService.initProductSearch(this.query, this.page);
        const data = this.searchService.productSearch$;
        data
          .pipe(
            map(response => {
              this.searchResult$ = response;
            })
          )
          .subscribe();
        const loadMore = this.searchService.loadMore$;
        loadMore
          .pipe(
            map(hasData => {
              this._mShowLoadButton = hasData;
            })
          )
          .subscribe();
      } else {
        // else search type is companies
        this._showCompanyNames = true;
        this.searchService.initCompanyNames(this.query);
        const data = this.searchService.companyName$;
        data
          .pipe(
            map(response => {
              this.companyNamesResult$ = response;
            })
          )
          .subscribe();
      }
    } else {
      this.clearSearchValues();
    }
  }

  _loadMore() {
    this.page += 1;
    if (this._mSearchType === "products") {
      this.searchService.loadMoreProductSearch(this.query, this.page);
    } else {
      this.searchService.loadMoreCompanySearch(this.query, this.page);
    }
  }

  _fetchAllProductsByCompany(companyName: string) {
    this._IsSearchActive = true;
    this._showCompanyNames = false;
    this._mSearchString = companyName;
    this.searchService.initCompanySearch(companyName, this.page);
    const data = this.searchService.companySearch$;
    data
      .pipe(
        map(response => {
          this.searchResult$ = response;
        })
      )
      .subscribe();

    const loadMore = this.searchService.loadMore$;
    loadMore
      .pipe(
        map(hasData => {
          this._mShowLoadButton = hasData;
        })
      )
      .subscribe();
  }

  clearSearchValues() {
    this._mSearchString = "";
    this.page = 0;
    this._IsSearchActive = false;
    this._showCompanyNames = false;
    this.searchResult$ = [];
    this.companyNamesResult$ = [];
  }

  tapSection(item) {
    navigator.geolocation.getCurrentPosition(
      response => {
        const body = {
          eventProperties: {
            userId: JSON.parse(localStorage.getItem("userData")).user.id,
            phoneNumber: JSON.parse(localStorage.getItem("userData")).user.mobileNo,
            enabled: JSON.parse(localStorage.getItem("userData")).user.enabled,
            latitude: response.coords.latitude,
            longitude: response.coords.longitude,
            section_id: item.title.toLowerCase()
          },
          eventName: 'section_tapped',
          userId: JSON.parse(localStorage.getItem("userData")).user.id,
          mobileNo: JSON.parse(localStorage.getItem("userData")).user.mobileNo,
          source: "pharmacyWeb"
        };
        // this.eventService.tapSectionEvent({}, body).subscribe(
        //   response => {},
        //   error => {}
        // );
      },
      error => {
        const body = {
          eventProperties: {
            userId: JSON.parse(localStorage.getItem("userData")).user.id,
            phoneNumber: JSON.parse(localStorage.getItem("userData")).user.mobileNo,
            enabled: JSON.parse(localStorage.getItem("userData")).user.enabled,
            section_id: item.title.toLowerCase()
          },
          eventName: 'section_tapped',
          userId: JSON.parse(localStorage.getItem("userData")).user.id,
          mobileNo: JSON.parse(localStorage.getItem("userData")).user.mobileNo,
          source: "pharmacyWeb"
        };
        // this.eventService.tapSectionEvent({}, body).subscribe(
        //   response => {},
        //   error => {}
        // );
      }
    )
  }
}
