import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DialogModelMultipleImages } from "./model";

@Component({
  selector: "app-multiple-images",
  templateUrl: "./multiple-images.component.html",
  styleUrls: ["./multiple-images.component.scss"],
})
export class MultipleImagesComponent implements OnInit {
  currImage = 0;
  currImageUrl = "";
  length = 0;
  currEntension = "";
  constructor(
    public dialogRef: MatDialogRef<MultipleImagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelMultipleImages
  ) {
    if (this.data?.imageUrls?.images) {
      this.currImage = this.data?.imageUrls?.index;
      this.currImageUrl = this.data.imageUrls.images[this.currImage];
      this.currEntension = this.currImageUrl.split(".").pop();
      this.length = this.data.imageUrls.images.length;
    } else if (this.data && this.data.imageUrls) {
      this.currImage = 0;
      this.currImageUrl = this.data.imageUrls[this.currImage];
      this.currEntension = this.currImageUrl.split(".").pop();
      this.length = this.data.imageUrls.length;
    }
  }

  ngOnInit(): void {}

  getNextImage() {
    let arrayCheck;
    if (this.data?.imageUrls?.images) {
      arrayCheck = this.data.imageUrls.images;
    } else {
      arrayCheck = this.data.imageUrls;
    }
    if (this.currImage < arrayCheck.length - 1) {
      this.currImage++;
      this.currImageUrl = arrayCheck[this.currImage];
      this.currEntension = this.currImageUrl.split(".").pop();
    }
  }

  getPreviousImage() {
    let arrayCheck;
    if (this.data?.imageUrls?.images) {
      arrayCheck = this.data.imageUrls.images;
    } else {
      arrayCheck = this.data.imageUrls;
    }
    if (this.currImage > 0) {
      this.currImage--;
      this.currImageUrl = arrayCheck[this.currImage];
      this.currEntension = this.currImageUrl.split(".").pop();
    }
  }

  closeIt() {
    this.dialogRef.close();
  }
}
