import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import { FilterService } from "src/app/core/services/filter/filter.service";

@Component({
  selector: "app-common-filters",
  templateUrl: "./common-filters.component.html",
  styleUrls: ["./common-filters.component.scss"],
})
export class CommonFiltersComponent implements OnChanges {
  header = {
    value: "",
    filter: false,
    key: "",
    sorting: { active: false, standAlone: false },
  };
  tableData = [];
  filters = {};
  filteredTableData = [];
  defaultSort = true;
  sort = false;

  constructor(private filterService: FilterService) {}

  @Input("filters") set filter(value: any) {
    if (value) {
      this.filters = value;
    }
  }

  @Input("header") set val(value: any) {
    if (value) {
      this.header = value;
    }
  }

  @Input("data") set data(value: any) {
    if (value) {
      this.tableData = value;
    }
  }

  @Output() parentData: EventEmitter<any> = new EventEmitter<any>();

  ngOnChanges(): void {
    this.filters[this.header.key] = {
      uniqueValues: [],
      values: [],
      key: this.header.key,
      type: "",
      active: false,
      show: false,
      searchString: "",
      sorting: {
        active: false,
        default: true,
        show: this.header?.sorting?.active || false,
        standAlone: this.header?.sorting?.standAlone || false,
      },
    };

    this.filters = this.filterService.getFiltersV2(
      [...this.tableData],
      this.filters
    );

    // this.filterData();
  }

  filterOptionSelect(event, key) {
    this.filters[key].values = event.value;
  }

  searchFilter(filter, event) {
    filter.searchString = event.target.value;
    filter = this.filterService.searchInFilter(filter);
  }

  toggleFilter(key, filter) {
    if (!this.filters[key]?.sorting?.standAlone) {
      filter && (this.filters[key].show = !this.filters[key].show);
    } else {
      //  sorting standalone is opted and checking if sorting filter is active or not
      if (this.filters[key]?.sorting?.active) {
        // sorting filter is active, then switching btw asc and dsc order of sorting filter on same click
        this.filters[key].sorting.default = !this.filters[key].sorting.default;
      } else {
        this.filters[key].sorting.active = true;
      }
      this.updateSort(key);
    }
  }

  removeFilterVal(key, index) {
    let arr = [...this.filters[key].values];
    arr.splice(index, 1);
    this.filters[key].values = arr;
  }

  removeFilter(event) {
    this.tableData = event.tableData;
    this.filters = event.filters;
    this.filters[event.key].active = false;
    this.filters[event.key].sorting.active = false;
    this.filters[event.key].values = [];
  }

  headerFilterClick(key) {
    this.filters[key].show = !this.filters[key].show;
  }

  updateFilter(key) {
    this.filters[key].active = true;

    this.filterData(key);

    this.filters[key].show = false;
  }

  updateSort(key) {
    this.filters[key].active = true;
    this.filteredTableData = this.sortByKey(
      [...this.tableData],
      key,
      this.filters[key].sorting.default
    );

    // Sending the data back to Parent component and calling the setFilteredData indirectly
    if (key) {
      this.parentData.emit({
        filteredData: this.filteredTableData,
        filters: this.filters,
        key,
      });
    }
    this.filters[key].show = false;
  }

  // Filter data
  filterData(key = "") {
    this.filteredTableData = this.filterService.filterDataV2(
      [...this.tableData],
      this.filters
    );
    // Sending the data back to Parent component and calling the setFilteredData indirectly
    if (key) {
      !this.filters[key]?.values?.length && (this.filters[key].active = false);
      this.parentData.emit({
        filteredData: this.filteredTableData,
        filters: this.filters,
        key,
      });
    }
  }

  applySort(key) {
    this.toggleSort();

    if (!this.filters[key].sorting.active) {
      this.filters[key].sorting.active = true;
      this.sortToggle(key);
    } else {
      this.sortToggle(key);
    }
  }

  toggleSort() {
    if (this.defaultSort) {
      this.defaultSort = false;
      this.sort = !this.sort;
    } else {
      this.sort = !this.sort;
    }
  }

  sortToggle(key) {
    this.filters[key].sorting.default = !this.filters[key].sorting.default;
    this.updateSort(key);
  }

  sortByKey(array, key, type) {
    if (type) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    } else {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x > y ? -1 : x < y ? 1 : 0;
      });
    }
  }
}
