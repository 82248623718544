import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FinancePaymentHistoryDialogModel } from './model';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { FinanceService } from 'src/app/core/services/api/finance.service';

@Component({
  selector: 'app-finance-payment-history',
  templateUrl: './finance-payment-history.component.html',
  styleUrls: ['./finance-payment-history.component.scss']
})
export class FinancePaymentHistoryComponent implements OnInit {
  payments = [];
  page = 0;

  constructor(
    public dialogRef: MatDialogRef<FinancePaymentHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FinancePaymentHistoryDialogModel,
    private financeService: FinanceService,
    private popupService: PopupService) { }

  ngOnInit(): void {
    this.popupService.openProgress();
    this.getPayments(true);
  }

  getPayments(closeProgress) {
    const params = {
      distributorId: this.data.distirbutorId,
      page: this.page
    }
    this.financeService.getPaymentHistory(params).subscribe(
      response => {
        this.payments = response.data;
        if (closeProgress) {
          this.popupService.closeProgress();
        }
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  close() {
    this.dialogRef.close();
  }

}
