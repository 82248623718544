import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef, MatIconRegistry } from "@angular/material";
import { DialogErrorComponent } from "src/app/shared/dialog/dialog-error/dialog-error.component";
import { DialogModelError } from "src/app/shared/dialog/dialog-error/model";
import { DialogProgressComponent } from "src/app/shared/dialog/dialog-progress/dialog-progress.component";
import { DialogModelProgress } from "src/app/shared/dialog/dialog-progress/model";
import { DialogSuccessComponent } from "src/app/shared/dialog/dialog-success/dialog-success.component";
import { DialogModelSuccess } from "src/app/shared/dialog/dialog-success/model";
import { DialogConfirmComponent } from "src/app/shared/dialog/dialog-confirm/dialog-confirm.component";
import { DialogModelConfirm } from "src/app/shared/dialog/dialog-confirm/model";
import { DialogModel } from "src/app/shared/dialog/wmm-popup/model";
import { MissingDialogModel } from "src/app/shared/dialog/missing-popup/model";
import { ExpiryDialogModel } from "src/app/shared/dialog/expiry/model";
import { WrongBilledDialogModel } from "src/app/shared/dialog/wrong-billed/model";
import { DialogRemoveMedConfirm } from "src/app/shared/dialog/remove-med-confirm/model";
import { DialogModelImage } from "src/app/shared/dialog/image/model";
import { WmmPopupComponent } from "src/app/shared/dialog/wmm-popup/wmm-popup.component";
import { DialogModelGenerateDn } from "src/app/shared/dialog/generate-dn/model";
import { GenerateDnComponent } from "src/app/shared/dialog/generate-dn/generate-dn.component";
import { MissingPopupComponent } from "src/app/shared/dialog/missing-popup/missing-popup.component";
import { ExpiryComponent } from "src/app/shared/dialog/expiry/expiry.component";
import { WrongBilledComponent } from "src/app/shared/dialog/wrong-billed/wrong-billed.component";
import { RemoveBounceMedComponent } from "src/app/shared/dialog/remove-bounce-med/remove-bounce-med.component";
import { RemoveBounceMedDialogModel } from "src/app/shared/dialog/remove-bounce-med/model";
import { RemoveMedConfirmComponent } from "src/app/shared/dialog/remove-med-confirm/remove-med-confirm.component";
import { SearchByRetailerComponent } from "src/app/shared/dialog/search-by-retailer/search-by-retailer.component";
import { DialogModelSearchByRetailer } from "src/app/shared/dialog/search-by-retailer/model";
import { SearchByOrderComponent } from "src/app/shared/dialog/search-by-order/search-by-order.component";
import { DialogModelSearchByOrder } from "src/app/shared/dialog/search-by-order/model";
import { SearchByInvoiceComponent } from "src/app/shared/dialog/search-by-invoice/search-by-invoice.component";
import { DialogModelSearchByInvoice } from "src/app/shared/dialog/search-by-invoice/model";
import { BlockUserComponent } from "src/app/shared/dialog/block-user/block-user.component";
import { DialogModelBlockUser } from "src/app/shared/dialog/block-user/model";
import { ImageComponent } from "src/app/shared/dialog/image/image.component";
import { UnblockUserComponent } from "src/app/shared/dialog/unblock-user/unblock-user.component";
import { DialogModelUnBlockUser } from "src/app/shared/dialog/unblock-user/model";
import { CommentComponent } from "src/app/shared/dialog/comment/comment.component";
import { DialogModelComment } from "src/app/shared/dialog/comment/model";
import { LogisticsCollectComponent } from "src/app/shared/dialog/logistics-collect/logistics-collect.component";
import { DialogModelLogisticsCollect } from "src/app/shared/dialog/logistics-collect/model";
import { CollectRetailerOutstandingComponent } from "src/app/shared/dialog/collect-retailer-outstanding/collect-retailer-outstanding.component";
import { DialogModelCollectRetailerOutstanding } from "src/app/shared/dialog/collect-retailer-outstanding/model";
import { OutstandingPaymentHistoryComponent } from "src/app/shared/dialog/outstanding-payment-history/outstanding-payment-history.component";
import { DialogModelOutstandingPaymentHistory } from "src/app/shared/dialog/outstanding-payment-history/model";
import { OutstandingDeRetailerInvoiceComponent } from "src/app/shared/dialog/outstanding-de-retailer-invoice/outstanding-de-retailer-invoice.component";
import { DialogModelDERetailerOutstandingInvoice } from "src/app/shared/dialog/outstanding-de-retailer-invoice/model";
import { OutstandingBillingInvoiceComponent } from "src/app/shared/dialog/outstanding-billing-invoice/outstanding-billing-invoice.component";
import { DialogModelBillingOutstandingInvoice } from "src/app/shared/dialog/outstanding-billing-invoice/model";
import { ConnectionLostComponent } from "src/app/shared/dialog/connection-lost/connection-lost.component";
import { DialogModelConfirmDisablePlatform } from "src/app/shared/dialog/confirm-disable-platform/model";
import { ConfirmDisablePlatformComponent } from "src/app/shared/dialog/confirm-disable-platform/confirm-disable-platform.component";
import { DialogModelConfirmSingleSOCancel } from "src/app/shared/dialog/confirm-single-so-cancel/model";
import { ConfirmSingleSoCancelComponent } from "src/app/shared/dialog/confirm-single-so-cancel/confirm-single-so-cancel.component";
import { ConfirmMultipleSoCancelComponent } from "src/app/shared/dialog/confirm-multiple-so-cancel/confirm-multiple-so-cancel.component";
import { DialogModelConfirmMultipleSOCancel } from "src/app/shared/dialog/confirm-multiple-so-cancel/model";
import { ConfirmRetrieveComponent } from "src/app/shared/dialog/confirm-retrieve/confirm-retrieve.component";
import { DialogModelConfirmRetrieve } from "src/app/shared/dialog/confirm-retrieve/model";
import { GeneratePoComponent } from "src/app/shared/dialog/generate-po/generate-po.component";
import { DialogModelGeneratePO } from "src/app/shared/dialog/generate-po/model";
import { DialogModelConfirmDeleteMed } from "src/app/shared/dialog/confirm-medicine-delete/model";
import { ConfirmMedicineDeleteComponent } from "src/app/shared/dialog/confirm-medicine-delete/confirm-medicine-delete.component";
import { CommonConfirmComponent } from "src/app/shared/dialog/common-confirm/common-confirm.component";
import { DialogModelCommonConfirm } from "src/app/shared/dialog/common-confirm/model";
import { AdnUploadErrorsComponent } from "src/app/shared/dialog/adn-upload-errors/adn-upload-errors.component";
import { AdnUploadCsvComponent } from "src/app/shared/dialog/adn-upload-csv/adn-upload-csv.component";
import { DialogModelAdnUpload } from "src/app/shared/dialog/adn-upload-csv/model";
import { DialogModelADN } from "src/app/shared/dialog/adn-upload-errors/model";
import { LiveSiOrderComponent } from "src/app/shared/dialog/live-si-order/live-si-order.component";
import { DialogModelLiveSI } from "src/app/shared/dialog/live-si-order/model";
import { AddMedicineSiComponent } from "src/app/shared/dialog/add-medicine-si/add-medicine-si.component";
import { DialogModelAddMed } from "src/app/shared/dialog/add-medicine-si/model";
import { PastSiOrderComponent } from "src/app/shared/dialog/past-si-order/past-si-order.component";
import { DialogModelPastSI } from "src/app/shared/dialog/past-si-order/model";
import { AddNewMedicineToMapComponent } from "src/app/shared/dialog/add-new-medicine-to-map/add-new-medicine-to-map.component";
import { DialogModelNewMed } from "src/app/shared/dialog/add-new-medicine-to-map/model";
import { AddNewCompositionComponent } from "src/app/shared/dialog/add-new-composition/add-new-composition.component";
import { DialogModelNewComposition } from "src/app/shared/dialog/add-new-composition/model";
import { DialogModelNewManufacture } from "src/app/shared/dialog/add-new-manufacture/model";
import { AddNewManufactureComponent } from "src/app/shared/dialog/add-new-manufacture/add-new-manufacture.component";
import { DialogModelNewDisease } from "src/app/shared/dialog/add-new-disease/model";
import { AddNewDiseaseComponent } from "src/app/shared/dialog/add-new-disease/add-new-disease.component";
import { ErrorListComponent } from "src/app/shared/dialog/error-list/error-list.component";
import { DialogModelErrorList } from "src/app/shared/dialog/error-list/model";
import { UploadCreditErrorComponent } from "src/app/shared/dialog/upload-credit-error/upload-credit-error.component";
import { DialogModelUploadCreditError } from "src/app/shared/dialog/upload-credit-error/model";
import { AdnMapConfirmComponent } from "src/app/shared/dialog/adn-map-confirm/adn-map-confirm.component";
import { DialogModelAdnMapConfirm } from "src/app/shared/dialog/adn-map-confirm/model";
import { ConfirmComponent } from "src/app/shared/dialog/confirm/confirm.component";
import { DialogModelConfirmCheck } from "src/app/shared/dialog/confirm/model";
import { CreateTicketComponent } from "src/app/shared/dialog/create-ticket/create-ticket.component";
import { DialogModelCreateTicket } from "src/app/shared/dialog/create-ticket/model";
import { DialogModelTicketInstructions } from "src/app/shared/dialog/ticket-instrutions/model";
import { TicketInstrutionsComponent } from "src/app/shared/dialog/ticket-instrutions/ticket-instrutions.component";
import { DialogModelCreateHelpExecutive } from "src/app/shared/dialog/create-help-executive/model";
import { CreateHelpExecutiveComponent } from "src/app/shared/dialog/create-help-executive/create-help-executive.component";
import { DialogModelMultipleImages } from "src/app/shared/dialog/multiple-images/model";
import { MultipleImagesComponent } from "src/app/shared/dialog/multiple-images/multiple-images.component";
import { DialogModelUploadCreditBillError } from "src/app/shared/dialog/upload-credit-bill-error/model";
import { UploadCreditBillErrorComponent } from "src/app/shared/dialog/upload-credit-bill-error/upload-credit-bill-error.component";
import { ConfirmReasonComponent } from "src/app/shared/dialog/confirm-reason/confirm-reason.component";
import { DialogModelConfirmReason } from "src/app/shared/dialog/confirm-reason/model";
import { AssignExpiryComponent } from "src/app/shared/dialog/assign-expiry/assign-expiry.component";
import { CaCollectionComponent } from "src/app/shared/dialog/ca-collection/ca-collection.component";
import { DialogModelCaCollection } from "src/app/shared/dialog/ca-collection/model";
import { MappingApproveConfirmComponent } from "src/app/shared/dialog/mapping-approve-confirm/mapping-approve-confirm.component";
import { DialogApproveConfirm } from "src/app/shared/dialog/mapping-approve-confirm/model";
import { DialogModelWarehouseGenericConfirm } from "src/app/shared/dialog/warehouse-generic-confirm/model";
import { WarehouseGenericConfirmComponent } from "src/app/shared/dialog/warehouse-generic-confirm/warehouse-generic-confirm.component";
import { DialogModelDROpenItemsSlotFilter } from "src/app/shared/dialog/dr-open-items-slot-filter/model";
import { DrOpenItemsSlotFilterComponent } from "src/app/shared/dialog/dr-open-items-slot-filter/dr-open-items-slot-filter.component";
import { DialogModelDROpenItemsSpokeFilter } from "src/app/shared/dialog/dr-open-items-spoke-filter/model";
import { DrOpenItemsSpokeFilterComponent } from "src/app/shared/dialog/dr-open-items-spoke-filter/dr-open-items-spoke-filter.component";
import { AddOpenMedicineWarehouseComponent } from "src/app/shared/dialog/add-open-medicine-warehouse/add-open-medicine-warehouse.component";
import { DialogModelAddOpenMedicineWarehouse } from "src/app/shared/dialog/add-open-medicine-warehouse/model";
import { AddPickItemComponent } from "src/app/shared/dialog/add-pick-item/add-pick-item.component";
import { DialogModelAddPickItem } from "src/app/shared/dialog/add-pick-item/model";
import { DialogModelCollectSignedSlip } from "src/app/shared/dialog/collect-signed-slip/model";
import { CollectSignedSlipComponent } from "src/app/shared/dialog/collect-signed-slip/collect-signed-slip.component";
import { DialogModelAddBanner } from "src/app/shared/dialog/add-banner/model";
import { AddBannerComponent } from "src/app/shared/dialog/add-banner/add-banner.component";
import { DialogModelCollectAcknowledgementSlip } from "src/app/shared/dialog/collect-acknowledgement-slip/model";
import { CollectAcknowledgementSlipComponent } from "src/app/shared/dialog/collect-acknowledgement-slip/collect-acknowledgement-slip.component";
import { DialogModelSendExpiryItemToDn } from "src/app/shared/dialog/send-expiry-itmes-to-dn/model";
import { SendExpiryItmesToDnComponent } from "src/app/shared/dialog/send-expiry-itmes-to-dn/send-expiry-itmes-to-dn.component";
import { DialogModelAddItemsFilter } from "src/app/shared/dialog/add-items-filter/model";
import { AddItemsFilterComponent } from "src/app/shared/dialog/add-items-filter/add-items-filter.component";
import { DialogModelGenerateRDCN } from "src/app/shared/dialog/generate-rdcn/model";
import { GenerateRdcnComponent } from "src/app/shared/dialog/generate-rdcn/generate-rdcn.component";
import { DialogModelCheckManualBill } from "src/app/shared/dialog/check-manual-bill/model";
import { CheckManualBillComponent } from "src/app/shared/dialog/check-manual-bill/check-manual-bill.component";
import { GstResponseComponent } from "src/app/shared/dialog/gst-response/gst-response.component";
import { DialogModelGSTResponse } from "src/app/shared/dialog/gst-response/model";
import { FinanceInvoiceDialogModel } from "src/app/shared/dialog/finance-invoice-details/model";
import { FinanceInvoiceDetailsComponent } from "src/app/shared/dialog/finance-invoice-details/finance-invoice-details.component";
import { FinanceDistributorDialogModel } from "src/app/shared/dialog/finance-distributor-details/model";
import { FinanceDistributorDetailsComponent } from "src/app/shared/dialog/finance-distributor-details/finance-distributor-details.component";
import { FinanceDisputeDialogModel } from "src/app/shared/dialog/finance-dispute/model";
import { FinanceDisputeComponent } from "src/app/shared/dialog/finance-dispute/finance-dispute.component";
import { FinanceInvoiceListDialogModel } from "src/app/shared/dialog/finance-invoice-list/model";
import { FinanceInvoiceListComponent } from "src/app/shared/dialog/finance-invoice-list/finance-invoice-list.component";
import { FinanceDNDialogModel } from "src/app/shared/dialog/finance-dn/model";
import { FinanceDnComponent } from "src/app/shared/dialog/finance-dn/finance-dn.component";
import { FinanceInvoiceAdjustDialogModel } from "src/app/shared/dialog/finance-invoice-adjust/model";
import { FinanceInvoiceAdjustComponent } from "src/app/shared/dialog/finance-invoice-adjust/finance-invoice-adjust.component";
import { FinancePaymentHistoryComponent } from "src/app/shared/dialog/finance-payment-history/finance-payment-history.component";
import { FinancePaymentHistoryDialogModel } from "src/app/shared/dialog/finance-payment-history/model";
import { FinanceCnGeneratedComponent } from "src/app/shared/dialog/finance-cn-generated/finance-cn-generated.component";
import { FinanceCnGeneratedDialogModel } from "src/app/shared/dialog/finance-cn-generated/model";
import { UploadWinnerListComponent } from "src/app/shared/dialog/upload-winner-list/upload-winner-list.component";
import { DialogModelUploadWinnerList } from "src/app/shared/dialog/upload-winner-list/model";
import { FoundQuantityDialogModel } from "src/app/shared/dialog/found-quantity/model";
import { FoundQuantityComponent } from "src/app/shared/dialog/found-quantity/found-quantity.component";
import { DialogModelSalesDashboard } from "src/app/shared/dialog/sales-dashboard-popup/model";
import { SalesDashboardPopupComponent } from "src/app/shared/dialog/sales-dashboard-popup/sales-dashboard-popup.component";
import { SaleMedicinesComponent } from "src/app/shared/dialog/sale-medicines/sale-medicines.component";
import { DialogModelSaleMedicines } from "src/app/shared/dialog/sale-medicines/model";
import { CustomizeConfirmComponent } from "src/app/shared/dialog/customize-confirm/customize-confirm.component";
import { DialogModelCustomizeConfirm } from "src/app/shared/dialog/customize-confirm/model";
import { DialogModelSaveoLoader } from "src/app/shared/dialog/saveo-loader/model";
import { SaveoLoaderComponent } from "src/app/shared/dialog/saveo-loader/saveo-loader.component";
import { DialogManualCN } from "src/app/shared/dialog/manual-cn-popup/model";
import { ManualCnPopupComponent } from "src/app/shared/dialog/manual-cn-popup/manual-cn-popup.component";
import { RevertCNComponent } from "src/app/shared/dialog/revert-cn/revert-cn.component";
import { DialogRevertCnModel } from "src/app/shared/dialog/revert-cn/model";
import { DialogManageCN } from "src/app/shared/dialog/manage-cn-popup/model";
import { ManageCnPopupComponent } from "src/app/shared/dialog/manage-cn-popup/manage-cn-popup.component";
import { ViewPincodeMappingComponent } from "src/app/shared/dialog/view-pincode-mapping/view-pincode-mapping.component";
import { DialogViewPincodeMappingModel } from "src/app/shared/dialog/view-pincode-mapping/model";
import { ReverifyComponent } from "src/app/shared/dialog/reverify/reverify.component";
import { DialogReverify } from "src/app/shared/dialog/reverify/model";
import { DialogModelLogisticsCoinsEdit } from "../../../shared/dialog/logistics-coins-edit/model";
import { LogisticsCoinsEditComponent } from "../../../shared/dialog/logistics-coins-edit/logistics-coins-edit.component";
import { LogisticsSessionsProceedComponent } from "../../../shared/dialog/logistics-sessions-proceed/logistics-sessions-proceed.component";
import { DialogModelLogisticsSessions } from "../../../shared/dialog/logistics-sessions-proceed/model";
import { MapAdjustmentComponent } from "src/app/shared/dialog/map-adjustment/map-adjustment.component";
import { DialogMapAdjustment } from "src/app/shared/dialog/map-adjustment/model";
import { DialogModelConfirmSession } from "../../../shared/dialog/create-session-confirm/model";
import { CreateSessionConfirmComponent } from "../../../shared/dialog/create-session-confirm/create-session-confirm.component";
import { OutstandingPopupComponent } from "src/app/shared/dialog/outstanding-popup/outstanding-popup.component";
import { DialogModelOutstandingPopUp } from "src/app/shared/dialog/outstanding-popup/model";
import { DialogModelPrintBillWarning } from "src/app/shared/dialog/print-bill-warning/model";
import { PrintBillWarningComponent } from "src/app/shared/dialog/print-bill-warning/print-bill-warning.component";
import { DialogModelViewBill } from "src/app/shared/dialog/view-bill/model";
import { ViewBillComponent } from "src/app/shared/dialog/view-bill/view-bill.component";

@Injectable({
  providedIn: "root",
})
export class PopupService {
  mDialogRef: MatDialogRef<DialogProgressComponent, any>;
  saveoLoaderRef: MatDialogRef<SaveoLoaderComponent, any>;
  errorListDialogRef: MatDialogRef<ErrorListComponent, any>;
  dialogRef: MatDialogRef<DialogConfirmComponent, any>;
  wmmDialogRef: MatDialogRef<WmmPopupComponent, any>;
  genDnDialogRef: MatDialogRef<GenerateDnComponent, any>;
  missingDialogRef: MatDialogRef<MissingPopupComponent, any>;
  expiryDialogRef: MatDialogRef<ExpiryComponent, any>;
  wrongBilledDialogRef: MatDialogRef<WrongBilledComponent, any>;
  removeBounceMedDialogRef: MatDialogRef<RemoveBounceMedComponent, any>;
  removeMedDialogRef: MatDialogRef<RemoveMedConfirmComponent, any>;
  searchByRetailerDialogRef: MatDialogRef<SearchByRetailerComponent, any>;
  searchByOrderDialogRef: MatDialogRef<SearchByOrderComponent, any>;
  searchByInvoiceDialogRef: MatDialogRef<SearchByInvoiceComponent, any>;
  blockUserDialogRef: MatDialogRef<BlockUserComponent, any>;
  unblockUserDialogRef: MatDialogRef<UnblockUserComponent, any>;
  imageDialogRef: MatDialogRef<ImageComponent, any>;
  commentDialogRef: MatDialogRef<CommentComponent, any>;
  logisticsCollectDialogRef: MatDialogRef<LogisticsCollectComponent, any>;
  collectRetailerOutstandingDialogRef: MatDialogRef<
    CollectRetailerOutstandingComponent,
    any
  >;
  outstandingPaymentHistoryDialogRef: MatDialogRef<
    OutstandingPaymentHistoryComponent,
    any
  >;
  outstandingDERetailerInvoiceDialogRef: MatDialogRef<
    OutstandingDeRetailerInvoiceComponent,
    any
  >;
  outstandingBillingInvoiceDialogRef: MatDialogRef<
    OutstandingBillingInvoiceComponent,
    any
  >;
  connectionLosteDialogRef: MatDialogRef<ConnectionLostComponent, any>;
  confirmDisablePlatformDialogRef: MatDialogRef<
    ConfirmDisablePlatformComponent,
    any
  >;
  cancelSingleSOConfirmDialogRef: MatDialogRef<
    ConfirmSingleSoCancelComponent,
    any
  >;
  cancelMultipleSOConfirmDialogRef: MatDialogRef<
    ConfirmMultipleSoCancelComponent,
    any
  >;
  confirmRetrieveDialogRef: MatDialogRef<ConfirmRetrieveComponent, any>;
  generatePODialogRef: MatDialogRef<GeneratePoComponent, any>;
  deleteMedicineDialogRef: MatDialogRef<ConfirmMedicineDeleteComponent, any>;
  commonConfirmDialogRef: MatDialogRef<CommonConfirmComponent, any>;
  adnUploadDialogRef: MatDialogRef<AdnUploadErrorsComponent, any>;
  adnUploadCsvDialogRef: MatDialogRef<AdnUploadCsvComponent, any>;
  liveSiOrdersDialogRef: MatDialogRef<LiveSiOrderComponent, any>;
  addMedicineDialogRef: MatDialogRef<AddMedicineSiComponent, any>;
  pastSiOrdersDialogRef: MatDialogRef<PastSiOrderComponent, any>;
  addNewMedToMap: MatDialogRef<AddNewMedicineToMapComponent, any>;
  addNewComposition: MatDialogRef<AddNewCompositionComponent, any>;
  addNewManufacture: MatDialogRef<AddNewManufactureComponent, any>;
  uploadCreditDialogRef: MatDialogRef<UploadCreditErrorComponent, any>;
  addNewDisease: MatDialogRef<AddNewDiseaseComponent, any>;
  confirmDialogRef: MatDialogRef<ConfirmComponent, any>;
  createTicketDialogRef: MatDialogRef<CreateTicketComponent, any>;
  ticketInstructionDialogRef: MatDialogRef<TicketInstrutionsComponent, any>;
  createHelpExecutiveDialogRef: MatDialogRef<CreateHelpExecutiveComponent, any>;
  multipleImagesDialogRef: MatDialogRef<MultipleImagesComponent, any>;
  uploadCreditBillDialogRef: MatDialogRef<UploadCreditBillErrorComponent, any>;
  confirmReasonDialogRef: MatDialogRef<ConfirmReasonComponent, any>;
  DialogModelConfirmReason: MatDialogRef<DialogModelConfirmReason, any>;
  assignExpiryDialogRef: MatDialogRef<AssignExpiryComponent, any>;
  caCollectionDialogRef: MatDialogRef<CaCollectionComponent, any>;
  DialogApproveConfirm: MatDialogRef<MappingApproveConfirmComponent, any>;
  warehouseGenericConfirmDialogRef: MatDialogRef<CommonConfirmComponent, any>;
  drOpenItemsSlotFilterDialogRef: MatDialogRef<
    DrOpenItemsSlotFilterComponent,
    any
  >;
  drOpenItemsSpokeFilterDialogRef: MatDialogRef<
    DrOpenItemsSpokeFilterComponent,
    any
  >;
  addOpenMedicineWarehouseDialogRef: MatDialogRef<
    AddOpenMedicineWarehouseComponent,
    any
  >;
  addPickItemDialogRef: MatDialogRef<AddPickItemComponent, any>;
  AdnMapDialogRef: MatDialogRef<AdnMapConfirmComponent, any>;
  collectSignedSlipDialogRef: MatDialogRef<CollectSignedSlipComponent, any>;
  addBannerDialogRef: MatDialogRef<AddBannerComponent, any>;
  collectAcknowledgementSlipDialogRef: MatDialogRef<
    CollectAcknowledgementSlipComponent,
    any
  >;
  sendExpiryItemsForDnDialogRef: MatDialogRef<
    SendExpiryItmesToDnComponent,
    any
  >;
  addItemsFilterDialogRef: MatDialogRef<AddItemsFilterComponent, any>;
  generateRDCNDialogRef: MatDialogRef<GenerateRdcnComponent, any>;
  checkManualBillDialogRef: MatDialogRef<CheckManualBillComponent, any>;
  gstResponseDialogRef: MatDialogRef<GstResponseComponent, any>;
  financeInvoiceDetailsDialogRef: MatDialogRef<
    FinanceInvoiceDetailsComponent,
    any
  >;
  financeDistirbutorDetailsDialogRef: MatDialogRef<
    FinanceDistributorDetailsComponent,
    any
  >;
  financeDisputeDialogRef: MatDialogRef<FinanceDisputeComponent, any>;
  financeInvoiceListDialogRef: MatDialogRef<FinanceInvoiceListComponent, any>;
  financeDNDialogRef: MatDialogRef<FinanceDnComponent, any>;
  financeInvoiceAdjusrDialogRef: MatDialogRef<
    FinanceInvoiceAdjustComponent,
    any
  >;
  financePaymentHistoryDialogRef: MatDialogRef<
    FinancePaymentHistoryComponent,
    any
  >;
  financeCnGeneratedDialogRef: MatDialogRef<FinanceCnGeneratedComponent, any>;
  uploadWinnerListDialogRef: MatDialogRef<UploadWinnerListComponent, any>;
  foundQuantityDialogRef: MatDialogRef<FoundQuantityComponent, any>;
  salesDashboardDialogRef: MatDialogRef<SalesDashboardPopupComponent, any>;
  salesMedicinesDialogRef: MatDialogRef<SaleMedicinesComponent, any>;
  customizeConfirmDialogRef: MatDialogRef<CustomizeConfirmComponent, any>;
  manualCNDialogRef: MatDialogRef<ManualCnPopupComponent, any>;
  manageCNDialogRef: MatDialogRef<ManageCnPopupComponent, any>;
  revertCnDialogRef: MatDialogRef<RevertCNComponent, any>;
  viewPincodeMappingDialogRef: MatDialogRef<ViewPincodeMappingComponent, any>;
  reverifyDialogRef: MatDialogRef<ReverifyComponent, any>;
  logisticsCoinsEditDialogRef: MatDialogRef<LogisticsCoinsEditComponent, any>;
  logisticsSessionsDialogRef: MatDialogRef<
    LogisticsSessionsProceedComponent,
    any
  >;
  mapAdjustmentDialogRef: MatDialogRef<MapAdjustmentComponent, any>;
  confirmSessionDialogRef: MatDialogRef<CreateSessionConfirmComponent, any>;
  openOutstandingDialogRef: MatDialogRef<OutstandingPopupComponent, any>;
  commonWarningDialogRef: MatDialogRef<PrintBillWarningComponent, any>;
  viewBillDialogRef: MatDialogRef<ViewBillComponent, any>;

  _mInProgress = false;
  _saveoLoader = false;

  constructor(public dialog: MatDialog) {}

  // Popup functions
  // Auto close
  autoClose() {
    setTimeout(() => {
      if (this.mDialogRef) {
        this.mDialogRef.close();
      }
    }, 4000);
  }

  openSaveoLoader() {
    this.closeSaveoLoader();
    this._saveoLoader = true;
    const alert: DialogModelSaveoLoader = new DialogModelSaveoLoader();

    this.saveoLoaderRef = this.dialog.open(SaveoLoaderComponent, {
      data: alert,
      disableClose: true,
    });
  }

  // Close progress popup
  closeSaveoLoader() {
    this._saveoLoader = false;
    if (this.saveoLoaderRef) {
      this.saveoLoaderRef.close();
    }
  }

  openProgress() {
    this.closeProgress();
    this._mInProgress = true;
    const alert: DialogModelProgress = new DialogModelProgress(
      "Please Wait...",
      "",
      null,
      null
    );

    this.mDialogRef = this.dialog.open(DialogProgressComponent, {
      data: alert,
      disableClose: true,
    });
  }

  // Close progress popup
  closeProgress() {
    this._mInProgress = false;
    if (this.mDialogRef) {
      this.mDialogRef.close();
    }
  }

  // Open success popup
  openSuccess(response: string) {
    this._mInProgress = true;
    const alert: DialogModelSuccess = new DialogModelSuccess(
      response,
      "",
      null,
      null
    );

    this.mDialogRef = this.dialog.open(DialogSuccessComponent, {
      data: alert,
      disableClose: false,
    });
    this.autoClose();
  }

  // Open success popup without auto close
  openSuccessNoAutoClose(response: string) {
    this._mInProgress = true;
    const alert: DialogModelSuccess = new DialogModelSuccess(
      response,
      "",
      null,
      null
    );

    this.mDialogRef = this.dialog.open(DialogSuccessComponent, {
      data: alert,
      disableClose: false,
    });
  }

  // Open comon confirm confirm
  openGSTResponsePopup(response) {
    this._mInProgress = true;
    const alert: DialogModelGSTResponse = new DialogModelGSTResponse(response);

    return (this.gstResponseDialogRef = this.dialog.open(GstResponseComponent, {
      panelClass: "custom-dialog-container",
      data: alert,
      disableClose: true,
    }));
  }

  // Close success popup
  closeSuccess() {
    this._mInProgress = false;
    if (this.mDialogRef) {
      this.mDialogRef.close();
    }
  }

  // Open error popup
  openError(response: string) {
    this._mInProgress = true;
    const alert: DialogModelError = new DialogModelError(
      response,
      "",
      null,
      null
    );

    this.mDialogRef = this.dialog.open(DialogErrorComponent, {
      data: alert,
      disableClose: false,
    });
    // this.autoClose();
  }

  // Open error popup
  openErrorList(response: string) {
    this._mInProgress = true;
    const alert: DialogModelErrorList = new DialogModelErrorList(response);

    this.errorListDialogRef = this.dialog.open(ErrorListComponent, {
      data: alert,
      disableClose: false,
    });
    this.autoClose();
  }

  // Close error popup
  closeError() {
    this._mInProgress = false;
    if (this.mDialogRef) {
      this.mDialogRef.close();
    }
  }

  // Open confirm popup
  openConfirm(response, invoiceNumber) {
    this._mInProgress = true;
    const alert: DialogModelConfirm = new DialogModelConfirm(
      response,
      invoiceNumber,
      null,
      null
    );

    return (this.dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: alert,
      disableClose: true,
    }));
  }

  // Close confirm popup
  closeConfirm() {
    this._mInProgress = false;
    if (this.mDialogRef) {
      this.mDialogRef.close();
    }
  }

  // Open wmm popup
  openWmmPopup(response, medicines) {
    this._mInProgress = true;
    const alert: DialogModel = new DialogModel(response, medicines, null, null);

    return (this.wmmDialogRef = this.dialog.open(WmmPopupComponent, {
      data: alert,
      disableClose: true,
    }));
  }

  // Close wmm popup
  closeWmmPopup() {
    this._mInProgress = false;
    if (this.mDialogRef) {
      this.mDialogRef.close();
    }
  }

  // Open wmm popup
  openDnPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelGenerateDn = new DialogModelGenerateDn(
      response,
      null,
      null,
      null
    );

    return (this.genDnDialogRef = this.dialog.open(GenerateDnComponent, {
      data: alert,
      disableClose: true,
    }));
  }

  // Close wmm popup
  closeDnPopup() {
    this._mInProgress = false;
    if (this.genDnDialogRef) {
      this.genDnDialogRef.close();
    }
  }

  // Open wmm popup
  openMissingPopup(response) {
    this._mInProgress = true;
    const alert: MissingDialogModel = new MissingDialogModel(response);

    return (this.missingDialogRef = this.dialog.open(MissingPopupComponent, {
      data: alert,
      disableClose: true,
    }));
  }

  // Close wmm popup
  closeMissingPopup() {
    this._mInProgress = false;
    if (this.missingDialogRef) {
      this.missingDialogRef.close();
    }
  }

  // Open wmm popup
  openExpiryPopup(response) {
    this._mInProgress = true;
    const alert: ExpiryDialogModel = new ExpiryDialogModel(response);

    return (this.expiryDialogRef = this.dialog.open(ExpiryComponent, {
      data: alert,
      disableClose: true,
    }));
  }

  // Close wmm popup
  closeExpiryPopup() {
    this._mInProgress = false;
    if (this.expiryDialogRef) {
      this.expiryDialogRef.close();
    }
  }

  // Open wmm popup
  openWrongBilledPopup(response) {
    this._mInProgress = true;
    const alert: WrongBilledDialogModel = new WrongBilledDialogModel(response);

    return (this.wrongBilledDialogRef = this.dialog.open(WrongBilledComponent, {
      data: alert,
      disableClose: true,
    }));
  }

  // Close wmm popup
  closeWrongBilledPopup() {
    this._mInProgress = false;
    if (this.wrongBilledDialogRef) {
      this.wrongBilledDialogRef.close();
    }
  }

  // Open remove bounce medicine popup
  openRemoveBounceMedPopup(response) {
    this._mInProgress = true;
    const alert: RemoveBounceMedDialogModel = new RemoveBounceMedDialogModel(
      response
    );

    return (this.removeBounceMedDialogRef = this.dialog.open(
      RemoveBounceMedComponent,
      {
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open remove med confirm popup
  openRemoveMedPopup(response) {
    this._mInProgress = true;
    const alert: DialogRemoveMedConfirm = new DialogRemoveMedConfirm(response);

    return (this.removeMedDialogRef = this.dialog.open(
      RemoveMedConfirmComponent,
      {
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open remove med confirm popup
  openSalesDashboardPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelSalesDashboard = new DialogModelSalesDashboard(
      response
    );

    return (this.salesDashboardDialogRef = this.dialog.open(
      SalesDashboardPopupComponent,
      {
        data: alert,
        disableClose: true,
      }
    ));
  }

  // open generateCN modal
  openGenerateCNPopup(response) {
    this._mInProgress = true;
    const alert: DialogManualCN = new DialogManualCN(response);

    return (this.manualCNDialogRef = this.dialog.open(ManualCnPopupComponent, {
      data: alert,
      // disableClose: true,
    }));
  }

  // open manageCN popup
  openManageCNPopup(response) {
    this._mInProgress = true;
    const alert: DialogManageCN = new DialogManageCN(response);
    return (this.manageCNDialogRef = this.dialog.open(ManageCnPopupComponent, {
      data: alert,
    }));
  }

  // Open sale medicines popup
  openSaleMedicinesPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelSaleMedicines = new DialogModelSaleMedicines(
      response
    );

    return (this.salesMedicinesDialogRef = this.dialog.open(
      SaleMedicinesComponent,
      {
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Close remove bounce medicine popup
  closeRemoveBounceMedPopup() {
    this._mInProgress = false;
    if (this.removeBounceMedDialogRef) {
      this.removeBounceMedDialogRef.close();
    }
  }

  // Close remove med confirm popup
  closeRemoveMedPopup() {
    this._mInProgress = false;
    if (this.removeMedDialogRef) {
      this.removeMedDialogRef.close();
    }
  }

  // Open image popup
  openImagePopup(response) {
    this._mInProgress = true;
    const alert: DialogModelImage = new DialogModelImage(response);

    return (this.imageDialogRef = this.dialog.open(ImageComponent, {
      data: alert,
      disableClose: true,
    }));
  }

  // Open comment popup
  openCommentPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelComment = new DialogModelComment(response);

    return (this.commentDialogRef = this.dialog.open(CommentComponent, {
      data: alert,
      disableClose: true,
    }));
  }

  // Open upload winner list popup - gamification
  openUploadWinnerList(response, campaignId) {
    this._mInProgress = true;
    const alert: DialogModelUploadWinnerList = new DialogModelUploadWinnerList(
      response,
      campaignId
    );

    return (this.uploadWinnerListDialogRef = this.dialog.open(
      UploadWinnerListComponent,
      {
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open block user popup
  openBlockUserPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelBlockUser = new DialogModelBlockUser(response);

    return (this.blockUserDialogRef = this.dialog.open(BlockUserComponent, {
      data: alert,
      disableClose: true,
    }));
  }

  // Close block user confirm popup
  closeBlockUserPopup() {
    this._mInProgress = false;
    if (this.blockUserDialogRef) {
      this.blockUserDialogRef.close();
    }
  }

  // Open unblock user popup
  openUnblockUserPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelUnBlockUser = new DialogModelUnBlockUser(response);

    return (this.unblockUserDialogRef = this.dialog.open(UnblockUserComponent, {
      data: alert,
      disableClose: true,
    }));
  }

  // Close unblock user confirm popup
  closeUnblockUserPopup() {
    this._mInProgress = false;
    if (this.unblockUserDialogRef) {
      this.unblockUserDialogRef.close();
    }
  }

  // Open search by retailer code popup
  openSearchByRetailerPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelSearchByRetailer = new DialogModelSearchByRetailer(
      response
    );

    return (this.searchByRetailerDialogRef = this.dialog.open(
      SearchByRetailerComponent,
      {
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open search by order id popup
  openSearchByOrderPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelSearchByOrder = new DialogModelSearchByOrder(
      response
    );

    return (this.searchByOrderDialogRef = this.dialog.open(
      SearchByOrderComponent,
      {
        maxWidth: "90vw",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open search by order id popup
  openSearchByInvoicePopup(response) {
    this._mInProgress = true;
    const alert: DialogModelSearchByInvoice = new DialogModelSearchByInvoice(
      response
    );

    return (this.searchByInvoiceDialogRef = this.dialog.open(
      SearchByInvoiceComponent,
      {
        maxWidth: "90vw",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open logistics collect popup
  openLogisticsCollectPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelLogisticsCollect = new DialogModelLogisticsCollect(
      response
    );

    return (this.logisticsCollectDialogRef = this.dialog.open(
      LogisticsCollectComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
        autoFocus: false,
      }
    ));
  }

  // Open collect retailer outstanding popup
  openCollectRetailerOutstandingPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelCollectRetailerOutstanding =
      new DialogModelCollectRetailerOutstanding(response);

    return (this.collectRetailerOutstandingDialogRef = this.dialog.open(
      CollectRetailerOutstandingComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open outstanding payment history popup
  openOutstandingPaymentHistoryPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelOutstandingPaymentHistory =
      new DialogModelOutstandingPaymentHistory(response);

    return (this.outstandingPaymentHistoryDialogRef = this.dialog.open(
      OutstandingPaymentHistoryComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open outstanding de/retailer invoice popup
  openOutstandingDERetailerInvoicePopup(response) {
    this._mInProgress = true;
    const alert: DialogModelDERetailerOutstandingInvoice =
      new DialogModelDERetailerOutstandingInvoice(response);

    return (this.outstandingDERetailerInvoiceDialogRef = this.dialog.open(
      OutstandingDeRetailerInvoiceComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open outstanding billing invoice popup
  openOutstandingBillingInvoicePopup(response) {
    this._mInProgress = true;
    const alert: DialogModelBillingOutstandingInvoice =
      new DialogModelBillingOutstandingInvoice(response);

    return (this.outstandingBillingInvoiceDialogRef = this.dialog.open(
      OutstandingBillingInvoiceComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open connection lost popup
  openConnectionLost() {
    this.connectionLosteDialogRef = this.dialog.open(ConnectionLostComponent, {
      disableClose: true,
    });
  }

  // Close connection lost popup
  closeConnectionLost() {
    this.connectionLosteDialogRef.close();
  }

  // Open confirm disable platform popup
  openConfirmDisablePlatformPopup() {
    this._mInProgress = true;
    const alert: DialogModelConfirmDisablePlatform =
      new DialogModelConfirmDisablePlatform();

    return (this.confirmDisablePlatformDialogRef = this.dialog.open(
      ConfirmDisablePlatformComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open cancel single SO confirm
  openCancelSingleSOConfirmPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelConfirmSingleSOCancel =
      new DialogModelConfirmSingleSOCancel(response);

    return (this.cancelSingleSOConfirmDialogRef = this.dialog.open(
      ConfirmSingleSoCancelComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open cancel multiple SO confirm
  openCancelMultipleSOConfirmPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelConfirmMultipleSOCancel =
      new DialogModelConfirmMultipleSOCancel(response);

    return (this.cancelMultipleSOConfirmDialogRef = this.dialog.open(
      ConfirmMultipleSoCancelComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open confirm retrieve
  openRetrieveConfirm(response) {
    this._mInProgress = true;
    const alert: DialogModelConfirmRetrieve = new DialogModelConfirmRetrieve(
      response
    );

    return (this.confirmRetrieveDialogRef = this.dialog.open(
      ConfirmRetrieveComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open generate PO
  openGeneratePO() {
    this._mInProgress = true;
    const alert: DialogModelGeneratePO = new DialogModelGeneratePO();

    return (this.generatePODialogRef = this.dialog.open(GeneratePoComponent, {
      panelClass: "custom-dialog-container-full",
      data: alert,
      disableClose: true,
    }));
  }

  // Open delete medicine confirm
  openConfirmDeleteMedPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelConfirmDeleteMed = new DialogModelConfirmDeleteMed(
      response
    );

    return (this.deleteMedicineDialogRef = this.dialog.open(
      ConfirmMedicineDeleteComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open common warning
  openPrintBillWarningPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelPrintBillWarning = new DialogModelPrintBillWarning(
      response
    );

    return (this.commonWarningDialogRef = this.dialog.open(
      PrintBillWarningComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open comon confirm confirm
  openCommonConfirmPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelCommonConfirm = new DialogModelCommonConfirm(
      response
    );

    return (this.commonConfirmDialogRef = this.dialog.open(
      CommonConfirmComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open customize confirm confirm
  openCustomConfirmPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelCustomizeConfirm = new DialogModelCustomizeConfirm(
      response
    );

    return (this.customizeConfirmDialogRef = this.dialog.open(
      CustomizeConfirmComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open delete medicine confirm
  openApproveConfirm(response) {
    this._mInProgress = true;
    const alert: DialogApproveConfirm = new DialogApproveConfirm(response);

    return (this.DialogApproveConfirm = this.dialog.open(
      MappingApproveConfirmComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open delete medicine confirm
  openConfirmReasonPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelConfirmReason = new DialogModelConfirmReason(
      response
    );

    return (this.confirmReasonDialogRef = this.dialog.open(
      ConfirmReasonComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // open adn upload errors
  openAdnUploadErrors(response) {
    this._mInProgress = true;
    const alert: DialogModelADN = new DialogModelADN(response);

    return (this.adnUploadDialogRef = this.dialog.open(
      AdnUploadErrorsComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // open adn upload csv
  openAdnUploadCsv(response) {
    this._mInProgress = true;
    const alert: DialogModelAdnUpload = new DialogModelAdnUpload(response);

    return (this.adnUploadCsvDialogRef = this.dialog.open(
      AdnUploadCsvComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // open live si order details

  openLiveSiOrders(response) {
    this._mInProgress = true;
    const alert: DialogModelLiveSI = new DialogModelLiveSI(response);

    return (this.liveSiOrdersDialogRef = this.dialog.open(
      LiveSiOrderComponent,
      {
        maxWidth: "90vw",
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // open add-medicine dialog for live si order details

  openAddMedicine(response) {
    this._mInProgress = true;
    const alert: DialogModelAddMed = new DialogModelAddMed(response);

    return (this.addMedicineDialogRef = this.dialog.open(
      AddMedicineSiComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // open past si order details
  openPastSiOrders(response) {
    this._mInProgress = true;
    const alert: DialogModelPastSI = new DialogModelPastSI(response);

    return (this.pastSiOrdersDialogRef = this.dialog.open(
      PastSiOrderComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // open add new medicine dialog for mapping
  openAddNewMedToMap(response) {
    this._mInProgress = true;
    const alert: DialogModelNewMed = new DialogModelNewMed(response);

    return (this.addNewMedToMap = this.dialog.open(
      AddNewMedicineToMapComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open adn map confirm
  openAdnMapConfirmPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelAdnMapConfirm = new DialogModelAdnMapConfirm(
      response
    );

    return (this.AdnMapDialogRef = this.dialog.open(AdnMapConfirmComponent, {
      panelClass: "custom-dialog-container",
      data: alert,
      disableClose: true,
    }));
  }

  // open upload credit error
  openUploadCreditErrors(errorList, message) {
    this._mInProgress = true;
    const alert: DialogModelUploadCreditError =
      new DialogModelUploadCreditError(errorList, message);

    return (this.uploadCreditDialogRef = this.dialog.open(
      UploadCreditErrorComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open warehouse generic confirm confirm
  openConfirmPopup(message, acceptButtonName, denyButtonName) {
    this._mInProgress = true;
    const alert: DialogModelConfirmCheck = new DialogModelConfirmCheck(
      message,
      acceptButtonName,
      denyButtonName
    );
    return (this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
      panelClass: "custom-dialog-container",
      data: alert,
      disableClose: true,
    }));
  }

  // open add new composition dialog for mapping
  openAddNewComposition(response) {
    this._mInProgress = true;
    const alert: DialogModelNewComposition = new DialogModelNewComposition(
      response
    );

    return (this.addNewComposition = this.dialog.open(
      AddNewCompositionComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // open add new manufacture dialog for mapping
  openAddNewManufacture(response) {
    this._mInProgress = true;
    const alert: DialogModelNewManufacture = new DialogModelNewManufacture(
      response
    );

    return (this.addNewManufacture = this.dialog.open(
      AddNewManufactureComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // open add new disease dialog for mapping
  openAddNewDisease(response) {
    this._mInProgress = true;
    const alert: DialogModelNewDisease = new DialogModelNewDisease(response);

    return (this.addNewDisease = this.dialog.open(AddNewDiseaseComponent, {
      panelClass: "custom-dialog-container",
      data: alert,
      disableClose: true,
    }));
  }

  // Open warehouse generic confirm confirm
  openWarehouseGenericConfirmPopup(message, acceptButtonName, denyButtonName) {
    this._mInProgress = true;
    const alert: DialogModelWarehouseGenericConfirm =
      new DialogModelWarehouseGenericConfirm(
        message,
        acceptButtonName,
        denyButtonName
      );

    return (this.warehouseGenericConfirmDialogRef = this.dialog.open(
      WarehouseGenericConfirmComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open create ticket on help desk popup
  openCreateTicket() {
    this._mInProgress = true;
    const alert: DialogModelCreateTicket = new DialogModelCreateTicket(null);

    return (this.createTicketDialogRef = this.dialog.open(
      CreateTicketComponent,
      {
        panelClass: "custom-dialog-container-full",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open ticket instructions popup
  openTicketInstructions(instructions) {
    this._mInProgress = true;
    const alert: DialogModelTicketInstructions =
      new DialogModelTicketInstructions(instructions);

    return (this.ticketInstructionDialogRef = this.dialog.open(
      TicketInstrutionsComponent,
      {
        panelClass: "custom-dialog-container-full",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open create help executive popup
  openCreateHelpExecutive() {
    this._mInProgress = true;
    const alert: DialogModelCreateHelpExecutive =
      new DialogModelCreateHelpExecutive();

    return (this.createHelpExecutiveDialogRef = this.dialog.open(
      CreateHelpExecutiveComponent,
      {
        panelClass: "custom-dialog-container-full",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open multiple images popup
  openMultipleImagesPopup(response) {
    this._mInProgress = true;
    const alert: DialogModelMultipleImages = new DialogModelMultipleImages(
      response
    );

    return (this.multipleImagesDialogRef = this.dialog.open(
      MultipleImagesComponent,
      {
        data: alert,
        disableClose: true,
      }
    ));
  }

  // open upload credit error
  openUploadCreditBillErrors(errorList, message) {
    this._mInProgress = true;
    const alert: DialogModelUploadCreditBillError =
      new DialogModelUploadCreditBillError(errorList, message);

    return (this.uploadCreditBillDialogRef = this.dialog.open(
      UploadCreditBillErrorComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open decision room open items slot filter
  openDROpenItemsSlotFilter() {
    this._mInProgress = true;
    const alert: DialogModelDROpenItemsSlotFilter =
      new DialogModelDROpenItemsSlotFilter(null);

    return (this.drOpenItemsSlotFilterDialogRef = this.dialog.open(
      DrOpenItemsSlotFilterComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // open assign expiry
  openAssignExpiry() {
    this._mInProgress = true;

    return (this.assignExpiryDialogRef = this.dialog.open(
      AssignExpiryComponent,
      {
        panelClass: "custom-dialog-container-full",
        data: null,
        disableClose: true,
      }
    ));
  }

  // open ca collection
  openCaCollection(invoice) {
    this._mInProgress = true;
    const alert: DialogModelCaCollection = new DialogModelCaCollection(invoice);

    return (this.caCollectionDialogRef = this.dialog.open(
      CaCollectionComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
        autoFocus: false,
      }
    ));
  }

  // open send expiry items for dn
  openSendExpiryItemsForDn(item) {
    this._mInProgress = true;
    const alert: DialogModelSendExpiryItemToDn =
      new DialogModelSendExpiryItemToDn(item);

    return (this.sendExpiryItemsForDnDialogRef = this.dialog.open(
      SendExpiryItmesToDnComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open decision room open items spoke filter
  openDROpenItemsSpokeFilter() {
    this._mInProgress = true;
    const alert: DialogModelDROpenItemsSpokeFilter =
      new DialogModelDROpenItemsSpokeFilter(null);

    return (this.drOpenItemsSpokeFilterDialogRef = this.dialog.open(
      DrOpenItemsSpokeFilterComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // open upload credit error
  openCollectSignedSlip(data) {
    this._mInProgress = true;
    const alert: DialogModelCollectSignedSlip =
      new DialogModelCollectSignedSlip(data);

    return (this.collectSignedSlipDialogRef = this.dialog.open(
      CollectSignedSlipComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open add open medicines warehouse
  openAddOpenMedicineWarehouse(data) {
    this._mInProgress = true;
    const alert: DialogModelAddOpenMedicineWarehouse =
      new DialogModelAddOpenMedicineWarehouse(data);

    return (this.addOpenMedicineWarehouseDialogRef = this.dialog.open(
      AddOpenMedicineWarehouseComponent,
      {
        panelClass: "tb-width--full",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open add pick items popup
  openAddPickItem(data) {
    this._mInProgress = true;
    const alert: DialogModelAddPickItem = new DialogModelAddPickItem(data);

    return (this.addPickItemDialogRef = this.dialog.open(AddPickItemComponent, {
      panelClass: "tb-width--full",
      data: alert,
      disableClose: true,
    }));
  }

  // Open add banner popup
  openAddBanner(data) {
    this._mInProgress = true;
    const alert: DialogModelAddBanner = new DialogModelAddBanner(data);

    return (this.addBannerDialogRef = this.dialog.open(AddBannerComponent, {
      panelClass: "custom-dialog-container-full",
      data: alert,
      disableClose: true,
    }));
  }

  // Open add pick items popup
  // Open collect acknowledgement slip popup
  openCollectAcknowledgementSlip(data) {
    this._mInProgress = true;
    const alert: DialogModelCollectAcknowledgementSlip =
      new DialogModelCollectAcknowledgementSlip(data);

    return (this.collectAcknowledgementSlipDialogRef = this.dialog.open(
      CollectAcknowledgementSlipComponent,
      {
        panelClass: "tb-width--full",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open add items filter popup
  openAddItemsFilter(data, currParams) {
    this._mInProgress = true;
    const alert: DialogModelAddItemsFilter = new DialogModelAddItemsFilter(
      data,
      currParams
    );

    return (this.addItemsFilterDialogRef = this.dialog.open(
      AddItemsFilterComponent,
      {
        panelClass: "custom-dialog-container-full",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open finance invoice details popup
  openFinanceInvoiceDetails(data) {
    this._mInProgress = true;
    const alert: FinanceInvoiceDialogModel = new FinanceInvoiceDialogModel(
      data
    );

    return (this.financeInvoiceDetailsDialogRef = this.dialog.open(
      FinanceInvoiceDetailsComponent,
      {
        panelClass: "custom-dialog-container-full",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open generate RDCN popup
  openGenerateRDCN(data) {
    this._mInProgress = true;
    const alert: DialogModelGenerateRDCN = new DialogModelGenerateRDCN(data);

    return (this.generateRDCNDialogRef = this.dialog.open(
      GenerateRdcnComponent,
      {
        panelClass: "custom-dialog-container-full",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open finance distirbutor details popup
  openFinanceDistributorDetails(data) {
    this._mInProgress = true;
    const alert: FinanceDistributorDialogModel =
      new FinanceDistributorDialogModel(data);

    return (this.financeDistirbutorDetailsDialogRef = this.dialog.open(
      FinanceDistributorDetailsComponent,
      {
        panelClass: "custom-dialog-container-full",
        data: alert,
        disableClose: true,
      }
    ));
  }

  openCheckManualBill(data, body) {
    this._mInProgress = true;
    const alert: DialogModelCheckManualBill = new DialogModelCheckManualBill(
      data,
      body
    );

    return (this.checkManualBillDialogRef = this.dialog.open(
      CheckManualBillComponent,
      {
        panelClass: "custom-dialog-container-full",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open finance dispute popup
  openFinanceDispute(
    id,
    disputedByOps,
    disputedByFinance,
    disputedAmount,
    disableUndo,
    remarks
  ) {
    this._mInProgress = true;
    const alert: FinanceDisputeDialogModel = new FinanceDisputeDialogModel(
      disputedByOps,
      disputedByFinance,
      disputedAmount,
      id,
      disableUndo,
      remarks
    );

    return (this.financeDisputeDialogRef = this.dialog.open(
      FinanceDisputeComponent,
      {
        panelClass: "custom-dialog-container-full",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open finance invoice list popup
  openFinanceInvoiceList(debitNote) {
    this._mInProgress = true;
    const alert: FinanceInvoiceListDialogModel =
      new FinanceInvoiceListDialogModel(debitNote);

    return (this.financeInvoiceListDialogRef = this.dialog.open(
      FinanceInvoiceListComponent,
      {
        panelClass: "custom-dialog-container-full",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open finance dn list popup
  openFinanceDn(debitNotes, cnValue) {
    this._mInProgress = true;
    const alert: FinanceDNDialogModel = new FinanceDNDialogModel(
      debitNotes,
      cnValue
    );

    return (this.financeDNDialogRef = this.dialog.open(FinanceDnComponent, {
      panelClass: "custom-dialog-container-full",
      data: alert,
      disableClose: true,
    }));
  }

  // Open finance dn list popup
  openFinanceInvoiceAdjust(adjustedPIIds) {
    this._mInProgress = true;
    const alert: FinanceInvoiceAdjustDialogModel =
      new FinanceInvoiceAdjustDialogModel(adjustedPIIds);

    return (this.financeInvoiceAdjusrDialogRef = this.dialog.open(
      FinanceInvoiceAdjustComponent,
      {
        panelClass: "custom-dialog-container-full",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open finance payment history
  openFinancePaymentHistory(distributorId) {
    this._mInProgress = true;
    const alert: FinancePaymentHistoryDialogModel =
      new FinancePaymentHistoryDialogModel(distributorId);

    return (this.financePaymentHistoryDialogRef = this.dialog.open(
      FinancePaymentHistoryComponent,
      {
        panelClass: "custom-dialog-container-full",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open finance cn generated
  openFinanceCnGenerated(invoices) {
    this._mInProgress = true;
    const alert: FinanceCnGeneratedDialogModel =
      new FinanceCnGeneratedDialogModel(invoices);

    return (this.financeCnGeneratedDialogRef = this.dialog.open(
      FinanceCnGeneratedComponent,
      {
        panelClass: "custom-dialog-container-full",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Open Found Quantity
  openFoundQuantity(adnId, order, currDataType) {
    this._mInProgress = true;
    const alert: FoundQuantityDialogModel = new FoundQuantityDialogModel(
      adnId,
      order,
      currDataType
    );

    return (this.foundQuantityDialogRef = this.dialog.open(
      FoundQuantityComponent,
      {
        panelClass: "custom-dialog-container-full",
        data: alert,
        disableClose: true,
      }
    ));
  }

  // Revert CN
  openRevertCN(cnNumber) {
    this._mInProgress = true;
    const alert: DialogRevertCnModel = new DialogRevertCnModel(cnNumber);

    return (this.revertCnDialogRef = this.dialog.open(RevertCNComponent, {
      panelClass: "custom-dialog-container-full",
      data: alert,
      disableClose: true,
    }));
  }

  // view pincode mapping
  openViewPincodeMapping(data) {
    this._mInProgress = true;
    const alert: DialogViewPincodeMappingModel =
      new DialogViewPincodeMappingModel(data);

    return (this.viewPincodeMappingDialogRef = this.dialog.open(
      ViewPincodeMappingComponent,
      {
        maxWidth: "35vw",
        minHeight: "40vh",
        panelClass: "custom-dialog-container-full",
        data: alert,
        autoFocus: false,
      }
    ));
  }

  // Open wmm popup
  openReverifyPopup(response) {
    this._mInProgress = true;
    const alert: DialogReverify = new DialogReverify(response);

    return (this.reverifyDialogRef = this.dialog.open(ReverifyComponent, {
      data: alert,
      disableClose: true,
    }));
  }

  openLogisticsCoinsEdit(response) {
    this._mInProgress = true;
    const alert: DialogModelLogisticsCoinsEdit =
      new DialogModelLogisticsCoinsEdit(response);

    return (this.logisticsCoinsEditDialogRef = this.dialog.open(
      LogisticsCoinsEditComponent,
      {
        maxWidth:"90vw",
        data: alert,
        disableClose: false,
      }
    ));
  }

  openLogisticsSessions(response) {
    this._mInProgress = true;
    const alert: DialogModelLogisticsSessions =
      new DialogModelLogisticsSessions(response);

    return (this.logisticsSessionsDialogRef = this.dialog.open(
      LogisticsSessionsProceedComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: false,
      }
    ));
  }

  openMapAdjustment(response) {
    this._mInProgress = true;
    const alert: DialogMapAdjustment = new DialogMapAdjustment(response);

    return (this.mapAdjustmentDialogRef = this.dialog.open(
      MapAdjustmentComponent,
      {
        panelClass: "custom-dialog-container",
        data: alert,
        disableClose: true,
      }
    ));
  }

  confirmSession(response) {
    this._mInProgress = true;
    const alert: DialogModelConfirmSession =
      new DialogModelConfirmSession(response);

    return (this.confirmSessionDialogRef = this.dialog.open(
      CreateSessionConfirmComponent,
      {
        data: alert,
        disableClose: true,
      }
    ));
  }

  openOutstandingModal(response) {
    this._mInProgress = true;
    const alert: DialogModelOutstandingPopUp =
      new DialogModelOutstandingPopUp(response);

    return (this.openOutstandingDialogRef = this.dialog.open(
      OutstandingPopupComponent,
      {
        data: alert,
        disableClose: false,
      }
    ));
  }

   // Open view bill popup
   openViewBill(response) {
    this._mInProgress = true;
    const alert: DialogModelViewBill = new DialogModelViewBill(response);

    return (this.viewBillDialogRef = this.dialog.open(ViewBillComponent, {
      data: alert,
      disableClose: false,
    }));
  }
}
