import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/common/api.service';
import { MixpanelService } from './mixpanel.service';

@Injectable({
  providedIn: "root",
})
export class EventService {
  API_URL = environment.BASE_URL + "/home/app_events";
  enabled=JSON.parse(localStorage.getItem("userData"))?.user?.enabled
  userID=JSON.parse(localStorage.getItem("userData"))?.user?.id
  phoneNumber=JSON.parse(localStorage.getItem("userData"))?.user?.mobileNo
  roles=JSON.parse(localStorage.getItem("userData"))?.user?.roles

  eventBody={
    eventProperties: {
      userId: this.userID,
      phoneNumber: this.phoneNumber,
      enabled: this.enabled,
      load_time:0,
      request:null,
      response:null,
      roles:this.roles
    },
    eventName: '',
    timeStamp:null,
    userId:  this.userID,
    mobileNo: this.phoneNumber,
    source: "dashboardsWeb",
    version:environment.version
  }

  // mixPanelBody= {
  //   deviceId: "",
  //   eventName: "",
  //   load_time:'',
  //   request:'',
  //   enabled:this.enabled,
  //   mobileNo:this.phoneNumber,
  //   source:"dashboardsWeb",
  //   userId: this.userID,
  //   timeStamp:null,
  //   roles:this.roles,
  //   version: environment.version,
  // };


  constructor(private apiService: ApiService,private mixpanelService:MixpanelService) { 

  }

 
   apiSuccessTime(data){
    const urlPath = this.API_URL;
    let body=JSON.parse(JSON.stringify(this.eventBody))
    body.eventProperties.load_time=data?.load_time
    body.eventProperties['url']=data?.url
    body.eventProperties.request=data?.request
    body.eventName=data?.eventName
    body.timeStamp=data?.timeStamp

    // let mixPanelData=JSON.parse(JSON.stringify(this.mixPanelBody))
    // mixPanelData.eventName=data?.eventName
    // mixPanelData.load_time=data?.load_time
    // mixPanelData['url']=data?.url
    // mixPanelData.request=data?.request
    // mixPanelData.timeStamp=data?.timeStamp

    // this.mixpanelService.track(data?.eventName,mixPanelData)
    // return this.apiService.doPostObservableForQuery(urlPath, body, {}).subscribe(
    //   response => {}
    // );
    return true
   }
 //  global method to track events across the web app
   trackEvent(eventName: string, data) {
    const urlPath = this.API_URL;
    // this.mixPanelBody.timeStamp=new Date().getTime()
    // let mxBody = { ...this.mixPanelBody, ...data };
    // mxBody.eventName=eventName
    let eventBody=this.eventBody
        eventBody.eventName=eventName;
        eventBody.timeStamp=new Date().getTime()
        eventBody.eventProperties={...eventBody.eventProperties,...data}
    // this.mixpanelService.track(eventName, mxBody);
    // return this.apiService.doPostObservableForQuery(urlPath, eventBody, {}).subscribe(
    //   response => {}
    // );
    return true
  }

 
}
