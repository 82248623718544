import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from "src/app/material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatIconModule, MatRippleModule } from "@angular/material";
import { CommonFiltersComponent } from './common-filters.component';

@NgModule({
  declarations: [CommonFiltersComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    MatIconModule,
    MatRippleModule,
  ],
  exports: [CommonFiltersComponent]
})
export class CommonFiltersModule {}
