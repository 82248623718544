import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { CommonFiltersComponent } from "../common-filters/common-filters.component";
@Component({
  selector: "app-common-filter-icons",
  templateUrl: "./common-filter-icons.component.html",
  styleUrls: ["./common-filter-icons.component.scss"],
})
export class CommonFilterIconsComponent implements OnInit {
  header = {
    value: "",
    filter: false,
    key: "",
    width: "",
    sorting: { active: false, standAlone: false },
  };
  filters = {};
  sort = false;
  defaultSort = true;

  constructor() {}

  @ViewChild(CommonFiltersComponent) commonFilters: CommonFiltersComponent;

  @Input("header") set val(value: any) {
    if (value) {
      this.header = value;
    }
  }

  @Output("filterClick") filterClick: EventEmitter<any> = new EventEmitter<any>();
  @Output("sortClick") sortClick: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    // this.filters[this.header.key] = {
    //   uniqueValues: [],
    //   values: [],
    //   key: this.header.key,
    //   active: false,
    //   show: false,
    //   searchString: "",
    //   sorting: {
    //     active: false,
    //     default: true,
    //     show: this.header?.sorting?.active || false,
    //     standAlone: this.header?.sorting?.standAlone || false,
    //   },
    // };
  }

  // Accessing the selectToggle option from the parent component
  selectFilterToggle(key, filter): void {
    this.filterClick.emit({
      key,
      filter,
    });
  }

  sortFilterToggle(key, filter): void {
    this.toggleSort();
    
    this.sortClick.emit({
      key,
      filter,
    });
  }

  // Toggling the sort options
  toggleSort() {
    if(this.defaultSort) {
      this.defaultSort = false;
      this.sort = !this.sort;
    } else {
      this.sort = !this.sort;
    }
  }
}
