import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogModelGenerateRDCN } from './model';
import { LogisticsService } from "src/app/core/services/api/logistics.service";
import { PopupService } from "src/app/core/services/popup/popup.service";

@Component({
  selector: 'app-generate-rdcn',
  templateUrl: './generate-rdcn.component.html',
  styleUrls: ['./generate-rdcn.component.scss']
})
export class GenerateRdcnComponent implements OnInit {
  items = [];
  selectAll = false;

  constructor(
    private logisticsService: LogisticsService,
    private popupService: PopupService,
    public dialogRef: MatDialogRef<GenerateRdcnComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelGenerateRDCN) { }

  ngOnInit(): void {
    this.popupService.openProgress();
    this.getItems();
  }

  getItems() {
    this.logisticsService.getRDCNInvoiceItems({invoiceNumber: this.data.invoiceNumber}).subscribe(
      response => {
        this.items = response.data;
        this.selectAll = false;
        this.items.map(item => {
          item.differenceAmount = 0;
          item.remarks = "";
          item.selected = false;
        })
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  toggleSelectAll() {
    this.items.map(item => {
      item.selected = this.selectAll;
    })
  }

  checkDeficitVal(item){
       if(item.differenceAmount>item.taxableValue){
        this.popupService.openError('Difference amount shouldnt be greater than net value')
       }
  }

  generate() {


    this.popupService.openProgress();
    let errorItems=[];
    const body = {
      buyerInvoiceNumber: this.data.invoiceNumber,
      rdcnItemRequestList: [
      ]
    }
    this.items.map(item => {
      if (this.selectAll || item.selected) {
        const itemObj = {
          batchId: item.batchId,
          cgst: item.cgst,
          differenceAmount: item.differenceAmount,
          distributorId: item.distributorId,
          expiryDate: item.expiryDate,
          hsnCode: item.hsnCode,
          igst: item.igst,
          itemCode: item.itemCode,
          itemName: item.itemName,
          manufacturerName: item.manufacturerName,
          mrp: item.mrp,
          netValue: item.netValue,
          packaging: item.packaging,
          productDiscount: item.productDiscount,
          ptr: item.ptr,
          quantity: item.quantity,
          remarks: item.remarks,
          schemeDiscount: item.schemeDiscount,
          sgst: item.sgst,
          tax: item.tax,
          taxAmount: item.taxAmount,
          taxableValue: item.taxableValue,
          totalDiscountAmount: item.totalDiscountAmount,
          uniqueCode: item.uniqueCode,
          unitPrice: item.unitPrice
        }
       
        if(item.differenceAmount>item.taxableValue){
            errorItems.push(item.itemName)
         }
        
        body.rdcnItemRequestList.push(itemObj);
      }
    })
    if(!errorItems.length){
      if (body.rdcnItemRequestList.length > 0) {
        this.logisticsService.generateRDCN({}, body).subscribe(
          response => {
            this.popupService.closeProgress();
            this.popupService.openSuccess(response.data);
            this.close();
          },
          error => {
            this.popupService.closeProgress();
            this.popupService.openError(error.error.error.error);
          }
        )
      } else {
        this.popupService.closeProgress();
        this.popupService.openError("No Item Selected.");
      }
    }else{
      this.popupService.closeProgress();
       this.popupService.openError(`Difference amount is greater than net value for items ${errorItems}`)
    }
   
  }

  close() {
    this.dialogRef.close();
  }

}
