import { UserModel } from 'src/app/core/services/params/user.model';
import { Injectable, Inject } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiService } from "../../../common/api.service";
import { map } from 'rxjs/operators';
import { AppDataService } from 'src/app/app-data/app-data.service';
import { POSTMAN, Postman } from '../postman';
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { LayoutDirective } from '@angular/flex-layout';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  API_URL: string = environment.BASE_URL + "/auth";
  gatewayUrl = environment.BASE_URL + "/authenticate"
  timer;
  refreshTokenProgress=false;
  constructor(
    @Inject(POSTMAN) public postman: Postman,
    private apiService: ApiService,
    public afAuth: AngularFireAuth,
    private appDataService: AppDataService,
    private http: HttpClient,
    private router: Router
  ) {}

  getUserId(): string {
    return this.afAuth.auth.currentUser.uid;
  }

  checkAuth(number: number): Observable<any> {
    const data = {
      mobile: { mobileNumber: number }
    };
    let urlPath = environment.adminUrl + "/auth/checkAuth";
    // let urlPath = this.postman.auth().checkAuth;
    return this.apiService
      .doPostObservable(urlPath, data.mobile)
      // .pipe(map(response => {response.data}));
  }

  logout(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.afAuth.auth.signOut().then(
        () => {
          localStorage.clear();
          observer.next(true);
          observer.complete();
        },
        error => {
          observer.next(false);
          observer.complete();
        }
      );
    });
  }

  createProfile(formData: any): Observable<any> {
    // console.log(formData);
    let urlPath = this.API_URL + "/user";
    return this.apiService.doPostObservable(urlPath, formData);
  }

  updateProfile(formData: any): Observable<any> {
    let urlPath = this.API_URL + "/user/update";
    return this.apiService.doPostObservable(urlPath, formData);
  }

  updateProfileV2(formData: any): Observable<any> {
    let urlPath = this.API_URL + "/v2/user/update";
    return this.apiService.doPostObservable(urlPath, formData);
  }

  getProfileData(): Observable<any> {
    let urlPath = this.API_URL + "/user";
    return this.apiService.doGetObservable(urlPath);
    // .pipe(map(response => console.log(response)));
  }

  getUserPhoneNumber(): Observable<string> {
    return new Observable<string>(observer => {
      const user = JSON.parse(localStorage.getItem("userData"));
      // console.log(user)
            if(user){
              observer.next(user.user.mobileNo);
              observer.complete();
            }else{
              console.log("Token Expired or User Data not Avaliable");
              this.appDataService.clear();
              this.logout();
            }

    });
  }

  hasAuthData() {
    const authData = this.getAuthData();
    // console.log(authData);
    if (authData == null) {
      return false;
    }
    return true;
  }

  getAuthData() {
    const auth = JSON.parse(localStorage.getItem("authToken"))
    if (auth && auth.jwtToken) {
      return auth;
    }
    return null;
  }

  sendOTP(params, body): Observable<any> {
    const urlPath = environment.BASE_URL + "/authenticate/sendOtp"
    const gatewayUrl = this.gatewayUrl + "/sendOtp";
    return this.apiService.doPostObservableForQuery(gatewayUrl, body, params)
  }

  verifyOTP(params, body): Observable<any> {
    const urlPath = environment.BASE_URL + "/authenticate/verifyOtp"
    const gatewayUrl = this.gatewayUrl + "/verifyOtp";
    return this.apiService.doPostObservableForQuery(gatewayUrl, body, params)
  }

  verfiyPassword(params, body): Observable<any> {
    const urlPath = environment.BASE_URL + "/authenticate/verifyPassword"
    const gatewayUrl = this.gatewayUrl + "/verifyPassword";
    return this.apiService.doPostObservableForQuery(gatewayUrl, body, params)
  }

  createPassword(params, body): Observable<any> {
    const auth = JSON.parse(localStorage.getItem("authToken"))
    const authToken = "Bearer " + auth.jwtToken;
    let createPasswordHeaders = new HttpHeaders().set("Content-Type", "application/json").set("source", "web").set("Authorization", authToken);
    const urlPath = environment.BASE_URL + "/authenticate/addUserCredentials"
    const gatewayUrl = this.gatewayUrl + "/addUserCredentials";
    return this.http.post(gatewayUrl, {}, { headers: createPasswordHeaders, params})
  }

  getAccessToken(): Observable<string> {
    // console.log('getAccessToken ++++++++++: ');
    /* return new Observable<string>(observer => {
      this.apiService.doPostObservableForQuery("http://api-gateway-loadbalancer-cb1c6404ccb74f66.elb.ap-south-1.amazonaws.com/authenticate/sendOtp", {}, {mobileNo: '8569972856'}).subscribe(
        response => {
          console.log(response)
        },
        error => {
          console.log(error.error);
        }
      )
     }
     */
    
    return new Observable<string>(observer => {
      const auth = JSON.parse(localStorage.getItem("authToken"))
      if (auth && auth.jwtToken) {
        observer.next(auth.jwtToken);
        observer.complete();
      } else {
        observer.error("error");
        localStorage.clear();
        observer.complete();
      }
      /*this.afAuth.auth.currentUser.getIdToken().then(
        (value: string) => {
          const auth = JSON.parse(localStorage.get("authToken"))

          // console.log('Token: ', value);
          observer.next(auth.jwtToken);
          observer.complete();
        },
        error => {
          observer.error(error);
          observer.complete();
        }
      );*/
    });
    
  }

  refreshAccessToken() {

    if(!this.refreshTokenProgress){
      this.getRefreshToken().subscribe(
        response => {
          const refreshAuthData = response.data;
          let auth = JSON.parse(localStorage.getItem("authToken"))
          auth.jwtToken = refreshAuthData.jwtToken;
          localStorage.setItem("authToken", JSON.stringify(auth));
          // this.router.navigateByUrl('/shop');
          this.refreshTokenProgress=false
        },
        error => {
          this.refreshTokenProgress=false
          this.router.navigateByUrl("/authenticate");
        }
      )
    }  
    
  }

  getNewAccessToken(): Observable<any> {
    return new Observable<string>(observer => {
      if(!this.refreshTokenProgress){
        this.getRefreshToken().subscribe(
          response => {
            const refreshAuthData = response.data;
            let auth = JSON.parse(localStorage.getItem("authToken"))
            auth.jwtToken = refreshAuthData.jwtToken;
            localStorage.setItem("authToken", JSON.stringify(auth));
            const newAuth = JSON.parse(localStorage.getItem("authToken"))
            if (newAuth && newAuth.jwtToken) {
              observer.next(newAuth.jwtToken);
              observer.complete();
            } else {
              observer.error("error");
              localStorage.clear();
              observer.complete();
            }
            this.refreshTokenProgress=false
            this.router.navigateByUrl('/admin');
            
          },
          error => {
            this.refreshTokenProgress=false
            this.router.navigateByUrl("/authenticate");
            observer.error("error");
            localStorage.clear();
            observer.complete();
          }
        )
      }     
    });
  }

  getRefreshToken(): Observable<any> {
    this.refreshTokenProgress=true;
    const auth = JSON.parse(localStorage.getItem("authToken"))
    const authToken = "Bearer " + auth.jwtToken;
    let refreshHeaders = new HttpHeaders().set("Content-Type", "application/json").set("source", "web").set("Authorization", authToken);
    const params = {
      refreshToken: auth.refreshToken
    }
    const urlPath = environment.BASE_URL + "/authenticate/refreshToken"
    const gatewayUrl = this.gatewayUrl + "/refreshToken";
    return this.http.post(gatewayUrl, {}, { headers: refreshHeaders, params})
  }

  subscribeAccessToken(): Observable<string> {
    return new Observable<string>(observer => {
      const auth = JSON.parse(localStorage.getItem("authToken"))
      if (auth && auth.jwtToken) {
        observer.next(auth.jwtToken);
        observer.complete();
      } else {
        observer.error("error");
        observer.complete();
      }
      /*
      let subscription = this.afAuth.user.subscribe(user => {
        user.getIdToken().then(
          (value: string) => {
            observer.next(value);
            subscription.unsubscribe();
            observer.complete();
          },
          error => {
            console.log(error);
            observer.error(error);

            subscription.unsubscribe();
            observer.complete();
          }
        );
      });
      */
    });
  }

  subscribeUserData(number): Observable<any> {
    const data = {
      mobile: { mobileNumber: number }
    };
    let urlPath = environment.adminUrl + "/auth/checkAuth";
    // let urlPath = this.postman.auth().checkAuth;
    return this.apiService
        .doPostObservable(urlPath, data.mobile)
        // .pipe(
        //   map(user => {
        //     alert(user)
        //     observer.next(user.data);
        //     observer.complete();
        //   },
        //   error => {
        //     alert(error.error.error.error);
        //   }
        //   )
        // )
        // .subscribe();
    // });
  }

  getUsersRetailerCode(params): Observable<any> {
    const urlPath = this.API_URL + "/getRetailerCode";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

}
