import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { AdminService } from "src/app/core/services/api/admin.service";
import { PopupService } from "src/app/core/services/popup/popup.service";
import { DialogModelOutstandingPopUp } from "./model";
import { LogisticsService } from "src/app/core/services/api/logistics.service";

@Component({
  selector: "app-outstanding-popup",
  templateUrl: "./outstanding-popup.component.html",
  styleUrls: ["./outstanding-popup.component.scss"],
})
export class OutstandingPopupComponent implements OnInit {
  sectionSelected = true;
  showInvoices = false;
  outstandingData = [];
  invoiceData = [];
  deliveryExecutives = [];
  deSelected;
  totalDeficitAmount = 0;
  totalOutstandingAmount = 0;
  totalOutstanding = [
    {
      amount: null,
      reason: "DE",
      comment: "",
      osConfirmed: false,
    },
    {
      amount: this.totalDeficitAmount,
      reason: "BILLING",
      comment: "",
      osConfirmed: false,
      buyerInvoiceMedicineList: [],
      showInvoices: false,
      buyerInvoiceItemsHeaderList: [
        "Name",
        "Packaging",
        "Quantity",
        "Value",
        "Deficit Amount",
        "Remarks",
        "Confirm",
      ],
    },
  ];
  totalAmountReconciled = 0;

  constructor(
    private popupService: PopupService,
    private adminService: AdminService,
    private logisticsService: LogisticsService,
    public dialogRef: MatDialogRef<OutstandingPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelOutstandingPopUp
  ) {}

  ngOnInit(): void {
    this.viewOutstanding(this.data.osData.invoiceNumber);
  }

  viewOutstanding(invNo) {
    this.popupService.openProgress();

    const params = {
      invoiceNumber: invNo,
    };

    this.logisticsService.viewOutstanding(params).subscribe(
      (response) => {
        this.outstandingData = response.data;
        this.popupService.closeProgress();
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  toggleSection() {
    this.sectionSelected = !this.sectionSelected;

    if (!this.sectionSelected) {
      this.getInvoices();
    } else {
      this.viewOutstanding(this.data.osData.invoiceNumber);
    }
  }

  close() {
    this.dialogRef.close();
  }

  // Find DE
  getAllExecutives(event) {
    if (event.keyCode === 13) {
      this.popupService.openProgress();

      let params = {
        deName: event.target.value,
      };

      this.logisticsService.getAllDeliveryExecutives(params).subscribe(
        (response) => {
          this.deliveryExecutives = response.data;

          this.popupService.closeProgress();
        },
        (error) => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      );
    }
  }

  // Select DE
  selectDE(ind, deItem, item) {
    this.deSelected = deItem;
    item.deName = deItem.shopName;
    this.deliveryExecutives = [];
  }

  // handler to view invoices
  handleViewInvoices() {
    // de_collect_os_viewInvoice_click event
    let data = {
      osReason: this.totalOutstanding[1].reason,
      osComments: this.totalOutstanding[1].comment,
    };
    // this.triggerEvent("de_collect_os_viewInvoice_click", data);

    // if invoice table is already open, close the table and return
    if (this.totalOutstanding[1].showInvoices) {
      this.totalOutstanding[1].showInvoices = false;
      return;
    }
    // hit the api to get the data
    this.getInvoices();
  }

  // Get Invoices
  getInvoices() {
    this.popupService.openProgress();

    this.adminService
      .getInvoiceItems({
        invoiceNumber: this.data.osData.invoiceNumber,
      })
      .subscribe(
        (response) => {
          this.invoiceData = response.data;
          this.totalOutstanding[1].buyerInvoiceMedicineList = response.data;
          this.totalOutstanding[1].showInvoices = true;
          this.popupService.closeProgress();
        },
        (error) => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      );
  }

  // key up handler for the os amount input
  handleOSAmountKeyUp(event, osItem) {
    if (event.keyCode === 13) this.handleOsAmountChange(event, osItem);
    // reset the confirmed state
    osItem.osConfirmed = false;
    // check for the negative values
    if (event.target.value < 0) {
      event.target.value = Math.abs(event.target.value);
      osItem.amount = Math.abs(event.target.value);
    } else if (event.target.value === null) {
      event.target.value = 0;
      osItem.amount = 0;
    }

    // trigger the confirm button click on retrun key press
    // if (event.keyCode === 13) this.handleOSConfirmClick(osItem);
  }

  // handler to run side effects on os amount change
  handleOsAmountChange(event, osItem) {
    // de_collect_os_amount_entered event
    let data = {
      osAmount: osItem.amount,
      osReason: osItem.reason,
    };
    // his.triggerEvent("de_collect_os_amount_entered", data);

    if (
      event.target.value === null ||
      osItem.amount > this.data.osData.invoiceAmt
    ) {
      event.target.value = 0;
      osItem.amount = 0;
    }
    // on os amount change, recalculate the outstanding and reconciled amount and collect button enable
    this.checkCollectButtonEnable();
  }

  // check if we need to enable the final collect button
  checkCollectButtonEnable() {
    // update the o/s and recon amount
    this.calculateOutstandingAmount();
    // this.calculateReconciledAmount();

    // this.totalAmountReconciled.toFixed(2) ==
    // this.data.invoiceData.collectAmount.toFixed(2)
    //   ? (this.collectEnable = true)
    //   : (this.collectEnable = false);
  }

  // calculates outstanding amount
  calculateOutstandingAmount() {
    // let totalAmountConfirmed = 0;
    // let totalOs = 0;
    // this.totalOutstanding.forEach((os, _) => {
    //   totalOs += os.amount === null ? 0 : os.amount;
    // });
    // add the amount entered in outstanding
    // totalAmountConfirmed += totalOs;
    // calculate the total outstanding
    // this.totalOutstandingAmount =
    // this.invoiceData.collectAmount - totalAmountConfirmed;
  }

  // calculate reconciled amount
  // calculateReconciledAmount() {
  //   let totalAmountRecon = 0;
  //   // add the amount in the outstanding object
  //   // map throught the items of outstanding object and increase the totalAmountRecon
  //   this.totalOutstanding.forEach((item, _) => {
  //     if (item.amount > 0 && item.osConfirmed) {
  //       totalAmountRecon += item.amount;
  //     }
  //   });
  //   // update the reconciled amount
  //   this.totalAmountReconciled = totalAmountRecon;
  // }

  handleOsCommentChange(event, index) {
    // de_collect_os_comments_entered event
    let data = {
      osAmount: this.totalOutstanding[index].amount,
      osReason: this.totalOutstanding[index].reason,
      osComments: this.totalOutstanding[index].comment,
    };
    // this.triggerEvent("de_collect_os_comments_entered", data);
  }

  // Calculate total deficit amount
  calculateTotalDeficitAmount() {
    this.totalDeficitAmount = 0;
    this.totalOutstanding[1].buyerInvoiceMedicineList.map((medicine) => {
      // only add the amount in the confirmed state
      if (medicine.deficitAmount > medicine.netValue) {
        medicine.deficitAmount = 0;
      } else if (medicine?.confirmed) {
        this.totalDeficitAmount += medicine.deficitAmount;
        this.totalOutstanding[1].amount += medicine.deficitAmount;
      }
    });
  }

  // key up handler for the billing amount input field
  handleBillingAmountkeyUp(event, index) {
    if (
      this.totalOutstanding[1].buyerInvoiceMedicineList[index].deficitAmount >
      this.totalOutstanding[1].buyerInvoiceMedicineList[index].netValue
    ) {
      this.totalOutstanding[1].buyerInvoiceMedicineList[
        index
      ].deficitAmount = 0;
    } else {
      this.totalOutstanding[1].buyerInvoiceMedicineList[index].confirmed
        ? (this.totalOutstanding[1].buyerInvoiceMedicineList[index].confirmed =
            false)
        : undefined;
      // reset the total calculated amount
      this.totalOutstanding[1].amount = 0;
      // if return key is pressed, handle the confirm click
      if (event.keyCode === 13) this.handleBillingConfirmClick(index);
    }
  }

  // billing item confirm click
  handleBillingConfirmClick(index) {
    // de_collect_os_deficitAmount_confirm event
    let data = {
      invoiceNumber: this.data.osData.invoiceNumber,
      deficitAmount:
        this.totalOutstanding[1].buyerInvoiceMedicineList[index].deficitAmount,
      remarks: this.totalOutstanding[1].buyerInvoiceMedicineList[index].comment,
    };
    // this.triggerEvent("de_collect_os_deficitAmount_confirm", data);

    // update the billing item confirmed state
    this.totalOutstanding[1].buyerInvoiceMedicineList[index].confirmed = true;
    // calculate the total deficit amount
    this.calculateTotalDeficitAmount();
  }

  // handler to run side effects on the deficit amount change
  handleDeficitAmountChange(event, index) {
    // de_collect_os_deficitAmount_entered event
    let data = {
      invoiceNumber: this.data.osData.invoiceNumber,
      deficitAmount:
        this.totalOutstanding[1].buyerInvoiceMedicineList[index].deficitAmount,
      remarks: this.totalOutstanding[1].buyerInvoiceMedicineList[index].comment,
    };
    // this.triggerEvent("de_collect_os_deficitAmount_entered", data);
  }

  // Submit deficit amount for DE reason
  storeDEReason(body) {
    this.adminService.storeDEReasonSelection({}, body).subscribe(
      (response) => {
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
        // this.osSubmissionSuccessful = true;
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
        // this.osSubmissionSuccessful = false;
      }
    );
  }

  // Submit deficit amount for billing reason
  storeBillingReason(body) {
    this.popupService.openProgress();

    this.adminService.storeBillingReasonSelection({}, body).subscribe(
      (response) => {
        this.showInvoices = false;
        this.popupService.closeProgress();
        this.popupService.openSuccess(response.data);
      },
      (error) => {
        this.showInvoices = false;
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // handler to run side effects on os confirm button
  handleOSConfirmClick(osItem) {
    // de_collect_os_amount_confirm event
    let data = {
      osAmount: osItem.amount,
      osReason: osItem.reason,
      osComments: osItem.comment,
    };
    // this.triggerEvent("de_collect_os_amount_confirm", data);

    osItem.osConfirmed = true;
    // update outstanding and reconcilation amount and collect button enable
    this.checkCollectButtonEnable();

    this.popupService.openProgress();

    let body = {
      deficitReason: osItem.reason === "DE" ? "DELIVERY_EXECUTIVE" : "BILLING",
      source: "OUTSTANDING",
      invoiceNumber: this.data.osData.invoiceNumber,
      deficitItemDetailList: [],
    };

    if (osItem.reason === "DE") {
      body["deficitAmount"] = osItem.amount;
      body["comment"] = osItem.comment;
      body["deMobile"] = this.deSelected.mobileNo;
      body["deName"] = this.deSelected.shopName;
      body["deRetailerCode"] = this.deSelected.retailerCode;
      body.deficitItemDetailList = this.invoiceData;

      this.storeDEReason(body);
    } else if (osItem.reason === "BILLING") {
      body["deficitAmount"] = this.totalOutstanding[1].amount;
      body["comment"] = this.totalOutstanding[1].comment;
      this.totalOutstanding[1].buyerInvoiceMedicineList.map((medicine) => {
        if (medicine.deficitAmount > 0 && medicine?.confirmed) {
          body.deficitItemDetailList.push(medicine);
        }
      });

      this.storeBillingReason(body);
    }
  }
}
