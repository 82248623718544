import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from '../../../common/api.service';

@Injectable({
  providedIn: 'root'
})
export class HelpDeskService {
  currFilterDepartment = 'ALL';
  currFilterStatus = 'All';
  showMyTickets = false;
  headers
  retailerId;
  // API_URL: string = "http://authentication-service-lb-8f5b733373cb41a1.elb.ap-south-1.amazonaws.com/helpNSupport";
  API_URL: string = environment.helpDeskUrl + "/helpNSupport";

  constructor(private http: HttpClient, private apiService: ApiService) {
    this.retailerId = JSON.parse(localStorage.getItem("userData")).user.id;
    this.headers = new HttpHeaders().set("Content-Type", "application/json").set("source", "web").set("userId", this.retailerId);
  }

  getAllTickets(params): Observable<any> {
    const urlPath = this.API_URL + "/fetchTicketsPageable";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getMyTickets(params): Observable<any> {
    const urlPath = this.API_URL + "/fetchMyTicketsPageable";
    return this.http.get(urlPath, { headers: this.headers, params });
  }

  getMyMentionTickets(params): Observable<any> {
    const urlPath = this.API_URL + "/fetchCommentedTicketsPageable";
    return this.http.get(urlPath, { headers: this.headers, params });
  }

  getAllDepartments(): Observable<any> {
    const urlPath =  "https://gatewayprod.saveo.in/api/helpNSupport/fetchDepartment";
    return this.apiService.doGetObservable(urlPath);
  }
  
  getAllExecutives(): Observable<any> {
    const urlPath = this.API_URL + "/fetchExecutives";
    return this.apiService.doGetObservable(urlPath);
  }
  
  getAllPriority(): Observable<any> {
    const urlPath = this.API_URL + "/fetchPriority";
    return this.apiService.doGetObservable(urlPath);
  }

  getAllStatus(): Observable<any> {
    const urlPath = this.API_URL + "/fetchStatus";
    return this.apiService.doGetObservable(urlPath);
  }

  getAllIssues(): Observable<any> {
    const urlPath = this.API_URL + "/fetchIssue";
    return this.apiService.doGetObservable(urlPath);
  }
  
  getAllSubIssues(): Observable<any> {
    const urlPath = this.API_URL + "/fetchSubIssue";
    return this.apiService.doGetObservable(urlPath);
  }

  getTicketData(params): Observable<any> {
    const urlPath = this.API_URL + "/getTicketDetails";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateTicketDepartment(params): Observable<any> {
    const urlPath = this.API_URL + "/setTicketDepartment";
    return this.http.get(urlPath, { headers: this.headers, params });
  }

  updateTicketExecutive(params): Observable<any> {
    const urlPath = this.API_URL + "/setTicketAlloted";
    return this.http.get(urlPath, { headers: this.headers, params });
  }

  updateTicketStatus(params): Observable<any> {
    const urlPath = this.API_URL + "/setTicketStatus";
    return this.http.get(urlPath, { headers: this.headers, params });
  }

  updateTicketPriority(params): Observable<any> {
    const urlPath = this.API_URL + "/setTicketPriority";
    return this.http.get(urlPath, { headers: this.headers, params });
  }

  getTicketComments(params): Observable<any> {
    const urlPath = this.API_URL + "/getTicketComments";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  sendTicketComments(params, body): Observable<any> {
    const urlPath = this.API_URL + "/setCommentforTicket";
    return this.http.post(urlPath, body, { headers: this.headers, params });
  }

  getIssueCategories(): Observable<any> {
    const urlPath = this.API_URL + "/fetchTicketCategory";
    return this.apiService.doGetObservable(urlPath);
  }

  getIssues(params): Observable<any> {
    const urlPath = this.API_URL + "/fetchIssues";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getSubIssues(params): Observable<any> {
    const urlPath = this.API_URL + "/fetchSubIssues";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getModeOfCommunication(): Observable<any> {
    const urlPath = this.API_URL + "/fetchModeOfTicket";
    return this.apiService.doGetObservable(urlPath);
  }

  createTicket(params): Observable<any> {
    const urlPath = this.API_URL + "/createTicket";
    return this.http.post(urlPath, {}, { headers: this.headers, params });
  }

  searchUser(params): Observable<any> {
    const urlPath = environment.adminUrl + "/home/fetchUserData";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getTicketResolutionSteps(params): Observable<any> {
    const urlPath = this.API_URL + "/ticketResolution";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  createHelpExecutive(params): Observable<any> {
    const urlPath = this.API_URL + "/createHelpExecutive";
    return this.http.post(urlPath, {}, {headers: this.headers, params});
  }

  getTicketHistory(params): Observable<any> {
    const urlPath = this.API_URL + "/getTicketHistoryLog";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getTickets(params): Observable<any> {
    const urlPath = this.API_URL + "/fetchfilteredTickets/v1";
    return this.http.get(urlPath, {params: params, headers: this.headers});
  }

  getOrderIdSuggestions(params): Observable<any> {
    const urlPath = environment.adminUrl + "/home/fetchOrderDetails";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getInvoiceNumberSuggestions(params): Observable<any> {
    const urlPath = environment.adminUrl + "/home/fetchInvoiceDetail";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getRetailerDetails(params): Observable<any> {
    const urlPath = environment.adminUrl + "/home/fetchUserSupportData";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

 
  getRetCodes(params): Observable<any> {
    const urlPath = environment.adminUrl + "/admin/communityLeader/v2/getRetailerCodesOnBehalf";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getTicketsByOrderID(params): Observable<any> {
    const urlPath = this.API_URL + "/fetchfilteredTickets/orderId";
    return this.http.get(urlPath, {params: params, headers: this.headers});
  }
  getTicketsByNumber(params): Observable<any> {
    const urlPath = this.API_URL + "/fetchfilteredTickets/mobileNumber";
    return this.http.get(urlPath, {params: params, headers: this.headers});
  }

  getTicketsByRetCode(params): Observable<any> {
    const urlPath = this.API_URL + "/fetchfilteredTickets/retailerCode";
    return this.http.get(urlPath, {params: params, headers: this.headers});
  }

  getTicketsByTicketId(params): Observable<any> {
    const urlPath = this.API_URL + "/fetchfilteredTickets/ticketId";
    return this.http.get(urlPath, {params: params, headers: this.headers});
  }

  getTicketsByDate(params): Observable<any> {
    const urlPath = this.API_URL + "/fetchfilteredTickets/date";
    return this.http.get(urlPath, {params: params, headers: this.headers});
  }

  getTicketsByOwnerShip(params): Observable<any> {
    const urlPath = this.API_URL + "/fetchfilteredTickets/ownership";
    return this.http.get(urlPath, {params: params, headers: this.headers});
  }

  // ------------------ raise issue apis new module starts ----------------------------------------

  getAllIssuesRaised(body,params):Observable<any> {
    const urlPath = environment.adminUrl + "/home/issue/getIssuesFromFilter";
    return this.apiService.doPostObservableForQuery(urlPath,body, params);
  }
  getAllIssuesStatus(params):Observable<any> {
    const urlPath = environment.adminUrl + "/home/issue/getAllIssueStatus";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getAllIssueTypes(params):Observable<any> {
    const urlPath = environment.adminUrl + "/home/issue/getAllIssueTypes";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getSubIssuesByIssueID(params):Observable<any> {
    const urlPath = environment.adminUrl + "/home/issue/getSubIssueByUserIssueId";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getTrackStatusByIssue(params):Observable<any> {
    const urlPath = environment.adminUrl + "/home/issue/getTrackIssueByUserIssueId";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  raiseSubIssue(body,params):Observable<any> {
    const urlPath = environment.adminUrl + "/home/issue/raiseSubIssue";
    return this.apiService.doPostObservableForQuery(urlPath,body, params);
  }
  getInvoicesByRetCode(params):Observable<any> {
    const urlPath = environment.adminUrl + "/home/issue/getInvoiceNumberByRetailerCode";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  getConversationByIssue(params):Observable<any> {
    const urlPath = environment.adminUrl + "/home/issue/getConversationByUserIssueId";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }

  updateIssueStatus(params):Observable<any> {
    const urlPath = environment.adminUrl + "/home/issue/changeUserIssueStatus";
    return this.apiService.doUpdateForQuery(urlPath, params);
  }

  sendMessage(body,params):Observable<any> {
    const urlPath = environment.adminUrl + "/home/issue/createConversation";
    return this.apiService.doPostObservableForQuery(urlPath,body, params);
  }

 confirmTicket(body,params):Observable<any> {
    const urlPath = environment.adminUrl + "/home/issue/confirmTicket";
    return this.apiService.doPostObservableForQuery(urlPath,body, params);
  }

  updateIssueRemarks(params):Observable<any> {
    const urlPath = environment.adminUrl + "/home/issue/changeRemark";
    return this.apiService.doUpdateForQuery(urlPath, params);
  }
  updateSubIssueStatus(params):Observable<any> {
    const urlPath = environment.adminUrl + "/home/issue/changeSubUserIssueStatus";
    return this.apiService.doUpdateForQuery(urlPath, params);
  }
  getTicketById(params):Observable<any> {
    const urlPath = environment.adminUrl + "/home/issue/getIssueById";
    return this.apiService.doGetObservableForQuery(urlPath, params);
  }
  updateDepartment(params):Observable<any> {
    const urlPath = environment.adminUrl + "/home/issue/changeDepartment";
    return this.apiService.doPostObservableForQuery(urlPath,{}, params);
  }
  sendMail(body):Observable<any> {
    const urlPath = environment.eagleUrl + "/HelpDesk/sendTicket";
    return this.apiService.doPostObservableForQuery(urlPath,body,{});
  }

}
