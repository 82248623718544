import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogModelConfirmSession } from "./model";
@Component({
  selector: "app-create-session-confirm",
  templateUrl: "./create-session-confirm.component.html",
  styleUrls: ["./create-session-confirm.component.scss"],
})
export class CreateSessionConfirmComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CreateSessionConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelConfirmSession
  ) {}

  ngOnInit(): void {
    
  }

  submit() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
